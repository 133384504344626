import React from "react";
import styled from "styled-components";
import { Button, Icon, theme } from "uikit";
import { RegisteredIcon } from "../../../../../assets/icons";

const CallButtonStyled = styled(Button.Button)<{ bgColor: string }>`
	height: 40px;
	width: 40px;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: ${({ bgColor }) => bgColor || "#EBEBEB"};

	border-radius: 8px;
	cursor: pointer;
	&:hover {
		background-color: ${({ bgColor }) => bgColor || "#EBEBEB"};
	}
`;

interface Props {
	bgColor: string;
	iconColor: string;
	iconName: RegisteredIcon;
	// eslint-disable-next-line react/require-default-props
	disabled?: boolean;
	onClick: () => void;
}

const CallButton: React.FC<Props> = ({
	bgColor,
	iconColor,
	iconName,
	disabled,
	onClick,
}) => (
	<CallButtonStyled
		bgColor={bgColor}
		onClick={onClick}
		icon={
			<Icon
				size={24}
				id={iconName}
				colors={[disabled ? theme.colors.disabled_text : iconColor]}
			/>
		}
		disabled={disabled}
	/>
);

export default CallButton;
