/* eslint-disable prettier/prettier */
import { string } from "yup";

export const defaultCountryCodePhone = "uk"; // "md"; // "uk";

export enum CodePhone {
	UK = "38",
	AZE = "994",
	PLN = "48",
	MD = "373",
}

/** Validate phone number
 * https://vdmt.com.ua/codes_of_mobile_operators
 * https://minfin.com.ua/telecom/european-country-dialing-codes
 * https://github.com/skotniczny/phonePL
 * https://www.npmjs.com/package/google-libphonenumber
 * https://mold.su/poleznaya-informatsiya/telefony-kod-moldova
 * @param phone - the phone number as a string
 */
export const validatePhone = (phone: string): boolean => {
	if (!phone) return false;

	// Ukraine
	if (phone.substring(0, 2) === CodePhone.UK) {
		// '^380(?:39|50|6[3678]|73|9[356789])\\d{7}$'
		const ukPhone = string().matches(
			/^\+?\b([38]){2}(050|048|066|095|099|063|073|093|070|700|090|900|067|068|096|097|098|091|092|020|094|089|039|031|032|033|034|035|036|038|041|042|043|044|045|046)[0-9]{7}\b$/,
		);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Azerbaijan
	if (phone.substring(0, 3) === CodePhone.AZE) {
		const azPhone = string().matches(
			/^\+?\b([994]){3}(050|051|055|077|070)[0-9]{6,7}\b$/,
		);
		const isValid = azPhone.isValidSync(phone);
		return isValid;
	}

	// Poland
	if (phone.substring(0, 2) === CodePhone.PLN) {
		const plPhone = string().matches(
			/^\+?\b([48]){2}(45|50|51|53|57|60|66|69|72|73|78|79|88)[0-9]{7}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	// Moldova
	if (phone.substring(0, 3) === CodePhone.MD) {
		const plPhone = string().matches(
			/^\+?\b([373]){3}(021|022|023|0265|0297|0231|0247|0293|0249|0251|0252|0246|0273|0299|0298|0248|0277|0244|0243|0263|0264|0271|0235|0254|0256|0230|0237|0262|0294|0258|0236|0250|0259|0269|0291|0241|0271|0242|0268|790|791|792|793|794|795|796|797|798|799|780|781|782|783|690|691|692|693|694|695|696|697|698|699|680|681|682|683|684|685|686|687|688|689|600|601|602|603|604|671|672)[0-9]{5,6}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	// General
	const general: string[] = ["050", "070"];
	if (general.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(/^\+?\b(050|070)[0-9]{6,7}\b$/);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Azerbaijan
	const az: string[] = ["050", "051", "055", "077", "070"];
	if (az.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(/^\+?\b(051|055|077)[0-9]{6,7}\b$/);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Ukraine
	const uk: string[] = [
		"020", // 2
		"039", // 3
		"031",
		"032",
		"033",
		"034",
		"035",
		"036",
		"038",
		"041", // 4
		"042",
		"043",
		"044",
		"045",
		"046",
		"048",
		"050", // 5
		"066", // 6
		"063",
		"067",
		"068",
		"073", // 7
		"070",
		"700",
		"089", // 8
		"900", // 9
		"090",
		"091",
		"092",
		"093",
		"094",
		"095",
		"096",
		"097",
		"098",
		"099",
	];

	if (uk.includes(phone.substring(0, 3))) {
		const ukPhone = string().matches(
			/^\+?\b(020|039|031|032|033|034|035|036|038|041|042|043|044|045|046|048|050|066|063|067|068|073|070|700|089|900|090|091|092|093|094|095|096|097|098|099])[0-9]{7}\b$/,
		);
		const isValid = ukPhone.isValidSync(phone);
		return isValid;
	}

	// Poland
	const pl: string[] = [
		"26",
		"39",
		"47",
		"45",
		"50",
		"51",
		"53",
		"57",
		"60",
		"66",
		"69",
		"72",
		"73",
		"78",
		"79",
		"88",
	];
	if (pl.includes(phone.substring(0, 2))) {
		const plPhone = string().matches(
			/^\+?\b(26|39|47|45|50|51|53|57|60|66|69|72|73|78|79|88)[0-9]{7}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	const md: string[] = [
		"021",
		"022",
		"023",
		"0265",
		"0297",
		"0231",
		"0247",
		"0293",
		"0249",
		"0251",
		"0252",
		"0246",
		"0273",
		"0299",
		"0298",
		"0248",
		"0277",
		"0244",
		"0243",
		"0263",
		"0264",
		"0271",
		"0235",
		"0254",
		"0256",
		"0230",
		"0237",
		"0262",
		"0294",
		"0258",
		"0236",
		"0250",
		"0259",
		"0269",
		"0291",
		"0241",
		"0271",
		"0242",
		"0268",
		"0790",
		"0791",
		"0792",
		"0793",
		"0794",
		"0795",
		"0796",
		"0797",
		"0798",
		"0799",
		"0780",
		"0781",
		"0782",
		"0783",
		"0690",
		"0691",
		"0692",
		"0693",
		"0694",
		"0695",
		"0696",
		"0697",
		"0698",
		"0699",
		"0680",
		"0681",
		"0682",
		"0683",
		"0684",
		"0685",
		"0686",
		"0687",
		"0688",
		"0689",
		"0600",
		"0601",
		"0602",
		"0603",
		"0604",
		"0671",
		"0672",
	];

	if (md.includes(phone.substring(0, 4))) {
		const plPhone = string().matches(
			/^\+?\b(021|022|023|0265|0297|0231|0247|0293|0249|0251|0252|0246|0273|0299|0298|0248|0277|0244|0243|0263|0264|0271|0235|0254|0256|0230|0237|0262|0294|0258|0236|0250|0259|0269|0291|0241|0271|0242|0268|790|791|792|793|794|795|796|797|798|799|780|781|782|783|690|691|692|693|694|695|696|697|698|699|680|681|682|683|684|685|686|687|688|689|600|601|602|603|604|671|672)[0-9]{5,6}\b$/,
		);
		const isValid = plPhone.isValidSync(phone);
		return isValid;
	}

	return false;
};

export const countryCodePhone = (
	phone: string,
	country: "az" | "uk" | "pl" | "md",
): string => {
	if (!phone) return phone;

	// Azerbaijan
	const az: string[] = ["050", "051", "055", "077", "070"];
	if (az.includes(phone.substring(0, 3)) && country === "az") {
		return `${CodePhone.AZE}${phone}`;
	}

	// Ukraine
	const uk: string[] = [
		"020", // 2
		"039", // 3
		"031",
		"032",
		"033",
		"034",
		"035",
		"036",
		"038",
		"041", // 4
		"042",
		"043",
		"044",
		"045",
		"046",
		"048",
		"050", // 5
		"066", // 6
		"063",
		"067",
		"068",
		"073", // 7
		"070",
		"700",
		"089", // 8
		"900", // 9
		"090",
		"091",
		"092",
		"093",
		"094",
		"095",
		"096",
		"097",
		"098",
		"099",
	];

	if (uk.includes(phone.substring(0, 3)) && country === "uk") {
		return `${CodePhone.UK}${phone}`;
	}

	// Poland
	const pl: string[] = [
		"26",
		"39",
		"47",
		"45",
		"50",
		"51",
		"53",
		"57",
		"60",
		"66",
		"69",
		"72",
		"73",
		"78",
		"79",
		"88",
	];
	if (pl.includes(phone.substring(0, 2)) && country === "pl") {
		return `${CodePhone.PLN}${phone}`;
	}

	const md: string[] = [
		"790",
		"791",
		"792",
		"793",
		"794",
		"795",
		"796",
		"797",
		"798",
		"799",
		"780",
		"781",
		"782",
		"783",
		"690",
		"691",
		"692",
		"693",
		"694",
		"695",
		"696",
		"697",
		"698",
		"699",
		"680",
		"681",
		"682",
		"683",
		"684",
		"685",
		"686",
		"687",
		"688",
		"689",
		"600",
		"601",
		"602",
		"603",
		"604",
		"671",
		"672",
	];

	if (md.includes(phone.substring(0, 3)) && country === "md") {
		return `${CodePhone.MD}${phone}`;
	}

	return phone;
};

export default validatePhone;
