import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const accountsExecutors = makeTable({
	allIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"executorRateId",
		"priority",
	],
	i18tPath: (id) => `mainPage.accounts.executors.columns.${id}`,
	defaultIds: [
		"fullName",
		"alias",
		"parkNumber",
		"phones",
		"balance",
		"bonusBalance",
		"role",
		"company",
		"taxiService",
		"registeredAt",
		"responsibleDispatcher",
		"executorRateId",
		"priority",
	],
	initialSortMethod: {
		column: "alias",
		type: "asc",
	},
	initialWidths: {
		fullName: 137,
		alias: 100,
		parkNumber: 100,
		phones: 150,
		balance: 110,
		bonusBalance: 130,
		role: 140,
		company: 120,
		taxiService: 120,
		registeredAt: DATE,
		responsibleDispatcher: 137,
		executorRateId: 137,
		priority: 137,
	},
	initialResizable: {
		fullName: true,
		alias: true,
		parkNumber: true,
		phones: true,
		balance: true,
		bonusBalance: true,
		role: true,
		company: true,
		taxiService: true,
		registeredAt: true,
		responsibleDispatcher: true,
		executorRateId: true,
		priority: true,
	},
	initialSortable: {
		fullName: true,
		alias: true,
		parkNumber: true,
		phones: true,
		balance: true,
		bonusBalance: true,
		role: true,
		company: true,
		taxiService: true,
		registeredAt: true,
		responsibleDispatcher: true,
		executorRateId: true,
		priority: true,
	},
});

export default accountsExecutors;
