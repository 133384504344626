import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Float } from "uikit";

import Language from "../../../../../services/Language";
import StoreList from "../../../../../types/StoreList";
import TaxiService from "../../../../../services/TaxiService";
import { useTypedSelector } from "../../../../../redux/store";
import useParkingActions from "../../hooks/useParkingActions";

import Popover from "./components/Popover";

const Filter: React.FC<Filter.Props> = ({
	taxiServiceData,
	taxiServiceIdsPosition,
	language,
}) => {
	const { onChangeFilter } = useParkingActions();

	const { criteria: filters } = useTypedSelector(
		(state) => state.orders.parking.filter,
	);

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);
	const { items: companies } = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);

	const isFilterActive = useMemo(() => {
		if (filters.online?.length) return true;
		if (filters.workingStatus?.length) return true;
		if (filters.statuses?.length) return true;
		if (filters.isYourOrderStatus) return true;
		if (companies?.length !== filters.companyIds?.length) return true;
		if (taxiServices?.length !== filters.taxiServiceIds?.length)
			return true;
		if (filters.executorGroupIds?.length > 0) return true;
		return false;
	}, [
		companies?.length,
		filters.companyIds?.length,
		filters.executorGroupIds?.length,
		filters.isYourOrderStatus,
		filters.online?.length,
		filters.statuses?.length,
		filters.taxiServiceIds?.length,
		filters.workingStatus?.length,
		taxiServices?.length,
	]);

	const [isButtonLocked, setIsButtonLocked] = useState(false);
	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);
	const [showModalPropsForExecutorGroup, setShowModalPropsForExecutorGroup] =
		useState<boolean>(false);

	const isActiveShowMultiModal = showModalPropsForExecutorGroup;

	const buttonOnClick = useCallback(() => {
		openAdditionalFilters
			? undefined
			: !isButtonLocked && setOpenAdditionalFilters(true);
	}, [isButtonLocked, openAdditionalFilters]);

	const buttonOnMouseDown = useCallback(
		(event) => {
			event.preventDefault();
			if (openAdditionalFilters) setIsButtonLocked(true);
		},
		[openAdditionalFilters],
	);

	const popoverOnClose = useCallback(() => {
		if (!isActiveShowMultiModal) setOpenAdditionalFilters(false);
	}, [isActiveShowMultiModal]);

	useEffect(() => {
		const unblockHandler = () => {
			setIsButtonLocked(false);
		};

		document.addEventListener("click", unblockHandler);

		return () => {
			document.removeEventListener("click", unblockHandler);
		};
	}, []);

	return (
		<>
			<Float.Tracker id="filters">
				<Button.Button
					style={
						isFilterActive
							? { backgroundColor: "rgb(109,190,255)" }
							: {}
					}
					variant={isFilterActive ? "primary" : "secondary"}
					icon={<Icon id="filters" size={20} />}
					onClick={buttonOnClick}
					onMouseDown={buttonOnMouseDown}
				/>
			</Float.Tracker>

			<Popover
				taxiServiceData={taxiServiceData}
				taxiServiceIdsPosition={taxiServiceIdsPosition}
				language={language}
				filter={filters}
				setFilter={onChangeFilter}
				open={openAdditionalFilters}
				onClose={popoverOnClose}
				setShowModalPropsForExecutorGroup={
					setShowModalPropsForExecutorGroup
				}
			/>
		</>
	);
};

declare namespace Filter {
	interface Props {
		taxiServiceData: StoreList<TaxiService.Model> | undefined;
		taxiServiceIdsPosition: number[];
		language: Language;
	}
}

export default Filter;
