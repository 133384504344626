// ? executers widget interface
import { State as _DataExecuters } from "./Executors/data/interface";

export namespace Accounts {
	export namespace Executors {
		export interface DataExecuters extends _DataExecuters {}
	}

	export const ConstantOrdersSlice = {
		EXECUTORS_DATA: "accounts/executors/data",
	};
}
