/**
 * Standardizes column widths for all tables to ensure a consistent format.
 */
const BASE_WIDTH_COLUMNS: Record<string, number> = {
	DATE: 155, // any column using date format, and this width is minimum for excel file format
	ACTIVE: 120, // any column using ToggleButton
	COMPANIES: 220, // any column using CompaniesByIds
} as const;

export default BASE_WIDTH_COLUMNS;
