/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State } from "./interface";

export const defaultColumnIds: string[] = ["name", "driversAmount", "aliases"];

const initialState: State = {
	columnIds: defaultColumnIds,
};

const setColumnIds: ReduxReducer<State, string[]> = (state, action) => {
	state.columnIds = action.payload;
};

const orderParkingTableSettings = createSlice({
	name: Orders.ConstantOrdersSlice.PARKING_TABLE_SETTINGS,
	initialState,
	reducers: {
		setColumnIds,
	},
});

export const orderParkingTableSettingsAction =
	orderParkingTableSettings.actions;
export const orderParkingTableSettingsReducer =
	orderParkingTableSettings.reducer;
export type orderParkingTableSettingsState = State;

export default orderParkingTableSettings;
