import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE } = BASE_WIDTH_COLUMNS;

const data = makeTable({
	allIds: [
		"name",
		"edrpou",
		"counterpartyGroupName",
		"contactName",
		"phones",
		"email",
		"orderCount",
		"overallBalance",
		"overallBonusBalance",
		"disconnectionThreshold",
		"accountsBalance",
		"accountsBonusBalance",
		"accountsDisconnectionThreshold",
		"active",
		"company",
		"registrationBranch",
	],
	defaultIds: [
		"name",
		"edrpou",
		"contactName",
		"phones",
		"email",
		"company",
		"registrationBranch",
		"counterpartyGroupName",
	],
	i18tPath: (id) => `tableColumns.customers.counterparties.${id}`,
	initialWidths: {
		name: 100,
		edrpou: 150,
		counterpartyGroupName: 100,
		contactName: 100,
		phones: 150,
		email: 150,
		orderCount: 150,
		overallBalance: 150,
		overallBonusBalance: 150,
		disconnectionThreshold: 150,
		accountsBalance: 150,
		accountsBonusBalance: 150,
		accountsDisconnectionThreshold: 150,
		active: ACTIVE,
		company: 150,
		registrationBranch: 150,
	},
});

export default data;
