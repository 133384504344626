import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const accountsTransportForModalInExecutors = makeTable({
	allIds: [
		"parkNumber",
		"aliases",
		"brand",
		"model",
		"class",
		"bodyType",
		"registeredAt",
		"responsibleDispatcher",
		"company",
		"taxiService",
		"color",
		"notes",
	],
	i18tPath: (id) => `mainPage.accounts.transport.columns.${id}`,
	defaultIds: [
		"parkNumber",
		"aliases",
		"brand",
		"model",
		"class",
		"bodyType",
		"registeredAt",
		"responsibleDispatcher",
		"company",
		"taxiService",
		"color",
		"notes",
	],
	initialSortMethod: {
		column: "parkNumber",
		type: "asc",
	},
	initialWidths: {
		parkNumber: 160,
		aliases: 120,
		brand: 100,
		model: 100,
		class: 100,
		bodyType: 150,
		registeredAt: DATE,
		responsibleDispatcher: 150,
		company: 120,
		taxiService: 120,
		color: 100,
		notes: 150,
	},
	initialResizable: {
		parkNumber: true,
		aliases: true,
		brand: true,
		model: true,
		class: true,
		bodyType: true,
		registeredAt: true,
		responsibleDispatcher: true,
		company: true,
		taxiService: true,
		color: true,
		notes: true,
	},
	initialSortable: {
		parkNumber: true,
		aliases: true,
		brand: true,
		model: true,
		class: true,
		bodyType: true,
		registeredAt: true,
		responsibleDispatcher: true,
		company: true,
		taxiService: true,
		color: true,
		notes: true,
	},
});

export default accountsTransportForModalInExecutors;
