import { Action, combineReducers } from "@reduxjs/toolkit";

import { chats, ordersChatAction, StateChats } from "./Chat";
import { parking, ordersParkingAction, StateParking } from "./Parking";
import { executors, ordersExecutorsAction, StateExecutors } from "./Executors";
import { map, ordersMapAction, StateMap } from "./Map";
import { softPhone, orderSoftPhoneAction, StateSoftPhone } from "./SoftPhone";
import {
	mapFromAddModal,
	ordersMapFromAddModalAction,
	StateMapFromAddModal,
} from "./MapFromAddModal";
import { tabs, ordersTabsAction, StateTabs } from "./Tabs";
import { Orders } from "./interface";

export { Orders };

export type State = {
	chats: StateChats;
	parking: StateParking;
	executors: StateExecutors;
	map: StateMap;
	mapFromAddModal: StateMapFromAddModal;
	tabs: StateTabs;
	softPhone: StateSoftPhone;
};

export type Actions = {
	chats: typeof ordersChatAction;
	parking: typeof ordersParkingAction;
	executors: typeof ordersExecutorsAction;
	map: typeof ordersMapAction;
	mapFromAddModal: typeof ordersMapFromAddModalAction;
	tabs: typeof ordersTabsAction;
	softPhone: typeof orderSoftPhoneAction;
};

export const ordersReducer = combineReducers<State, Action<Actions>>({
	chats,
	parking,
	executors,
	map,
	tabs,
	mapFromAddModal,
	softPhone,
});

export const ordersAction: Actions = {
	chats: ordersChatAction, // * Widget
	parking: ordersParkingAction, // * Widget
	executors: ordersExecutorsAction, // * Widget
	map: ordersMapAction, // * Widget
	mapFromAddModal: ordersMapFromAddModalAction,
	tabs: ordersTabsAction,
	softPhone: orderSoftPhoneAction, // * Widget
};

export default ordersReducer;
