import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE, ACTIVE } = BASE_WIDTH_COLUMNS;

const accountsCarParks = makeTable({
	allIds: ["active", "name", "taxiServices", "createdAt"],
	i18tPath: (id) => `mainPage.accounts.carParks.columns.${id}`,
	defaultIds: ["active", "name", "taxiServices", "createdAt"],
	initialSortMethod: {
		column: "createdAt",
		type: "asc",
	},
	initialWidths: {
		active: ACTIVE,
		name: 800,
		taxiServices: 430,
		createdAt: DATE,
	},
	initialResizable: {
		active: true,
		name: true,
		taxiServices: true,
		createdAt: true,
	},
	initialSortable: {
		active: true,
		name: true,
		taxiServices: true,
		createdAt: true,
	},
});

export default accountsCarParks;
