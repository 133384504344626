/* eslint-disable no-param-reassign */

import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

import Executor from "../../../../services/Executor";

import {
	ParkingFilter,
	ExecutorFilter,
	ChatFilter,
	DefaultFilter,
	OrderPageFilters,
} from "./interface";

export type {
	ParkingFilter,
	ExecutorFilter,
	ChatFilter,
	DefaultFilter,
	OrderPageFilters,
};

const initialState: OrderPageFilters = {
	parkings: {
		data: {
			executors: [],
		},
		filters: {
			companyIds: [],
			taxiServiceIds: [],
			taxiServiceIdsPosition: [],
			statuses: [],
			online: [],
			workingStatus: [],
			isYourOrderStatus: false,
			query: "",
			executorGroupIds: [],
		},
	}, // TODO The tree-like redux is already ready for it in the folder orders
	executors: {
		data: {
			executors: [],
		},
		filters: {
			companyIds: [],
			taxiServiceIds: [],
			executorGroupIds: [],
			fleetIds: [],
			statuses: [
				"available",
				"busy",
				"dinner",
				"home",
				"on_order",
				"closed",
			],
			online: ["online", "offline"],
			workingStatus: ["working", "notWorking"],
			isYourOrderStatus: true,
			order: {
				column: undefined,
				type: undefined,
			},
			query: "",
		},
	},
	chats: {
		data: {
			dispatchers: [],
			executors: [],
		},
		filters: {
			query: "",
			order: {
				column: "callSign",
				type: "asc",
			},
		},
	}, // TODO it has nothing to do with chats. Check if there is some kind of binding, if not, then delete
	defaultFilters: {
		companyIds: [],
		taxiServiceIds: [],
		statuses: [],
		online: [],
		workingStatus: [],
		isYourOrderStatus: false,
		order: {
			column: undefined,
			type: undefined,
		},
		query: "",
	},
};

type Reducer<P> = CaseReducer<OrderPageFilters, PayloadAction<P>>;

const setChatsQuery: Reducer<string> = (state, { payload }) => {
	state.chats.filters.query = payload;
};

const setChatsSort: Reducer<{ column; type }> = (state, { payload }) => {
	state.chats.filters.order = payload;
};

const setExecutorsSort: Reducer<{ column; type }> = (state, { payload }) => {
	state.executors.filters.order = payload;
};

const setExecutorsData: Reducer<{
	executors: Executor.Model[];
}> = (state, { payload }) => {
	state.executors.data = payload;
};

const setExecutorsFilter: Reducer<ExecutorFilter> = (state, { payload }) => {
	state.executors.filters = payload;
};

const setParkingsQuery: Reducer<string> = (state, { payload }) => {
	state.parkings.filters.query = payload;
};

const setParkingsPosition: Reducer<number[]> = (state, { payload }) => {
	state.parkings.filters.taxiServiceIdsPosition = payload;
};

const setParkingsData: Reducer<{
	executors: Executor.Model[];
}> = (state, { payload }) => {
	state.parkings.data = payload;
};

const setParkingsFilter: Reducer<ParkingFilter> = (state, { payload }) => {
	state.parkings.filters = payload;
};

const setChatsFilter: Reducer<ChatFilter> = (state, { payload }) => {
	state.chats.filters = payload;
};

const setDefaultFilter: Reducer<DefaultFilter> = (state, { payload }) => {
	state.defaultFilters = payload;
};

const orderPageFilters = createSlice({
	name: "orderPageFiltersReducer",
	initialState,
	reducers: {
		setChatsQuery,
		setChatsSort,
		setExecutorsSort,
		setExecutorsData,
		setExecutorsFilter,
		setParkingsQuery,
		setParkingsPosition,
		setParkingsData,
		setParkingsFilter,
		setChatsFilter,
		setDefaultFilter,
	},
});

export default orderPageFilters;
