import React from "react";
import styled from "styled-components";
import { Column, theme } from "uikit";
import Caller from "./components/Caller";

const CallersStyled = styled(Column)`
	padding-top: 16px;
	width: 290px;
	background-color: ${theme.colors.transparent};
`;

const Callers = () => (
	<CallersStyled gaps="6px*">
		<Caller />
	</CallersStyled>
);

export default Callers;
