import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE } = BASE_WIDTH_COLUMNS;

const preferencesServices = makeTable({
	allIds: [
		"active",
		"name",
		"company",
		"branch",
		"isExecutorRelated",
		"isVehicleRelated",
	],
	i18tPath: (id) => `mainPage.preferences.services.columns.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	defaultIds: [
		"active",
		"name",
		"company",
		"branch",
		"isExecutorRelated",
		"isVehicleRelated",
	],
	initialWidths: {
		active: ACTIVE,
		name: 450,
		company: 240,
		branch: 240,
		isExecutorRelated: 240,
		isVehicleRelated: 240,
	},
	initialResizable: {
		active: true,
		name: true,
		branch: true,
		company: true,
		isExecutorRelated: true,
		isVehicleRelated: true,
	},
	initialSortable: {
		active: true,
		name: true,
		branch: true,
		company: true,
		isExecutorRelated: true,
		isVehicleRelated: true,
	},
});

export default preferencesServices;
