import JsSIP from "jssip";
import { Nullable, theme } from "uikit";
import { uniqueId } from "lodash";

import { SIPToDispatcher, Call as CallService, Order } from "../services";
import { store } from "../redux/store";
import sipToDispatcher from "../redux/reducers/sipToDispatcher";
import { openOrderCard, focusOrderCard } from "../redux/services/Order/card";
import orderPage from "../redux/reducers/OrdersPage";
import { ordersAction as orders } from "../redux/reducers/Orders";
import { ORDERS_TYPES, OrderType } from "../redux/constants/OrdersPage";
import softphoneWidget, {
	Call,
	CallType,
	TransferCall,
	TransferType,
} from "../redux/reducers/softphoneWidget";
import { preOrderMeta } from "../redux/reducers/settings/preorders";
import { Tab } from "../pages/MainPage/pages/Orders/components/OrderModal/types/Tab";
import sound from "../assets/sounds/ring.wav";
import { checkSipForActivity } from "../components/Header/components/SipList/components/Sip/helper";
import {
	DispatcherPhone,
	ExecutorPhone,
} from "../components/SoftphoneWidget/components/Transfer/types";

export const softphoneConnectionColors = {
	success: {
		backgroundColor: "#4CAF50",
		iconColor: theme.colors.white,
	},
	failure: {
		backgroundColor: theme.colors.negative,
		iconColor: theme.colors.white,
	},
	inactive: {
		backgroundColor: theme.colors.button_secondary,
		iconColor: theme.colors.secondary,
	},
};

export const sipConfig = (config) => {
	const oSipAudio = document.createElement("audio");
	const oSoundAudio = document.createElement("audio");

	oSoundAudio.src = sound;
	oSoundAudio.loop = true;

	JsSIP.debug.enable("JsSIP:*");
	const socket = new JsSIP.WebSocketInterface(config.wssHost);
	socket.via_transport = "WS";
	const configuration = {
		sockets: [socket],
		uri: config.uri,
		name: config.sipValue,
		password: config.password,
		register_expires: config.register_expires,
		session_timers_refresh_method: config.session_timers_refresh_method,
		realm: config.realm,
		user_agent: config.user_agent,
		host: config.host,
	};

	const callOptions = {
		mediaConstraints: { audio: true, video: false },
		pcConfig: {
			iceServers: [...config.iceServers.stun, ...config.iceServers.turn],
		},
	};

	JsSIP.debug.enable("JsSIP:*");
	return {
		ua: new JsSIP.UA(configuration),
		audio: {
			call: oSipAudio,
			sounds: oSoundAudio,
		},
		callOptions,
		config,
	};
};

export const sipConnect = async (sipData) => {
	if (!sipData) return;
	const uaConfig = sipConfig(sipData);
	const { ua, audio } = uaConfig;

	store.dispatch(sipToDispatcher.actions.setUaConfig(uaConfig));

	// ua.on("connecting", (e) => {
	// 	console.log("ua on Connecting", e);
	// });

	// ua.on("connected", (e) => {
	// 	console.log("ua on Connected", e);
	// });

	ua.on("registered", () => {
		store.dispatch(sipToDispatcher.actions.setSoftphoneConnect("success"));
	});

	// ua.on("unregistered", (e) => {
	// 	console.log("ua on unregistered", e);
	// });

	ua.on("registrationFailed", () => {
		if (uaConfig.config.isSoftPhone) {
			store.dispatch(
				sipToDispatcher.actions.setSoftphoneConnect("failure"),
			);
			store.dispatch(softphoneWidget.actions.setIsOpen(false));
		} else {
			store.dispatch(
				sipToDispatcher.actions.setSoftphoneConnect("inactive"),
			);
			store.dispatch(softphoneWidget.actions.setIsOpen(false));
		}
	});

	ua.on("newRTCSession", (e) => {
		const { session, request } = e;

		const callData: Call = e;

		const { callsData } = store.getState().softphoneWidget;

		let timerId: any = null;

		let callDuration = 0;
		let holdDuration = 0;
		timerId = setInterval(() => {
			const updatedCall: Call = {
				...callData,
				callDuration: formatSeconds(callDuration),
				holdDuration: formatSeconds(holdDuration),
			};
			callDuration++;
			if (!callData.session._localHold) {
				holdDuration = 0;
			} else if (callData.session._localHold) {
				holdDuration++;
			}
			store.dispatch(
				softphoneWidget.actions.updateCallDuration(updatedCall),
			);
			store.dispatch(softphoneWidget.actions.rerenderSoftphone());
		}, 1000);

		session.on("ended", (e) => {
			clearInterval(timerId);
			const { callsData } = store.getState().softphoneWidget;

			const session = callsData.find((call) =>
				call.session.id.includes(e.message?.call_id),
			);

			const { transferQueue } = store.getState().softphoneWidget;

			const transfer = transferQueue.find(
				(transfer) => transfer.numberToRefer === request.to.uri.user,
			);

			if (transfer) {
				setTimeout(() => {
					transfer?.call.session.refer(
						transfer.sipUri,
						uaConfig.callOptions,
					);
					store.dispatch(
						softphoneWidget.actions.deleteFromTransferQueue({
							...transfer,
						}),
					);
				}, 500);
			}

			if (session && e.message?.call_id) {
				const callData: Call = session;
				store.dispatch(softphoneWidget.actions.terminateCall(callData));
				store.dispatch(softphoneWidget.actions.setSelectedCaller(null));
				store.dispatch(sipToDispatcher.actions.setCurrentCall(null));
			}
		});

		let myCandidateTimeout: NodeJS.Timeout;

		session.on("icecandidate", (candidate) => {
			if (myCandidateTimeout != null) clearTimeout(myCandidateTimeout);

			myCandidateTimeout = setTimeout(candidate.ready, 2000);
		});

		session.on("failed", () => {
			const { transferQueue } = store.getState().softphoneWidget;

			const transfer = transferQueue.find(
				(transfer) => transfer.numberToRefer === request.to.uri.user,
			);

			if (transfer) {
				store.dispatch(
					softphoneWidget.actions.deleteFromTransferQueue({
						...transfer,
					}),
				);
			}
		});

		if (session.direction === "incoming") {
			const isIncludesNewPhone = callsData.every(
				(call) =>
					call.request.from._uri._user !== request.from._uri._user,
			);

			if (isIncludesNewPhone) {
				store.dispatch(
					softphoneWidget.actions.addCallerToCalls(callData),
				);

				if (!callsData.length) {
					store.dispatch(
						softphoneWidget.actions.setSelectedCaller(callData),
					);
				}
			} else {
				store.dispatch(softphoneWidget.actions.updateCall(callData));
			}

			store.dispatch(softphoneWidget.actions.rerenderSoftphone());

			const originalReceiveRequest = session.receiveRequest;
			// eslint-disable-next-line func-names
			session.receiveRequest = function (request) {
				if (request.method === "CANCEL") {
					handleCancelMethod(request);
				}
				// eslint-disable-next-line prefer-rest-params
				return originalReceiveRequest.apply(this, arguments);
			};

			audio.sounds.play();
			// let timerId: any = null;
			session.on("confirmed", () => {
				const { selectedCall } = store.getState().softphoneWidget;

				if (selectedCall?.request.call_id !== request.call_id) {
					session.hold();
				}

				callDuration = 0;

				store.dispatch(softphoneWidget.actions.rerenderSoftphone());
				const remoteStream = session.connection.getRemoteStreams()[0];
				audio.call.srcObject = remoteStream;
				audio.call.play();
			});

			session.on("failed", () => {
				clearInterval(timerId);
				if (session._connection) {
					session.terminate();
				}
			});
		}

		if (session.direction === "outgoing") {
			const isIncludesNewPhone = callsData.every(
				(call) => call.request.to._uri._user !== request.to._uri._user,
			);

			if (isIncludesNewPhone) {
				store.dispatch(
					softphoneWidget.actions.addCallerToCalls(callData),
				);
				if (!callsData.length) {
					store.dispatch(
						softphoneWidget.actions.setSelectedCaller(callData),
					);
				}
			} else {
				store.dispatch(softphoneWidget.actions.updateCall(callData));
			}

			store.dispatch(softphoneWidget.actions.rerenderSoftphone());

			// let timerId: any = null;
			session.on("confirmed", () => {
				const { selectedCall } = store.getState().softphoneWidget;

				if (selectedCall?.request.call_id !== request.call_id) {
					session.hold();
				}

				callDuration = 0;
			});

			session.on("progress", () => {
				const { selectedCall } = store.getState().softphoneWidget;
				if (selectedCall?.request.call_id === request.call_id) {
					const receivers: RTCRtpReceiver[] =
						session.connection.getReceivers();

					const remoteStream = new MediaStream();

					receivers.forEach((receiver) => {
						const { track } = receiver;
						if (track.kind === "audio") {
							remoteStream.addTrack(track);
						}
					});

					if (receivers.length) {
						audio.call.srcObject = remoteStream;
						audio.call.play();
					}
				}
			});
			session.on("failed", (e) => {
				clearInterval(timerId);
				const { callsData } = store.getState().softphoneWidget;

				const session = callsData.find((call) =>
					call.session.id.includes(e.message?.call_id),
				);

				if (session && e.message?.call_id) {
					const callData: Call = session;
					store.dispatch(
						softphoneWidget.actions.terminateCall(callData),
					);
					store.dispatch(
						softphoneWidget.actions.setSelectedCaller(null),
					);
					store.dispatch(
						sipToDispatcher.actions.setCurrentCall(null),
					);
				}
			});
		}

		session.on("unhold", () => {
			setTimeout(() => {
				const remoteStream = session?.connection.getRemoteStreams()[0];
				if (remoteStream) {
					audio.call.srcObject = remoteStream;
					audio.call.play();
				}
			}, 120);
		});
	});

	ua.start();
};

export const callUpNewCall = async (sipUri: string) => {
	const { uaConfig } = store.getState().sipToDispatcher;
	const { callsData } = store.getState().softphoneWidget;
	if (sipUri) {
		if (
			callsData.some((call) =>
				sipUri.includes(call.session.remote_identity.uri.user),
			)
		)
			return null;
		const number = sipUri;
		const missedCallsPhones = localStorage.getItem("missedCallsPhones");
		if (missedCallsPhones) {
			const parsedMissedCalls = JSON.parse(missedCallsPhones);

			const call = parsedMissedCalls.find((missedCall) =>
				number.includes(missedCall),
			);

			if (call) {
				await CallService.setNoAnswerAsRecall(call);
			}
		}
		uaConfig.ua.call(number, uaConfig.callOptions);
		return null;
	}
	return null;
};

function handleCancelMethod(request) {
	const { callsData } = store.getState().softphoneWidget;

	const callToTerminate = callsData.find(
		(call) => call.request.from.uri.user === request.from.uri.user,
	);
	getAndCallDown(callToTerminate);
}

/**
 * Handles the call-up logic, which includes accepting calls, making calls, and handling missed calls.
 *
 * @param {boolean} [callUpFirstCall=false] - Flag indicating whether to answer the first unconfirmed call.
 * @returns {Promise<null>} A promise that resolves to null.
 */
export const callUp = async (callUpFirstCall?: boolean): Promise<null> => {
	/**
	 * Get the SIP configuration from the Redux store.
	 */
	const { uaConfig } = store.getState().sipToDispatcher;

	/**
	 * Get the softphone widget state from the Redux store.
	 */
	const { selectedCall, numberQuery, callsData } =
		store.getState().softphoneWidget;

	const { tabs: activeTabsInModalOrderData } =
		store.getState().ordersPageReducer;

	const { items: allOrdersData } =
		store.getState().ordersPageReducer.orders[ORDERS_TYPES.ALL];

	const { items: preliminaryOrdersData } =
		store.getState().ordersPageReducer.orders[ORDERS_TYPES.PRELIMINARY];

	const { items: liveOrdersData } =
		store.getState().ordersPageReducer.orders[ORDERS_TYPES.LIVE];

	const { items: executingOrdersData } =
		store.getState().ordersPageReducer.orders[ORDERS_TYPES.EXECUTING];

	const activeAllOrdersDataSort = Array.from(allOrdersData || []).sort(
		(a, b) =>
			new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
	);

	const activeTabsInModalOrderSort = Array.from(
		activeTabsInModalOrderData || [],
	).sort(
		(a, b) =>
			new Date(b.form?.createdAt).getTime() -
			new Date(a.form?.createdAt).getTime(),
	);

	/**
	 * Opens the order modal and saves the provided phone number to the call buffer.
	 *
	 * @param {string | number} [phoneNumber] - The phone number to use when opening the order modal.
	 */
	const openOrderModal = (
		isCallUs: boolean,
		phoneNumber?: string | number,
	): void => {
		if (!isCallUs) return;

		if (
			typeof phoneNumber === "string" ||
			typeof phoneNumber === "number"
		) {
			const phoneNumberAsString: string =
				typeof phoneNumber === "number"
					? String(phoneNumber)
					: phoneNumber;

			// found the active order id from the caller's phone.
			const findOrderIdInActiveOrder = (
				orders: Order.Model[],
				phoneNumber: string,
			): Order.Model | null => {
				for (const order of orders) {
					if (
						order.phones?.some(
							(phone) => phone.number === phoneNumber,
						)
					) {
						return order;
					}
				}

				return null;
			};

			// found the edit new order in modal id from the caller's phone.
			const findOrderIdInEditModalOrder = (
				orders: Tab[],
				phoneNumber: string,
			): number | null => {
				for (const order of orders) {
					if (
						order.form?.additionalPhones?.some(
							(phone) => phone === phoneNumber,
						) ||
						order.form?.phone === phoneNumber
					) {
						const retval =
							typeof order.id === "number" ? order.id : null;
						return retval;
					}
				}

				return null;
			};

			const isFromActiveAllOrderAndHasID = findOrderIdInActiveOrder(
				activeAllOrdersDataSort,
				phoneNumberAsString,
			);

			const isFromEditModalOrderAndHasID = findOrderIdInEditModalOrder(
				activeTabsInModalOrderSort,
				phoneNumberAsString,
			);

			const isOrderIdNumber = (order: Order.Model | null): boolean =>
				typeof order?.id === "number";

			const selectOrder = (original: Order.Model | null) => {
				if (original) {
					store.dispatch(orderPage.actions.setFocusableOrder(null));

					store.dispatch(
						orderPage.actions.setActiveOrder({
							...original,
							hash: uniqueId("order"),
						}),
					);

					if (original.points.length === 1) {
						store.dispatch(orderPage.actions.setActivePoint(0));
					}
				}
			};

			if (isFromEditModalOrderAndHasID) {
				// Check if the caller's phone number matches an open order editing modal.
				// If found, focus on the specific order form in the modal.
				store.dispatch(focusOrderCard(isFromEditModalOrderAndHasID));
			} else if (isOrderIdNumber(isFromActiveAllOrderAndHasID)) {
				// Check if the caller's phone number matches any active orders.
				// If found, switch focus to the relevant tab or table in the active orders page.
				// Then open the editing modal for the existing order form.
				const idNeedOrder = isFromActiveAllOrderAndHasID?.id as number;

				store.dispatch(openOrderCard(idNeedOrder));
				store.dispatch(focusOrderCard(idNeedOrder));

				// Switches the tab on the order page and focuses an element from the table on all 3 tables: PRELIMINARY, LIVE, EXECUTING
				const tabsOnChangeSelectedOption = (option: OrderType) => {
					store.dispatch(orderPage.actions.setOrdersType(option));
					store.dispatch(orders.tabs.filter.setOrdersType(option));
				};

				const isPreliminaryOrderTabAndHasId = findOrderIdInActiveOrder(
					preliminaryOrdersData,
					phoneNumberAsString,
				);

				const isLiveOrderTabAndHasId = isOrderIdNumber(
					isPreliminaryOrderTabAndHasId,
				)
					? null
					: findOrderIdInActiveOrder(
							liveOrdersData,
							phoneNumberAsString,
					  );

				const isExecutingOrderTabAndHasId = isOrderIdNumber(
					isLiveOrderTabAndHasId,
				)
					? null
					: findOrderIdInActiveOrder(
							executingOrdersData,
							phoneNumberAsString,
					  );

				if (isOrderIdNumber(isPreliminaryOrderTabAndHasId)) {
					tabsOnChangeSelectedOption(ORDERS_TYPES.PRELIMINARY);
					selectOrder(isFromActiveAllOrderAndHasID);
				}

				if (isOrderIdNumber(isLiveOrderTabAndHasId)) {
					tabsOnChangeSelectedOption(ORDERS_TYPES.LIVE);
					selectOrder(isFromActiveAllOrderAndHasID);
				}

				if (isOrderIdNumber(isExecutingOrderTabAndHasId)) {
					tabsOnChangeSelectedOption(ORDERS_TYPES.EXECUTING);
					selectOrder(isFromActiveAllOrderAndHasID);
				}
			} else {
				// If the phone number doesn't match any existing modals or active orders:
				// Save the phone number to the buffer for received calls.
				// Open a new order form and populate it with the caller's phone number.
				// Clear the current route in the orders page.

				store.dispatch(
					orderPage.actions.setBufferFirstOpenThenCallUp(true),
				);
				store.dispatch(orderPage.actions.setBufferOpenNewTab(true));
				store.dispatch(openOrderCard());
				store.dispatch(orderPage.actions.setRoute([]));
			}
		}
	};

	/**
	 * Extracts the current number from the selected call's session details.
	 */
	const numberCurrency: string | number | undefined =
		selectedCall?.session?.remote_identity?.uri?.user ||
		selectedCall?.session?._remote_identity?._uri?._user;

	const isCallUs = selectedCall?.session?._direction === "incoming";

	// If a numberQuery is provided
	if (numberQuery) {
		/**
		 * Check if there is already an ongoing call with the entered number.
		 */
		if (
			callsData.some((call) =>
				numberQuery.includes(call.session.remote_identity.uri.user),
			)
		)
			return null;

		/**
		 * Form the SIP address using the entered number and the host from the configuration.
		 */
		const number = `sip:${numberQuery}@${uaConfig.config.host}`;

		/**
		 * Check for missed calls stored in local storage and mark them as recalled if found.
		 */
		const missedCallsPhones = localStorage.getItem("missedCallsPhones");
		if (missedCallsPhones) {
			const parsedMissedCalls: string[] = JSON.parse(missedCallsPhones);

			const call = parsedMissedCalls.find((missedCall) =>
				numberQuery.includes(missedCall),
			);

			if (call) {
				await CallService.setNoAnswerAsRecall(call);
			}
		}

		// Make a call to the formed SIP address
		uaConfig.ua.call(number, uaConfig.callOptions);

		// Reset the entered number in Redux
		store.dispatch(softphoneWidget.actions.setNumberQuery(""));
		return null;
	}

	// Pause all current sound notifications
	uaConfig.audio.sounds.pause();

	// If the selected call is not confirmed, answer it
	if (!selectedCall?.session._is_confirmed && selectedCall) {
		selectedCall?.session.answer(uaConfig.callOptions);
		openOrderModal(isCallUs, numberCurrency);

		return null;
	}

	// If the callUpFirstCall flag is passed
	if (callUpFirstCall) {
		/**
		 * Find the first unconfirmed call from the list of current calls.
		 */
		const firstUnconfirmedCall = callsData.find(
			(call) => !call.session._is_confirmed,
		);
		if (firstUnconfirmedCall) {
			firstUnconfirmedCall.session.answer(uaConfig.callOptions);
			openOrderModal(isCallUs, numberCurrency);

			// Update the Redux store with the selected call details
			store.dispatch(
				softphoneWidget.actions.setSelectedCaller(firstUnconfirmedCall),
			);

			// Place the current call on hold
			toggleHold(firstUnconfirmedCall);
			return null;
		}
	} else {
		// If a call is selected, answer it
		selectedCall?.session.answer(uaConfig.callOptions);
		openOrderModal(isCallUs, numberCurrency);

		return null;
	}

	return null;
};

export const getAndCallDown = (selected?: Nullable<Call>) => {
	if (!selected?.request) {
		const { selectedCall } = store.getState().softphoneWidget;
		selectedCall && callDown(selectedCall);
	} else {
		callDown(selected);
	}
};

export const callDown = (call: Call) => {
	const { uaConfig } = store.getState().sipToDispatcher;

	if (call) {
		uaConfig.audio.sounds.pause();
		const callData: Call = call;

		if (call.session?._connection) {
			call.session.terminate();
		}
		store.dispatch(softphoneWidget.actions.terminateCall(callData));
	}
};

export const transferToAnyNumber = async (
	transferType: TransferType,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	callType: CallType,
) => {
	const { selectedCall, transferNumberQuery } =
		store.getState().softphoneWidget;
	const { uaConfig } = store.getState().sipToDispatcher;
	const foundedDispatcherSip = await foundDispatcherByNumber(
		transferNumberQuery,
	);

	const sipUri =
		foundedDispatcherSip ||
		`sip:${transferNumberQuery}@${uaConfig.config.host}`;

	if (transferType === "blind") {
		return selectedCall?.session.refer(sipUri, uaConfig.callOptions);
	}
	if (transferType === "normal") {
		if (!selectedCall) return null;
		const transfer: TransferCall = {
			call: selectedCall,
			sipUri,
			numberToRefer: transferNumberQuery,
		};

		uaConfig.ua.call(sipUri, uaConfig.callOptions);

		store.dispatch(softphoneWidget.actions.setSelectedCaller(selectedCall));

		return store.dispatch(
			softphoneWidget.actions.addToTransferQueue(transfer),
		);
	}
	return null;
};

export const transferSelectedPhone = async (
	selectedPhone: Nullable<ExecutorPhone | DispatcherPhone>,
	transferType: TransferType,
	callType: CallType,
	sipValue?: string,
) => {
	const { uaConfig } = store.getState().sipToDispatcher;
	const { selectedCall } = store.getState().softphoneWidget;

	if (!selectedPhone) return null;

	if (transferType === "blind") {
		if (selectedPhone.type === "executor") {
			const sipUri = `sip:${selectedPhone?.phone}@${uaConfig.config.host}`;
			if (sipUri && selectedPhone?.phone) {
				return selectedCall?.session.refer(
					sipUri,
					uaConfig.callOptions,
				);
			}
		}
		if (selectedPhone.type === "dispatcher") {
			if (callType === "onSip") {
				const phone = sipValue || null;

				const sipUri = `sip:${phone}@${uaConfig.config.host}`;

				if (sipUri && phone) {
					return selectedCall?.session.refer(
						sipUri,
						uaConfig.callOptions,
					);
				}
			}
			if (callType === "onNumber") {
				const phone = selectedPhone.phone.slice(1);
				const sipUri = `sip:${phone}@${uaConfig.config.host}`;
				if (sipUri) {
					return selectedCall?.session.refer(
						sipUri,
						uaConfig.callOptions,
					);
				}
			}
		}
	}
	if (transferType === "normal") {
		if (selectedPhone.type === "executor") {
			if (!selectedCall) return null;
			const phone = selectedPhone.phone.slice(1);

			const sipUri = `sip:${phone}@${uaConfig.config.host}`;

			const transfer: TransferCall = {
				call: selectedCall,
				contactToRefer: selectedPhone,
				sipUri,
				numberToRefer: phone,
			};

			uaConfig.ua.call(sipUri, uaConfig.callOptions);

			store.dispatch(
				softphoneWidget.actions.setSelectedCaller(selectedCall),
			);

			return store.dispatch(
				softphoneWidget.actions.addToTransferQueue(transfer),
			);
		}
		if (selectedPhone.type === "dispatcher") {
			if (callType === "onSip") {
				const phone = sipValue || null;

				const sipUri = `sip:${phone}@${uaConfig.config.host}`;

				if (!selectedCall || !sipUri || !phone) return null;

				const transfer: TransferCall = {
					call: selectedCall,
					contactToRefer: selectedPhone,
					sipUri,
					numberToRefer: phone,
				};

				uaConfig.ua.call(sipUri, uaConfig.callOptions);

				store.dispatch(
					softphoneWidget.actions.setSelectedCaller(selectedCall),
				);

				return store.dispatch(
					softphoneWidget.actions.addToTransferQueue(transfer),
				);
			}
			if (callType === "onNumber") {
				const phone = selectedPhone.phone.slice(1);
				const sipUri = `sip:${phone}@${uaConfig.config.host}`;

				if (sipUri && selectedCall) {
					const transfer: TransferCall = {
						call: selectedCall,
						contactToRefer: selectedPhone,
						sipUri,
						numberToRefer: phone,
					};

					uaConfig.ua.call(sipUri, uaConfig.callOptions);

					store.dispatch(
						softphoneWidget.actions.setSelectedCaller(selectedCall),
					);

					return store.dispatch(
						softphoneWidget.actions.addToTransferQueue(transfer),
					);
				}
			}
		}
	}
	return null;
};

export const toggleMute = () => {
	const { selectedCall } = store.getState().softphoneWidget;

	if (selectedCall) {
		if (selectedCall.session._audioMuted) {
			selectedCall.session.unmute();
		} else {
			selectedCall.session.mute();
		}
	}
};

export const toggleHold = (currentCall?: Call) => {
	const { selectedCall, callsData } = store.getState().softphoneWidget;

	if (currentCall) {
		callsData.map((call) => {
			if (
				call.request.call_id !== currentCall.request.call_id &&
				!call.session._localHold
			) {
				call.session.hold();
			}
			return null;
		});
		return null;
	}

	if (selectedCall) {
		if (selectedCall.session._localHold) {
			selectedCall.session.unhold();
		} else {
			selectedCall.session.hold();
		}
	}
	return null;
};

function formatSeconds(seconds) {
	let hours: string | number = Math.floor(seconds / 3600);
	let minutes: string | number = Math.floor((seconds % 3600) / 60);
	let remainingSeconds: string | number = Math.floor(seconds % 60);

	hours = hours < 10 ? `0${hours}` : hours;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	remainingSeconds =
		remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	if (+hours > 0) {
		return `${hours}:${minutes}:${remainingSeconds}`;
	}
	return `${minutes}:${remainingSeconds}`;
}

export async function foundDispatcherByNumber(
	phoneNumber: string,
	dispatcherId?: number,
) {
	const phoneNumberToCall = phoneNumber.includes("+")
		? phoneNumber.slice(1)
		: phoneNumber;

	const { transferData } = store.getState().softphoneWidget;

	const { uaConfig, sipValue } = store.getState().sipToDispatcher;

	const foundedDispatcher = transferData.dispatchers.find((dispatcher) =>
		dispatcher.additionalFields.phones?.find(
			(phone) => phone === phoneNumberToCall,
		),
	);

	if (foundedDispatcher) {
		const allDispatcherSips: CallService.Model.Available[] =
			await SIPToDispatcher.getAvailable({
				dispatcherId: dispatcherId || foundedDispatcher.id,
				status: "ALL",
			});

		const activeSip = allDispatcherSips.find(
			(sip) =>
				!checkSipForActivity(sip.lastCheckMs) &&
				sip.sipValue !== sipValue,
		);

		if (activeSip) {
			const sipUri = `sip:${activeSip?.sipValue}@${uaConfig.config.host}`;
			return sipUri;
		}
		return null;
	}
	return null;
}
