// /* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State, BtnOnMapToggles, BtnOnFooterToggles } from "./interface";

export const baseBtnOnMap: BtnOnMapToggles = {
	route: true,
	cars: true,
	sectors: false,
	parking: false,
	priceZones: false,
	allowBound: false,
};

export const baseBtnOnFooter: BtnOnFooterToggles = {
	street: true,
	object: false,
	localObject: false,
};

const initialState: State = {
	btnOnMap: baseBtnOnMap,
	btnOnFooter: baseBtnOnFooter,
};

const toggleAllButtonsOnMap: ReduxReducer<State, BtnOnMapToggles> = (
	state,
	action,
) => {
	state.btnOnMap = {
		...baseBtnOnMap,
		...action.payload,
	};
};

const toggleAllButtonsOnFooter: ReduxReducer<State, BtnOnFooterToggles> = (
	state,
	action,
) => {
	state.btnOnFooter = {
		...baseBtnOnFooter,
		...action.payload,
	};
};

const orderMapFromAddModalToggles = createSlice({
	name: Orders.ConstantOrdersSlice.MAP_FROM_ADD_MODEL_TOGGLES,
	initialState,
	reducers: {
		toggleAllButtonsOnMap,
		toggleAllButtonsOnFooter,
	},
});

export const orderMapFromAddModalTogglesAction =
	orderMapFromAddModalToggles.actions;
export const orderMapFromAddModalTogglesReducer =
	orderMapFromAddModalToggles.reducer;
export type orderMapFromAddModalTogglesState = State;

export default orderMapFromAddModalToggles;
