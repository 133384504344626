/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";
import { OrderPageExecutorColumnId } from "../tableSettings";

import {
	State,
	OrderInterfase as OrderInterface,
	ExecuterFilter,
} from "./interface";

export const baseFilters: ExecuterFilter = {
	companyIds: [],
	taxiServiceIds: [],
	executorGroupIds: [],
	fleetIds: [],
	statuses: ["available", "busy", "dinner", "home", "on_order", "closed"],
	online: ["online", "offline"],
	workingStatus: ["working", "notWorking"],
	isYourOrderStatus: true,
	order: {
		column: OrderPageExecutorColumnId.ONLINE,
		type: "asc",
	},
	query: "",
};

const initialState: State = {
	criteria: baseFilters,
	isSortMap: false,
};

const setExecutorsSort: ReduxReducer<State, OrderInterface> = (
	state,
	action,
) => {
	state.criteria.order = action.payload;
};

const setExecutorsFilter: ReduxReducer<State, ExecuterFilter> = (
	state,
	action,
) => {
	state.criteria = { ...action.payload };
	state.isSortMap = true;
};

const setIsSortMap: ReduxReducer<State, State["isSortMap"]> = (
	state,
	{ payload },
) => {
	state.isSortMap = payload;
};

const reset: ReduxReducer<State, any> = (state) => {
	Object.keys(initialState).forEach((key) => {
		state[key] = initialState[key];
	});
};

const orderExecutorsFilters = createSlice({
	name: Orders.ConstantOrdersSlice.EXECUTORS_FILTERS,
	initialState,
	reducers: {
		reset,
		setIsSortMap,
		setExecutorsSort,
		setExecutorsFilter,
	},
});

export const orderExecutorsFiltersAction = orderExecutorsFilters.actions;
export const orderExecutorsFiltersReducer = orderExecutorsFilters.reducer;
export type orderExecutorsFiltersState = State;

export default orderExecutorsFilters;
