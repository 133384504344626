import { Language } from "../../../assets/languages/langs";
import { getPRPC } from "../../../hooks/usePRPC";
import { BlacklistPhoneLevel } from "../../../pages/MainPage/pages/Blacklist/tabs/Customers/components/Modal/components/Content/tabs/Main/components/Level/constants";
import createRPCQuery from "../../../utils/createRPCQuery.util";
import { sortByNumberOrString } from "../../../utils/sortByNumberOrString";

import { CheckCard } from "./getCounterpartyByCode";

interface BlacklistPhoneToCompany extends Record<string, any> {
	id: number;
	login: string;
	name: Record<Language, string>;
	settings: Record<string, any>;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
}
interface BlacklistPhoneToCompanies extends Record<string, any> {
	company: BlacklistPhoneToCompany;
	id: number;
	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;
}
export interface PhoneBlacklistData {
	fatherName: string;
	id: number;

	name: string | null;
	number: number;
	surname: string | null;

	phone: string;
	level: BlacklistPhoneLevel;
	description?: string;

	customer: any | null;

	createdAt: string;
	updatedAt: string;
	deletedAt: string | null;

	dispatcher: Record<string, any>;
	blacklistPhoneToCompanies: BlacklistPhoneToCompanies[];
}
export enum DiscountType {
	Basic = "basic",
	Fixed = "fixed",
	BonusTrip = "bonusTrip",
	Flexible = "flexible",
	Cumulative = "cumulative",
}

export enum DiscountUnit {
	Percentage = "percentage",
	Amount = "amount",
}

export interface DiscountPlan extends Record<string, any> {
	active: boolean;
	name: Record<Language, string>;
	maxDiscount: { value: number; active: boolean };
	discount?: { unit: DiscountUnit; value: number };
	type: DiscountType;
	types?: Record<DiscountType, any>;
	appliesToMinimal?: boolean;
}

export interface PaymentAccounts {
	active: boolean;
	// return to backend
	id: number;
	amount: number;
	configuration: any | null;
	createdAt: string;
	deletedAt: string | null;
	internal: boolean;
	isProvider: boolean;
	type: "main" | "bonus" | "threshold";
	updatedAt: string;
	v: number;
	currency: string;
	currencySymbol: string;
}

export interface PaymentAccount extends PaymentAccounts {
	id: number;
	type: "main" | "bonus";
	amount: number;
	currency: string;
	currencySymbol: string;
}

export interface PhoneInfoCounter {
	cancelled: number;
	failed: number;
	success: number;
	total: number;
}

export const ClientPhoneType = {
	CUSTOMER: "customer",
	EMPLOYEE: "employee",
	AGENT: "agent",
} as const;
export type TypeClientPhoneType = typeof ClientPhoneType;
export type ValueClientPhoneType =
	(typeof ClientPhoneType)[keyof typeof ClientPhoneType];

export interface ClientPhone {
	client: {
		type: ValueClientPhoneType;
		data: Record<string, any> & {
			paymentAccounts: PaymentAccounts[];
			counters: PhoneInfoCounter;
			additionalPlan: DiscountPlan;
			mainPlan: DiscountPlan;
		};
	} | null;
	blacklist: PhoneBlacklistData | null;
	passengersAsCustomersMap: any | null;
	paymentAccounts: PaymentAccount[];
	detectedCheckCard?: CheckCard.Card | null;
}

export interface OnResponseData extends ClientPhone {
	clients?: ClientPhone[] | null;
}

export const getPlanFromPhone = (
	data: Record<string, any>,
	counters?: Record<string, any>,
): DiscountPlan => {
	const type = data?.configuration?.type;
	const types =
		data?.configuration?.general?.types || data?.configuration?.types;

	const grid =
		types?.[type]?.grid?.sort((a, b) =>
			sortByNumberOrString(a?.threshold || 0, b?.threshold || 0),
		) || [];

	const gridDiscount = grid?.find((item, i, arr) => {
		const total = counters?.total || 1;
		const next = arr?.[i + 1];
		if (next) {
			return total >= item?.threshold && total < next.threshold;
		}
		return total >= item?.threshold;
	})?.discount;

	const discount = types?.[type]?.discount || gridDiscount;

	const payload = {
		active: data.active,
		name: data.name,
		discount,
		maxDiscount: data?.configuration?.general?.maxDiscount,
		type,
		types,
		appliesToMinimal: data?.configuration?.general?.appliesToMinimal,
	};

	return payload;
};

export default function getPhoneInfo(
	params: { phone?: string; companyId?: number; companyIds?: number[] },
	onResponse: (result: OnResponseData | null) => void,
) {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		const res = await createRPCQuery(() =>
			prpcow?.theirsModel.phone.getPhoneInfo(params),
		);

		if (!res) return onResponse(null);

		return onResponse(res);
	};
}
