import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE, COMPANIES } = BASE_WIDTH_COLUMNS;

const settingsMessagePushNotices = makeTable({
	allIds: [
		"allowed",
		"name",
		"action",
		"destinationApp",
		"companies",
		"taxiServices",
	],
	defaultIds: [
		"allowed",
		"name",
		"action",
		"destinationApp",
		"companies",
		"taxiServices",
	],

	i18tPath: (id) => `settings.messages.pushNotices.columns.${id}`,
	initialSortMethod: {
		column: "allowed",
		type: "asc",
	},
	initialWidths: {
		allowed: ACTIVE,
		name: 320,
		action: 250,
		destinationApp: 160,
		companies: COMPANIES,
		taxiServices: 430,
	},
	initialResizable: {
		allowed: true,
		name: true,
		action: true,
		destinationApp: true,
		companies: true,
		taxiServices: true,
	},
	initialSortable: {
		allowed: true,
		name: true,
		action: true,
		destinationApp: true,
		companies: true,
		taxiServices: true,
	},
});

export default settingsMessagePushNotices;
