/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */

import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { react, Row, Select, useInternal } from "uikit";
import { InputifiedComponentProps } from "uikit/dist/utils/react";

import Company from "../../services/Company";
import TaxiService from "../../services/TaxiService";
import Map from "../../redux/services/Map";
import useModelSubscribe from "../../hooks/useModelSubscribe";

import InternalController from "./Controller";

const TaxiServiceForm = react.withController<
	TaxiServiceForm.InputifiedPropsBase,
	InternalController
>(
	react.inputify<TaxiServiceForm.InternalPropsBase, TaxiServiceForm.Value>(
		({ value, language, controller, onChange }) => {
			const { t } = useTranslation();
			const rootRef = useRef<HTMLDivElement | null>(null);

			const [companyIdFiledError, setCompanyIdFieldError] =
				useState(false);
			const [taxiServiceIdFiledError, setTaxiServiceIdFieldError] =
				useState(false);

			const companies = useModelSubscribe({}, Company)?.cache;
			const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

			const taxiService = useMemo(
				() =>
					taxiServices?.find(
						(taxiService) => taxiService.id === value,
					),
				[taxiServices, value],
			);

			const companyIdRef = useRef<number | undefined>(undefined);

			const [companyId, setCompanyId] = useInternal(
				taxiService?.company?.id ?? companyIdRef.current,
			);

			companyIdRef.current = companyId;

			const companyOptions = useMemo(
				() =>
					companies?.map((company) => ({
						key: company.id,
						label: company.name?.[language] ?? "",
						value: company.id,
					})) ?? [],
				[companies, language],
			);

			const taxiServiceOptions = useMemo(
				() =>
					companyId
						? taxiServices
								?.filter(
									(taxiService) =>
										taxiService.company?.id === companyId,
								)
								.map((taxiService) => ({
									key: taxiService.id,
									label: taxiService.settlement[language],
									value: taxiService.id,
								}))
						: taxiServices?.map((taxiService) => ({
								key: taxiService.id,
								label: taxiService.settlement[language],
								value: taxiService.id,
						  })),
				[taxiServices, companyId, language],
			);

			// useEffect(() => {
			// 	if (taxiService?.id !== value) {
			// 		onChange(taxiService?.id ?? undefined);
			// 	}
			// }, [taxiService?.id, value, onChange]);

			controller.setContext({
				rootRef,

				value,
				companyId,
				taxiServices,

				setCompanyIdFieldError,
				setTaxiServiceIdFieldError,
			});

			return (
				<Row ref={rootRef} sizes="1fr*" gaps="8px*" maxedWidth>
					<Select
						placeholder={t("taxiServiceForm.str0") ?? ""}
						options={companyOptions}
						value={companyId}
						error={companyIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setCompanyId(option.value);
							onChange(undefined);
						}}
					/>
					<Select
						placeholder={t("taxiServiceForm.str1") ?? ""}
						options={taxiServiceOptions}
						value={value}
						error={taxiServiceIdFiledError}
						onSelect={(option) => {
							setCompanyIdFieldError(false);
							setTaxiServiceIdFieldError(false);
							onChange(option.value);
						}}
					/>
				</Row>
			);
		},
	),
	InternalController,
);

declare namespace TaxiServiceForm {
	type Value = number | undefined;

	interface PropsBase {
		language: Map.Language;
	}

	interface InternalPropsBase extends PropsBase {
		controller: InternalController;
	}

	type InputifiedPropsBase = PropsBase & InputifiedComponentProps<Value>;

	type Props = InputifiedPropsBase;

	type Controller = InternalController;
}

export default TaxiServiceForm;
