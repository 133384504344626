import React, { useMemo, memo, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../services/Language";
import { useTypedDispatch, useTypedSelector } from "../../../redux/store";
import getCompanies from "../../../redux/services/Company/getCompanies";
import { StyledP, StyledRow } from "../styles";

const CompaniesByIdsBase: React.FC<CompaniesByIdsBase.Props> = ({
	taxiServiceIds = [],
	lang,
	onSize,
	colors,
}) => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { companies } = useTypedSelector((state) => state.ordersPageReducer);

	const items = useMemo(() => companies?.items || [], [companies]);
	const ref = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const height = ref?.current?.offsetHeight;
		if (height !== undefined) onSize?.(height);
	}, [onSize]);

	useLayoutEffect(() => {
		if (dispatch && !items.length) {
			dispatch(getCompanies());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const data = useMemo(() => {
		if (!items.length) return [];
		return items
			?.filter((company) => {
				if (company?.taxiServices && company?.taxiServices?.length) {
					return company?.taxiServices?.some((item) =>
						taxiServiceIds.includes(item?.id),
					);
				}
				return false;
			})
			.map((item) => item?.name?.[lang]);
	}, [items, taxiServiceIds, lang]);

	if (!data.length) return <StyledRow></StyledRow>;
	if (data.length === items.length) {
		return (
			<StyledRow gap="0 8px">
				<StyledP colors={colors}>
					{t(["all_company", "All companies"])}
				</StyledP>
			</StyledRow>
		);
	}

	return (
		<StyledRow
			ref={ref}
			gap="0 8px"
			flex={onSize ? { wrap: "wrap" } : undefined}
			w={onSize ? { min: "200px" } : ""}
		>
			{data.length === 1 && <StyledP colors={colors}>{data[0]}</StyledP>}

			{data.length > 1 &&
				data.map((item, i, arr) => (
					<StyledP colors={colors} key={item}>{`${item}${
						arr?.[i + 1] ? "," : ""
					}`}</StyledP>
				))}
		</StyledRow>
	);
};

export const CompaniesByIds = memo(CompaniesByIdsBase);
declare namespace CompaniesByIdsBase {
	interface Props {
		taxiServiceIds: number[];
		lang: Language;
		onSize?: (value: number) => void;
		colors?: string;
	}
}

export default CompaniesByIdsBase;
