import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { theme } from "uikit";

import { useTypedSelector } from "../../redux/store";
import softphoneWidget, { Call } from "../../redux/reducers/softphoneWidget";
import { toggleHold } from "../../utils/jsSip";
import InputModalSoftphone from "../InputModalSoftphone";

import {
	ReduceButton,
	ModalTitle,
	DialingTab,
	LatestTab,
	NavBar,
	Transfer,
} from "./components";

const Softphone = styled("div")`
	width: 290px;

	background-color: ${theme.colors.white};
	border-radius: 0 0 5px 5px;
`;

const SoftphoneWidget = ({ onClose }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		isFocused,
		position,
		isReduce,
		callsData,
		selectedCall,
		tab,
		transfer,
	} = useTypedSelector((state) => state.softphoneWidget);

	const { sipValue, uaConfig } = useTypedSelector(
		(state) => state.sipToDispatcher,
	);

	const onChangePosition = useCallback(
		(e, data) => {
			dispatch(
				softphoneWidget.actions.setPosition({
					x: data.x,
					y: data.y,
				}),
			);
		},
		[dispatch],
	);

	const onReduce = useCallback(() => {
		dispatch(softphoneWidget.actions.setIsReduce(true));
	}, [dispatch]);

	const onExpand = useCallback(() => {
		dispatch(softphoneWidget.actions.setIsReduce(false));
	}, [dispatch]);

	const onSelectCaller = useCallback(
		(callerData: Call) => {
			if (callerData?.request.call_id !== selectedCall?.request.call_id) {
				dispatch(softphoneWidget.actions.setSelectedCaller(callerData));
				dispatch(softphoneWidget.actions.rerenderSoftphone());
				const remoteStream =
					callerData.session?.connection?.getRemoteStreams()[0];
				uaConfig.audio.call.srcObject = remoteStream;
				uaConfig.audio.call.play();
				toggleHold(callerData);
			}
		},
		[dispatch, selectedCall?.request.call_id, uaConfig?.audio?.call],
	);

	const onCloseTransfer = useCallback(() => {
		dispatch(softphoneWidget.actions.setTransfer("none"));
		dispatch(softphoneWidget.actions.setTransferTab("dispatchers"));
		dispatch(softphoneWidget.actions.setTransferNumberQuery(""));
	}, [dispatch]);

	const modalTitle = useMemo(
		() => `${t("softphoneWidget.str200") ?? ""}: ${sipValue}`,
		[sipValue, t],
	);

	return (
		<>
			{transfer === "none" ? (
				<InputModalSoftphone
					draggable
					dimmed={false}
					pointerEvents="none"
					useDefaultFooter={false}
					style={{
						boxShadow: isReduce
							? "0px 8px 30px 6px #0000001a"
							: "0px 8px 16px 6px #0000001a",
						opacity: isFocused ? 1 : 0.8,
						backgroundColor: theme.colors.transparent,
						height: isReduce ? "604px" : "310px",
						zIndex: 1001,
					}}
					onClose={onClose}
					action={
						<ReduceButton
							isReduce={isReduce}
							onExpand={onExpand}
							onReduce={onReduce}
						/>
					}
					title={
						<ModalTitle
							title={modalTitle}
							isSoftphoneReduce={isReduce}
						/>
					}
					onChangePosition={onChangePosition}
					position={position}
				>
					<Softphone>
						{tab === "dialing" && (
							<DialingTab
								selectedCall={selectedCall}
								data={callsData}
								onSelectCaller={onSelectCaller}
								isReduce={isReduce}
							/>
						)}
						{tab === "latest" && <LatestTab />}
						{isReduce && <NavBar />}
					</Softphone>
				</InputModalSoftphone>
			) : (
				<InputModalSoftphone
					dimmed={false}
					style={{
						boxShadow: "0px 8px 30px 6px #0000001a",
						height: "562px",
						opacity: isFocused ? 1 : 0.8,
						zIndex: 1001,
					}}
					useDefaultHeader={false}
					useDefaultFooter={false}
					pointerEvents="none"
					draggable
					transfer={true}
					position={position}
					onChangePosition={onChangePosition}
				>
					<Transfer type={transfer} onClose={onCloseTransfer} />
				</InputModalSoftphone>
			)}
		</>
	);
};

export default SoftphoneWidget;
