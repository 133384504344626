import { Order } from "../../../../services";
import { getPRPC } from "../../../../hooks/usePRPC";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import orderPage from "../../../reducers/OrdersPage";
import { formatOrder } from "../../../constants/OrdersPage/order";
import SubscriptionPool from "../../SubscriptionPool";

let initialized = false;

enum OrderSingleEventType {
	UPDATE = "update",
	ACCEPTED = "accepted",
	RESOLVE_ERROR = "resolveError",
	DELETE_CACHE = "deleteCache",
}

interface OrderForm {
	orderId: number;
	/** this orderFormTab.form as `JSON.stringify` */
	form: string;
}

interface CardUpdateEvent {
	type: OrderSingleEventType;
	data?: Order.Model & OrderForm;
}

export default function initOrderCard() {
	return async (dispatch) => {
		if (initialized) return null;

		if (!getPRPC()) return null;

		initialized = true;

		const cachedDispatcher = {};

		const getDispatcherById = async (id, prpc) => {
			if (!cachedDispatcher[id]) {
				const response = await createRPCQuery(() =>
					prpc.theirsModel.dispatcher.getById(id),
				);
				cachedDispatcher[response.id] = {
					id: response.id,
					name: response.person.name,
					surname: response.person.surname,
					fatherName: response.person.fatherName,
				};
			}

			return cachedDispatcher[id];
		};

		const onUpdateSession = async (state, prpc) => {
			const updatedCardsPromises = state.cards.map(async (card) => {
				let { lockedBy } = card;

				if (lockedBy) {
					// TODO: Move it to the server
					lockedBy = await getDispatcherById(card.lockedBy, prpc);
				}

				console.log(
					"[ OrderModal ] orders.card.subscribe::onUpdate()",
					{
						card,
						order: card.order,
						cards: state.cards,
						state,
						lockedBy,
					},
				);
				return {
					...card,
					lockedBy,
					order: card.order,
				};
			});

			const updatedCards = await Promise.all(updatedCardsPromises);
			dispatch(orderPage.actions.setModalOpen(state.open));
			dispatch(
				orderPage.actions.setModalData({
					...state,
					cards: updatedCards,
				}),
			);
		};

		const onCardUpdate = (event: CardUpdateEvent) => {
			if (event.data) {
				// TODO: Add updated order object to the store
				console.log(
					"[OrderModal]: orders.card.subscribe::onCardUpdate()",
					event,
				);

				if (event.type === OrderSingleEventType.RESOLVE_ERROR) {
					const { orderId, form } = event.data;
					if (orderId && form) {
						dispatch(
							orderPage.actions.updatedOrderForm({
								orderId,
								form,
							}),
						);
					}
					return;
				}

				if (event.type === OrderSingleEventType.DELETE_CACHE) {
					const { orderId } = event.data;
					if (orderId) {
						dispatch(
							orderPage.actions.removeOrderForm({ orderId }),
						);
					}
					return;
				}

				if (event.type === OrderSingleEventType.UPDATE) {
					const formatNewOrder = formatOrder(event.data);
					try {
						dispatch(
							orderPage.actions.updatedModalData(formatNewOrder),
						);
						dispatch(
							orderPage.actions.setActiveOrder(formatNewOrder),
						);
					} catch (error) {
						console.log(
							"[OrderModal Error]: orders.card.subscribe::onCardUpdate()",
							{ error, event },
						);
					}
				}
			}
		};
		await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.orders.card.subscribe({
					ping: () => true,
					onUpdate: (state) => onUpdateSession(state, prpc),
					onCardUpdate: (event: CardUpdateEvent) => {
						if (event.data) onCardUpdate(event);
					},
				}),
			{ name: "initOrderCard" },
		);

		return null;
	};
}
