import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { COMPANIES } = BASE_WIDTH_COLUMNS;

const settingsFinancesExecutorPenalties = makeTable({
	allIds: ["name", "companies", "taxiServices"],
	i18tPath: (id) => `settings.finances.executorPenalties.columns.${id}`,
	defaultIds: ["name", "companies", "taxiServices"],
	initialSortMethod: {
		column: "name",
		type: "asc",
	},
	initialWidths: {
		name: 300,
		companies: COMPANIES,
		taxiServices: 800,
	},
	initialResizable: {
		name: true,
		companies: true,
		taxiServices: true,
	},
	initialSortable: {
		name: true,
		companies: true,
		taxiServices: true,
	},
});

export default settingsFinancesExecutorPenalties;
