import React, { useCallback } from "react";
import styled from "styled-components";

import {
	typographyStyles,
	baseStyles,
	flexStyles,
	distributeStyles,
	visible,
	scrollbar,
	Style,
} from "..";
/* <{ disabled?: boolean; w }> */
// ${({ w }) => (w ? `width: ${w};` : "")}

// font-size: clamp(12px, 1.7vh, 14px);
// font-style: normal;
// font-weight: 400;
// line-height: 18px;
// color: #a6a8aa;

// color: #21333f;
const StyleInput = styled.input<Input.Props>`
	height: 100%;
	max-height: 32px;
	min-width: 144px;

	background-color: #ffffff;
	border-right: 1px solid;
	border-bottom: 1px solid;
	border-left: 1px solid;
	border-top: 1px solid;
	border-radius: 5px;
	border-color: #dee0e2;

	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&::placeholder {
		color: #aaa;
	}

	&:disabled {
		background-color: #e9e9e9;
		color: #a6a8aa;
		cursor: not-allowed;

		&::placeholder {
			color: #a6a8aa;
		}
	}

	${baseStyles}
	${distributeStyles}
  ${flexStyles}
  ${typographyStyles}

  ${visible}
  ${scrollbar}

  	padding: 6px 12px;
`;

const Input: React.FC<Input.Props> = ({
	// w = "144px",
	disableInteractions = false,
	onCopy,
	onCut,
	onPaste,
	onDragStart,
	onDrop,

	...props
}) => {
	const handleCopy = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			if (disableInteractions) e.preventDefault();
			onCopy && onCopy(e);
		},
		[disableInteractions, onCopy],
	);

	const handleCut = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			if (disableInteractions) e.preventDefault();
			onCut && onCut(e);
		},
		[disableInteractions, onCut],
	);

	const handlePaste = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			if (disableInteractions) e.preventDefault();
			onPaste && onPaste(e);
		},
		[disableInteractions, onPaste],
	);

	const handleDragStart = useCallback(
		(e: React.DragEvent<HTMLInputElement>) => {
			if (disableInteractions) e.preventDefault();
			onDragStart && onDragStart(e);
		},
		[disableInteractions, onDragStart],
	);

	const handleDrop = useCallback(
		(e: React.DragEvent<HTMLInputElement>) => {
			if (disableInteractions) e.preventDefault();
			onDrop && onDrop(e);
		},
		[disableInteractions, onDrop],
	);
	return (
		<StyleInput
			{...props}
			// style={{ width: w }}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			onDragStart={handleDragStart}
			onDrop={handleDrop}
			// w={w}
		/>
	);
};

// <SelectContainer width={w}>

// </SelectContainer>

declare namespace Input {
	interface Props
		extends React.InputHTMLAttributes<HTMLInputElement>,
			Style.InputType {
		// w?: string;
		disableInteractions?: boolean;
	}
}

export default Input;
