import React, { useMemo, lazy, Suspense, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	SettingsTabProps,
	CommunicationMethods,
} from "../../../../components/Settings/tabProps";
import {
	SideTab,
	StyledRow,
	SuspenseLoader,
} from "../../../../components/common"; // .

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyProvider = lazy<React.FC<MessageSettingsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Provider" /* webpackChunkName: "settings-message-page-provider" */
	);
	return { default: elem.default };
});

const LazyTemplates = lazy<React.FC<MessageSettingsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Templates" /* webpackChunkName: "settings-message-page-templates" */
	);
	return { default: elem.default };
});

const LazyPushNotices = lazy<React.FC<MessageSettingsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/PushNotices" /* webpackChunkName: "settings-message-page-push-notices" */
	);
	return { default: elem.default };
});

const MessageSettingsPage: React.FC = () => {
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.PROVIDER_SMS,
					label: t("settings.messages.providerSms.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyProvider
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.PROVIDER_SMS],
				},
				{
					key: TabKeys.TEMPLATES_SMS,
					label: t("settings.messages.templatesSms.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyTemplates
									methods={() => ""}
									onHasChanges={() => ""}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.TEMPLATES_SMS],
				},
				{
					key: TabKeys.PUSH_NOTICES,
					label: t("settings.messages.pushNotices.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyPushNotices
									methods={() => ""}
									onHasChanges={() => ""}
								/>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.PUSH_NOTICES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);
	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace MessageSettingsPage {
	interface Props extends SettingsTabProps {}
}

export default MessageSettingsPage;
