import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE } = BASE_WIDTH_COLUMNS;

const preferencesColors = makeTable({
	allIds: ["active", "name", "assignedColor", "company", "taxiService"],
	i18tPath: (id) => `mainPage.preferences.colors.columns.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	defaultIds: ["active", "name", "assignedColor", "company", "taxiService"],
	initialWidths: {
		active: ACTIVE,
		name: 350,
		assignedColor: 350,
		company: 250,
		taxiService: 250,
	},
	initialResizable: {
		active: true,
		name: true,
		assignedColor: true,
		company: true,
		taxiService: true,
	},
	initialSortable: {
		active: true,
		name: true,
		assignedColor: true,
		company: true,
		taxiService: true,
	},
});

export default preferencesColors;
