import React, { useMemo } from "react";
import moment from "moment";
import { isNumber, isString } from "lodash";
import { useTranslation } from "react-i18next";
import { theme } from "uikit";

import Language from "../../../../../../services/Language";
import useTime from "../../../../../../hooks/useTime";
import OrderStatus from "../../../../../../types/OrderStatus";
import { ExecuteOrderStage } from "../../../../../../redux/constants/OrdersPage/order";
import Order from "../../../../../../services/Order";

function formatDuration(milliseconds: number): string {
	const sign = Math.sign(milliseconds);

	// eslint-disable-next-line no-param-reassign
	milliseconds = Math.abs(milliseconds);

	const seconds = Math.floor((milliseconds / 1e3) % 60);
	const minutes = Math.floor((milliseconds / (1e3 * 60)) % 60);
	const hours = Math.floor((milliseconds / (1e3 * 60 * 60)) % 24);
	const days = Math.floor(milliseconds / (1e3 * 60 * 60 * 24));

	const formattedHours = String(hours).padStart(2, "0");
	const formattedMinutes = String(minutes).padStart(2, "0");
	const formattedSeconds = String(seconds).padStart(2, "0");
	const formattedDays = days !== 0 ? `${days}D ` : "";

	return `${
		sign === -1 ? "-" : ""
	}${formattedDays}${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function useTopData(activeOrder: any, language: Language) {
	const currentTime = useTime("every-second");

	const { t } = useTranslation();

	const created = useMemo(
		() =>
			activeOrder.filledAt
				? moment(activeOrder.filledAt)
						.local(true)
						.format("DD.MM.yyyy HH:mm:ss")
				: "-",
		[activeOrder.filledAt],
	);

	const lastReceivedOrder = useMemo(
		() => activeOrder?.additionalFields?.transfer?.lastReceivedOrder,
		[activeOrder?.additionalFields?.transfer?.lastReceivedOrder],
	);

	const executorArrivalTimestamp = useMemo(
		() =>
			activeOrder?.executorToOrder?.[0]?.additionalFields?.arrivalTime ||
			lastReceivedOrder?.executorToOrder?.[0]?.additionalFields
				?.arrivalTime ||
			activeOrder.orderDate,
		[
			activeOrder?.executorToOrder,
			activeOrder?.orderDate,
			lastReceivedOrder?.executorToOrder,
		],
	);

	const isThereExecutorArrivalTimestamp = useMemo(
		() =>
			isNumber(executorArrivalTimestamp) ||
			isString(executorArrivalTimestamp),
		[executorArrivalTimestamp],
	);

	const executorArrivalTime = useMemo(
		() => moment(executorArrivalTimestamp),
		[executorArrivalTimestamp],
	);

	const timeLeft = useMemo(
		() =>
			isThereExecutorArrivalTimestamp
				? executorArrivalTime.diff(currentTime)
				: undefined,
		[currentTime, executorArrivalTime, isThereExecutorArrivalTimestamp],
	);

	const feeding = useMemo(
		() =>
			isThereExecutorArrivalTimestamp &&
			(activeOrder?.executorToOrder?.length ||
				lastReceivedOrder?.executorToOrder?.length) ? (
				<>
					{moment(executorArrivalTime)
						.set({ second: 0, millisecond: 0 })
						.local(true)
						.format("DD.MM.yyyy HH:mm:ss")}
					{isNumber(timeLeft) &&
					activeOrder.status !== OrderStatus.CLOSED ? (
						<span style={{ color: theme.colors.primary }}>
							{" "}
							({formatDuration(timeLeft)})
						</span>
					) : (
						""
					)}
				</>
			) : (
				"-"
			),
		[
			activeOrder?.executorToOrder?.length,
			activeOrder.status,
			executorArrivalTime,
			isThereExecutorArrivalTimestamp,
			lastReceivedOrder?.executorToOrder?.length,
			timeLeft,
		],
	);

	const completion = useMemo(() => "-", []);

	const status = useMemo(() => {
		const hasExecutor = activeOrder?.executorToOrder?.at(0);

		if (activeOrder.status === OrderStatus.EXECUTING) {
			const { executingStage, executingStageUpdatedAt } = hasExecutor
				? activeOrder
				: lastReceivedOrder || activeOrder;

			const time = moment(executingStageUpdatedAt).format(
				"HH:mm:ss DD/MM/yyyy",
			);

			const EXECUTE_STAGE = {
				[ExecuteOrderStage.ASSIGNED]: t([
					`order_executing_stages.assigned`,
					"Driver assigned",
				]),
				[ExecuteOrderStage.ACCEPTED]: t([
					`order_executing_stages.accepted`,
					"Accepted",
				]),
				[ExecuteOrderStage.ARRIVED]: t([
					`order_executing_stages.arrived`,
					"Has arrived",
				]),
				[ExecuteOrderStage.DELAYED]: t([
					`order_executing_stages.delayed`,
					"The driver is delayed",
				]),
				[ExecuteOrderStage.WAITING_CLIENT]: t([
					`order_executing_stages.waiting_for_client`,
					"Waiting for the client",
				]),
				[ExecuteOrderStage.WITH_CLIENTS]: t([
					`order_executing_stages.with_clients`,
					"With clients",
				]),
				[ExecuteOrderStage.FAILED]: t([
					`order_executing_stages.failed`,
					"Not done",
				]),
				[ExecuteOrderStage.EXECUTED]: t([
					`order_executing_stages.executed`,
					"Completed",
				]),
			};

			return `${EXECUTE_STAGE[executingStage]} (${time})`;
		}
		const ORDER_STATUS_MAP = {
			[OrderStatus.CREATING]: t([`order_status.creating`, "Creation"]),
			[OrderStatus.CREATED]: t([`order_status.created`, "Created"]),
			[OrderStatus.INSTANT_DISTRIBUTION]: t([
				`order_status.instant_distribution`,
				"Quick auto search",
			]),
			[OrderStatus.DISTRIBUTION]: t([
				`order_status.distribution`,
				"Auto search",
			]),
			[OrderStatus.REDISTRIBUTION]: t([
				`order_status.redistribution`,
				"Repeated auto search",
			]),
			[OrderStatus.DISTRIBUTION_AND_FREE_WAVE]: t([
				`order_status.distribution_and_free_wave`,
				"Distribution and free wave",
			]),
			[OrderStatus.FREE_WAVE]: t([`order_status.free_wave`, "Free wave"]),
			[OrderStatus.ASSIGNED_MANUALLY]: t([
				`order_status.assigned_manually`,
				"Assigned manually",
			]),
			[OrderStatus.EXECUTING]: t([
				`order_status.executing`,
				"Performance",
			]),
			[OrderStatus.CLOSED]: t([`order_status.closed`, "Closed"]),
		};

		if (activeOrder.status === OrderStatus.CLOSED) {
			const closeComment = !hasExecutor
				? lastReceivedOrder?.closedOrderComments?.[0] ||
				  activeOrder.closedOrderComments?.[0]
				: activeOrder.closedOrderComments?.[0];

			const reason = closeComment?.status
				? t(`order_closed_status.${closeComment.status}`).toLowerCase()
				: "";

			const subReason = closeComment?.subStatus
				? t(`order_closed_status.${closeComment.subStatus}`)
				: "";

			let by: string | undefined;

			if (closeComment?.dispatcher) {
				by = t(`order_closed_by.dispatcher`) as string;
			}
			if (closeComment?.executor) {
				by = t(`order_closed_by.executor`) as string;
			}
			if (closeComment?.customer) {
				by = t(`order_closed_by.customer`) as string;
			}

			return `${t(`order_closed_by.closed`) ?? ""}${
				reason ? `, ${reason}` : ""
			}${subReason ? ` (${subReason})` : ""} ${by ? `(${by})` : ""}`;
		}
		return ORDER_STATUS_MAP[activeOrder.status];
	}, [activeOrder, lastReceivedOrder, t]);

	const client = useMemo(() => {
		if (activeOrder?.passengers?.length) {
			const { counterparty, status } = activeOrder;

			const { isOwn } = activeOrder;
			const executorToOrder = activeOrder?.executorToOrder;

			if (!isOwn && !executorToOrder.length) return "-";
			if (!isOwn && status === OrderStatus.CLOSED) return "-";
			const fromPhones =
				activeOrder?.additionalFields?.transfer?.lastReceivedOrder
					?.phones || [];

			const sortedPhones: Order.Phone[] = isOwn
				? [...activeOrder.phones].sort((a, b) => a.group - b.group)
				: [...fromPhones].sort((a, b) => a.group - b.group);

			const firstPhone = sortedPhones.at(0)?.number || "";

			const customerPerson =
				activeOrder.passengers?.find((passenger) => {
					const passengerPhones = passenger.customer?.person?.phones;

					return (
						passengerPhones?.some(
							(phone) => phone.number === firstPhone,
						) ?? false
					);
				})?.customer?.person ??
				activeOrder.passengers?.at(0)?.customer?.person;

			const phone = firstPhone;

			const result = [
				counterparty?.additionalFields?.name,
				customerPerson?.name,
				phone,
			]
				.filter(Boolean)
				.join(", ");

			return result.length === 0 ? "-" : result;
		}

		return "-";
	}, [activeOrder]);

	const executor = useMemo(() => {
		if (activeOrder?.executorToOrder?.length) {
			const { isOwn } = activeOrder as Order.Model;
			const [executorToOrder] = activeOrder.executorToOrder;
			const { executor } = executorToOrder;

			const taxiServiceId = activeOrder?.taxiService?.id || "";
			const settlement =
				activeOrder?.taxiService?.settlement?.[language] || "";
			const companyName =
				activeOrder?.taxiService?.company?.name?.[language] || "";

			const executorTaxiServiceId = executor?.taxiService?.id || "";
			const executorTaxiService =
				executor?.taxiService?.settlement?.[language] || "";
			const executorCompany =
				executor?.taxiService?.company?.name?.[language] || "";

			let carLabel = "";
			if (activeOrder.carToOrder.length) {
				const [carToOrder] = activeOrder.carToOrder;
				const { car } = carToOrder;
				const number = car?.additionalFields?.registrationNumber || "";
				const model = car.modelToBodyType.model.name || "";
				const brand =
					car.modelToBodyType.model.baseTypeToBrand.brand.name;
				const color = car?.color?.name?.[language] || "";
				const label = `${brand} ${model}${
					number ? `(${number})` : ""
				}, ${color}`;
				carLabel = label;
			}

			if (executor) {
				const { name } = executor.person;
				const phone = executor.person?.phones?.[0]?.number || "";

				if (!isOwn) {
					const list = [
						taxiServiceId === executorTaxiServiceId,
						settlement === executorTaxiService,
						companyName === executorCompany,
					];

					if (list.every((item) => item === true)) {
						return [
							`${name} (${executor.callSign})`,
							phone,
							carLabel,
						]
							.filter(Boolean)
							.join(", ");
					}

					const company = `${executorCompany} (${executorTaxiService})`;
					return [phone, carLabel, company]
						.filter(Boolean)
						.join(", ");
				}

				return [`${name} (${executor.callSign})`, phone, carLabel]
					.filter(Boolean)
					.join(", ");
			}
		}

		if (
			activeOrder?.additionalFields?.transfer?.executor ||
			lastReceivedOrder?.executorToOrder?.length
		) {
			const { isOwn, additionalFields } = activeOrder as Order.Model;
			const transfer = additionalFields?.transfer;
			const executorFromTransfer =
				transfer?.executor ||
				Order.getDatByExecutorForTransfer(lastReceivedOrder);

			if (isOwn && executorFromTransfer) {
				const number = executorFromTransfer?.carNumber || "";
				const model = executorFromTransfer?.carModel || "";
				const brand = executorFromTransfer?.carBrand || "";
				const color = executorFromTransfer?.carColor?.[language];
				const label = `${brand} ${model}${
					number ? `(${number})` : ""
				}, ${color}`;
				const executorTaxiServiceName =
					executorFromTransfer?.executorTaxiServiceName?.[language] ||
					"";
				const executorCompanyName =
					executorFromTransfer?.executorCompanyName?.[language] || "";

				const company = `${executorCompanyName} (${executorTaxiServiceName})`;

				const executorPhone =
					transfer?.lastReceivedOrder?.executorToOrder?.at?.(0)
						?.executor?.person?.phones?.[0]?.number || "";

				return [executorPhone, label, company]
					.filter(Boolean)
					.join(", ");
			}
		}

		return "-";
	}, [activeOrder, language, lastReceivedOrder]);

	const classes = useMemo(() => {
		if (activeOrder?.orderToCarClasses?.length) {
			return activeOrder.orderToCarClasses
				.map((item) => item.carClass?.name?.[language])
				.join(", ");
		}

		return "-";
	}, [activeOrder.orderToCarClasses, language]);

	const rateSettings = useMemo(
		() => activeOrder?.additionalFields?.rateSettings ?? {},
		[activeOrder?.additionalFields?.rateSettings],
	);

	const taximeter = useMemo(
		() => activeOrder?.additionalFields?.taximeter,
		[activeOrder?.additionalFields?.taximeter],
	);

	const rateSettingTypes = useMemo(
		() => Object.keys(rateSettings),
		[rateSettings],
	);

	const parameters = useMemo(() => {
		const usedRateSettingTypes = rateSettingTypes.filter(
			(rateSettingType) => rateSettings[rateSettingType],
		);

		if (taximeter) {
			usedRateSettingTypes.push("taximeter");
		}

		if (usedRateSettingTypes.length) {
			return usedRateSettingTypes
				.map((rateSettingType) => {
					if (rateSettingType === "taximeter") {
						return (
							t(
								"pages.mainPage.pages.orders.orderModal.orderRideInformation.str207",
							) ?? ""
						);
					}

					return t(`order_rate_setting.${rateSettingType}`) ?? "";
				})
				.join(", ");
		}

		return "-";
	}, [taximeter, rateSettingTypes, rateSettings, t]);

	const services = useMemo(() => {
		if (activeOrder?.orderToServices?.length) {
			return activeOrder.orderToServices
				.map((item) => item?.service?.name?.[language] || "")
				.join(", ");
		}

		return "-";
	}, [activeOrder.orderToServices, language]);

	const notes = useMemo(
		() => (activeOrder?.orderNote ? activeOrder.orderNote : "-"),
		[activeOrder],
	);

	const customerNotes = useMemo(
		() =>
			activeOrder?.additionalFields?.customerNotes
				? activeOrder?.additionalFields?.customerNotes
				: "-",
		[activeOrder?.additionalFields?.customerNotes],
	);

	return {
		created,
		feeding,
		completion,
		status,
		client,
		executor,
		classes,
		parameters,
		services,
		notes,
		customerNotes,
	};
}

export default useTopData;
