import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const agentOrdersReport = makeTable({
	allIds: [
		"orderNumber",
		"phone",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"agentFeeUah",
		"orderAmountUah",
		"paymentType",
		"status",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.agentOrdersReport.columns.${id}`,
	defaultIds: [
		"orderNumber",
		"phone",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"agentFeeUah",
		"orderAmountUah",
		"paymentType",
		"status",
	],
	initialSortMethod: {
		column: "created",
		type: "asc",
	},
	initialWidths: {
		orderNumber: 80,
		phone: 120,
		passenger: 100,
		from: 200,
		to: 160,
		note: 400,
		orderNote: 400,
		created: DATE,
		when: DATE,
		completed: DATE,
		callSign: 110,
		agentFeeUah: 200,
		orderAmountUah: 130,
		paymentType: 100,
		status: 100,
	},
	initialResizable: {
		orderNumber: true,
		phone: true,
		passenger: true,
		from: true,
		to: true,
		note: true,
		orderNote: true,
		created: true,
		when: true,
		completed: true,
		callSign: true,
		agentFeeUah: true,
		orderAmountUah: true,
		paymentType: true,
		status: true,
	},
	initialSortable: {
		orderNumber: true,
		phone: true,
		passenger: true,
		from: true,
		to: true,
		note: true,
		orderNote: true,
		created: true,
		when: true,
		completed: true,
		callSign: true,
		agentFeeUah: true,
		orderAmountUah: true,
		paymentType: true,
		status: true,
	},
});

export default agentOrdersReport;
