// /* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State, BufferNumberTelefone } from "./interface";

const initialState: State = {
	receivedCall: {
		/**
		 * @deprecated This value is obsolete. It is no longer used or populated
		 * anywhere in the application. Consider removing references to it.
		 */
		bufferNumberTelefone: null,
	},
};

const saveNumberToReceivedCallBuffer: ReduxReducer<
	State,
	BufferNumberTelefone
> = (state, action) => {
	state.receivedCall.bufferNumberTelefone = action.payload;
};

const orderSoftPhoneCalls = createSlice({
	name: Orders.ConstantOrdersSlice.SOFTPHONE_CALLS,
	initialState,
	reducers: {
		saveNumberToReceivedCallBuffer,
	},
});

export const orderSoftPhoneCallsAction = orderSoftPhoneCalls.actions;
export const orderSoftPhoneCallsReducer = orderSoftPhoneCalls.reducer;
export type orderSoftPhoneCallsState = State;

export default orderSoftPhoneCalls;
