/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

// eslint-disable-next-line import/no-unresolved
import { ModelEventMeta } from "@node-elion/syncron";
import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNames } from "../../assets/languages/langs";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import RequiredProperties from "../../types/RequiredProperties";
import { DisplayFields } from "../../constants/access";
import { Service, Base, Card, Language } from "..";

const logger = createLogger({ name: "Role" });

interface ServiceSubscribeOnUpdateData<Model> {
	models: Model[];
	metadataState: ModelEventMeta;
}

type OnUpdate<Model> = (
	data: ServiceSubscribeOnUpdateData<Model>,
) => void | Promise<void>;

interface Subscription<Options> {
	unsubscribe(): Promise<void>;
	update(options: Options): Promise<void>;
}

class Role extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.role.card);

		return this._Card;
	}

	public static fromResponse(data: any, language?: Language): Role.Model {
		return {
			id: data.id,

			services: data.roleToServices?.map((toService) =>
				Service.fromResponse(toService.service),
			),

			dispatcherIds: data.roleToDispatchers?.map(
				(toDispatcher) => toDispatcher.dispatcher.id,
			),
			executorIds: data.roleToExecutors
				? data.roleToExecutors?.map(
						(toExecutor) => toExecutor.executor?.id,
				  )
				: null,

			serviceIds: data.roleToServices
				?.filter((item) => item?.deletedAt === null)
				.map((toService) => toService.service?.id),
			taxiServiceIds: data.roleToTaxiServices?.map(
				(toTaxiService) => toTaxiService.taxiService.id,
			),
			// TODO: is not correct!
			name: language ? data.name?.[language] : data.name?.ru,
			description: data.description ?? "",
			permissions: data.permissions,
			additionalFields: data.additionalFields,
			displayFields: data.displayFields,

			active: data.active,
			assignableTo: data.assignableTo,
			default: data.default,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: Role.Model.New | Role.Model.Modified): any {
		return {
			dispatcherIds: model.dispatcherIds,
			executorIds: model.executorIds,
			serviceIds: model.serviceIds,
			taxiServiceIds: model.taxiServiceIds,
			name: createObjectLanguageNames(model.name),
			description: model.description === "" ? " " : model.description,
			permissions: model.permissions,
			additionalFields: model.additionalFields,
			displayFields: model.displayFields,
			active: model.active,
			assignableTo: model.assignableTo,
			default: model.default,
		};
	}

	public static async store(object: Role.Model.New) {
		try {
			logger.info("[Role] store Start", Role.toRequest(object));
			this.request((prpc) =>
				prpc.theirsModel.role.create(Role.toRequest(object)),
			)?.catch((e: any) => logger.error("[Role] Error store:", e));
		} catch (err: any) {
			logger.error("[Role] Error store:", err);
			return false;
		}
		return true;
	}

	public static async update(object: Role.Model.Modified) {
		try {
			logger.info("[Role] update Start", Role.toRequest(object));
			this.request((prpc) =>
				prpc.theirsModel.role.update(object.id, Role.toRequest(object)),
			)?.catch((e: any) => logger.error("[Role] Error update:", e));

			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.role.update(
						object.id,
						Role.toRequest(object),
					),
				{ silent: false, error: true },
			);
			return res;
		} catch (err: any) {
			logger.error("[Role] Error update:", err);
			return false;
		}
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: Role.SubscribeOptions,
		onUpdate: OnUpdate<Role.Model>,
	): Promise<Subscription<Role.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[Role] subscribe", state);
				onUpdate({
					...state,

					models: state.models.map((item) =>
						this.fromResponse(item, options.language),
					),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.role.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						logger.error("[Role] Error subscription:", error);
					},
				}),
			{ name: "Role.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Role.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Role.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.role.delete(id));
	}

	private static optionsToRequest(options: Role.SubscribeOptions) {
		return {
			limit: options.limit,
			offset: options.offset,
			query: options.query,
			order: options.order,

			active: options.active,
			assignableTo: options.assignableTo,
			lang: options.language,
			taxiServiceIds: options.taxiServiceIds,
		};
	}
}

declare namespace Role {
	type AssignableTo = "dispatcher" | "executor";

	interface Model {
		id: number;

		services?: Service.Model[];

		dispatcherIds?: number[];
		executorIds?: number[];
		serviceIds?: number[];
		taxiServiceIds?: number[];

		name: string;
		description: string;
		permissions: string[];
		additionalFields?: Record<Language, any>;
		displayFields?: DisplayFields;

		active: boolean;
		assignableTo: AssignableTo;
		default: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions extends ServiceSubscribeOptionsBase<Role.Model> {
		active?: boolean;
		assignableTo?: AssignableTo;
		language?: Language;
		taxiServiceIds?: number[];
	}

	namespace Model {
		type New = Omit<Model, "id" | "createdAt" | "updatedAt" | "deletedAt">;
		type Modified = RequiredProperties<
			Partial<Omit<Model, "createdAt" | "updatedAt" | "deletedAt">>,
			"id"
		>;
	}
}

export default Role;
