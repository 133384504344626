import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const accountingReplenishPerformersBalance = makeTable({
	allIds: [
		"executorCallSign",
		"paymentDate",
		"paymentSum",
		"notes",
		"dispatcherFullName",
	],
	i18tPath: (id) =>
		`mainPage.accounting.replenishPerformersBalance.columns.${id}`,
	defaultIds: [
		"executorCallSign",
		"paymentDate",
		"paymentSum",
		"notes",
		"dispatcherFullName",
	],
	initialSortMethod: {
		column: "paymentDate",
		type: "asc",
	},
	initialWidths: {
		executorCallSign: 250,
		paymentDate: DATE,
		paymentSum: 200,
		notes: 250,
		dispatcherFullName: 250,
	},
	initialResizable: {
		executorCallSign: true,
		paymentDate: true,
		paymentSum: true,
		notes: true,
		dispatcherFullName: true,
	},
	initialSortable: {
		executorCallSign: true,
		paymentDate: true,
		paymentSum: true,
		notes: true,
		dispatcherFullName: true,
	},
});

export default accountingReplenishPerformersBalance;
