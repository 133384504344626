import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE, COMPANIES } = BASE_WIDTH_COLUMNS;

const accountsExecutorTeams = makeTable({
	allIds: ["active", "name", "companies", "branches"],
	i18tPath: (id) => `mainPage.accounts.executorTeams.columns.${id}`,
	defaultIds: ["active", "name", "companies", "branches"],
	initialSortMethod: {
		column: "name",
		type: "asc",
	},
	initialWidths: {
		active: ACTIVE,
		name: 320,
		companies: COMPANIES,
		branches: 896,
	},
	initialResizable: {
		active: true,
		name: true,
		companies: true,
		branches: true,
	},
	initialSortable: {
		active: true,
		name: true,
		companies: true,
		branches: true,
	},
});

export default accountsExecutorTeams;
