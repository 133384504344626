/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import createRPCQuery from "../../utils/createRPCQuery.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { MessageTemplateActions } from "../../types/MessageTemplateEnums";
import { Base, Dispatcher, SIP } from "..";

const logger = createLogger({ name: "SIPToDispatcher" });

function isNewModel(
	model: SIPToDispatcher.Model.New | SIPToDispatcher.Model.Modified,
): model is SIPToDispatcher.Model.New {
	return "taxiServiceId" in model;
}

class SIPToDispatcher extends Base {
	static fromResponse(data: any): SIPToDispatcher.Model {
		return {
			id: data.id,

			dispatcher: data.dispatcher
				? Dispatcher.fromResponse(data.dispatcher)
				: undefined,
			sip: data.sip ? SIP.fromResponse(data.sip) : undefined,

			active: data.active,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: SIPToDispatcher.Model.New | SIPToDispatcher.Model.Modified,
	): any {
		if (isNewModel(model)) {
			return {
				taxiServiceId: model.taxiServiceId,
				dispatcherId: model.dispatcherId,
				sipId: model.sipId,

				// isSoftPhone: model.isSoftPhone,
			};
		}

		return {
			isSoftPhone: model.isSoftPhone,
		};
	}

	public static async getById(dispatcherId: number) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.getById(dispatcherId),
		);
		return data;
	}

	public static async getAvailable(
		options: SIPToDispatcher.GetAvailable,
	): Promise<SIP.Model.Available[]> {
		const data = await this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.getAvailable(
				SIPToDispatcher.getAvailableOptionsToRequest(options),
			),
		);

		return data;
	}

	public static async initSession(options: { sipToDispatcherId: number }) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.initSipSession(options),
		);
		return data;
	}

	public static async store(object: SIPToDispatcher.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.create(
				SIPToDispatcher.toRequest(object),
			),
		);

		return data;
	}

	public static async update(object: SIPToDispatcher.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.sipToDispatcher.update(
				object.id,
				SIPToDispatcher.toRequest(object),
			),
		);

		return data;
	}

	public static async destroy(id: number) {
		this.request((prpc) => prpc.theirsModel.sipToDispatcher.delete(id));
	}

	static async callMessageToCustomer(params: {
		orderId: number;
		callMessageAction: MessageTemplateActions;
	}) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.sipToDispatcher.callMessageToCustomer(
						params,
					),
				{ silent: false, error: true },
			);

			logger.info("[SIPToDispatcher] callMessageToCustomer", {
				params,
				res,
			});

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async subscribe(
		options: SIPToDispatcher.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<SIPToDispatcher.Model>,
	): Promise<Subscription<SIPToDispatcher.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.sipToDispatcher.subscribe({
						params: this.optionsToRequest(options),
						ping: () => true,
						onEvent: (event) => {
							modelEventConstructor.onEvent(event);
						},
						onError: (error) => {
							logger.error(error);
						},
					}),
				),
			{ name: "SIPToDispatcher.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: SIPToDispatcher.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<SIPToDispatcher.SubscribeOptions>;
	}

	private static getAvailableOptionsToRequest(
		options: SIPToDispatcher.GetAvailable,
	) {
		return {
			dispatcherId: options.dispatcherId,
			sipAvailableStatus: options.status,
		};
	}

	private static optionsToRequest(options: SIPToDispatcher.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			query: options.query,

			isSoftPhone: options.isSoftPhone,
			isFree: options.isFree,

			order: options.order,
		};
	}
}

declare namespace SIPToDispatcher {
	export interface Model {
		id: number;

		dispatcher?: Dispatcher.Model;
		sip?: SIP.Model;

		active: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<SIPToDispatcher.Model> {
		isSoftPhone?: boolean;
		isFree?: boolean;
		taxiServiceIds?: number[];
	}

	interface GetAvailable {
		dispatcherId: number;

		status: "DENY" | "ALLOW" | "ALL";
	}

	namespace Model {
		interface New {
			taxiServiceId: number;
			dispatcherId: number;
			sipId: number;

			isSoftPhone: string;
		}

		interface Modified {
			id: number;

			isSoftPhone: string;
		}
	}
}

export default SIPToDispatcher;
