import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE, DATE } = BASE_WIDTH_COLUMNS;

const preferencesObjectSecondTable = makeTable({
	allIds: ["active", "name", "company", "branch", "createdAt", "properties"],
	i18tPath: (id) => `mainPage.preferences.objects.columns.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	defaultIds: [
		"active",
		"name",
		"company",
		"branch",
		"createdAt",
		"properties",
	],
	initialWidths: {
		active: ACTIVE,
		name: 800,
		company: 150,
		branch: 150,
		createdAt: DATE,
		properties: 150,
	},
	initialResizable: {
		active: true,
		name: true,
		company: true,
		branch: true,
		createdAt: true,
		properties: true,
	},
	initialSortable: {
		active: true,
		name: true,
		company: true,
		branch: true,
		createdAt: true,
		properties: true,
	},
});

export default preferencesObjectSecondTable;
