import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import Button from "../Button";

const CallClientButton: React.FC<CallClientButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.CALL_ORDER_CLIENT;

	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const isOwn = useMemo(() => activeOrder?.isOwn, [activeOrder]);
	const orderStatus = useMemo(() => activeOrder?.status, [activeOrder]);
	const lastReceivedOrder = useMemo(() => {
		const value =
			activeOrder?.additionalFields?.transfer?.lastReceivedOrder;
		return value;
	}, [activeOrder]);

	const hasExecutor = useMemo(
		() => activeOrder?.executorToOrder?.length,
		[activeOrder?.executorToOrder],
	);

	const phones = useMemo(() => {
		const payload: string[] = [];

		if (
			!isOwn &&
			lastReceivedOrder?.phones?.length &&
			hasExecutor &&
			orderStatus === "executing"
		) {
			[lastReceivedOrder.phones]
				.sort((a, b) => a.group - b.group)
				.forEach((item) => payload.push(item?.number || ""));

			return payload;
		}
		return payload;
	}, [hasExecutor, isOwn, lastReceivedOrder?.phones, orderStatus]);

	const disabled = useMemo(() => {
		if (!activeOrder?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!activeOrder.passengers.length) return true;

		const hasCustomer = activeOrder.passengers.some(
			(passenger) => passenger.customer?.id,
		);

		return !hasCustomer;
	}, [sipToDispatcherId, activeOrder]);

	const onClick = useCallback(() => {
		if (sipToDispatcherId && isOwn) {
			SIP.callToCustomer({
				sipToDispatcherId,
				orderId: activeOrder.id,
			});
		}

		if (sipToDispatcherId && !isOwn && phones.length) {
			const phone = phones.at(0);
			if (phone) {
				SIP.callToPhone({
					sipToDispatcherId,
					phone,
				});
			}
		}
	}, [activeOrder.id, isOwn, phones, sipToDispatcherId]);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<Button
			variant="secondary"
			disabled={disabled}
			settingsKeyId="callOrderClient"
			title={
				t(
					"pages.mainPage.pages.orders.orderHeader.secondRow.callClientButton.str0",
				) ?? ""
			}
			tabIndex={-1}
			onClick={onClick}
		>
			<Icon id="orders-call-client" size={20} />
		</Button>
	);
};

declare namespace CallClientButton {
	interface Props {}
}

export default CallClientButton;
