import { TFunction } from "i18next";

// Defines the possible keys for tab options
type TabKey =
	| "main"
	| "user-select"
	| "executor-select"
	| "car-select"
	| "history"
	| "options"
	| "additional-data"
	| "cars"
	| "files"
	| "comments"
	| "orders"
	| "transactions"
	| "customers"
	| "schedules"
	| "passport"
	| "access"
	| "averageSpeed"
	| "permission-select"
	| "automaticFines"
	| "manualFines";

// Describes the structure of a tab option
interface TabOption {
	key: TabKey; // Unique identifier for the tab
	value: TabKey; // Value associated with the tab
	label: string; // Display label for the tab
}

/**
 * Retrieves tab options based on selected keys and the translation function.
 *
 * @param t - The translation function from i18next used for localizing tab labels.
 * @param selectedTabs - Array of keys representing the selected tabs.
 * @returns An array of `TabOption` objects for the selected tabs, including localized labels.
 *
 * @example
 * const { t } = useTranslation();
 *
 * const tabs = useMemo(() => {
 *     const selectedTabs: Parameters<typeof getTabsModeOne>[1] = [
 *         "main",
 *         "schedules",
 *         "executor-select",
 *         "history",
 *     ];
 *
 *     return getTabsModeOne(t, selectedTabs);
 * }, [t]);
 */
const getTabsModeOne = (
	t: TFunction, // Translation function from i18next
	selectedTabs: TabKey[], // Array of selected tab keys
): TabOption[] => {
	// Define all available tab options with localized labels
	const allTabs: Record<TabKey, TabOption> = {
		main: {
			key: "main",
			value: "main",
			label: t("utils.getTabsModeOne.main") ?? "Main", // Localized label
		},
		"user-select": {
			key: "user-select",
			value: "user-select",
			label: t("utils.getTabsModeOne.userSelect") ?? "Users",
		},
		"executor-select": {
			key: "executor-select",
			value: "executor-select",
			label: t("utils.getTabsModeOne.executorSelect") ?? "Executors",
		},
		"car-select": {
			key: "car-select",
			value: "car-select",
			label: t("utils.getTabsModeOne.carSelect") ?? "Transport",
		},
		history: {
			key: "history",
			value: "history",
			label: t("utils.getTabsModeOne.history") ?? "History",
		},
		options: {
			key: "options",
			value: "options",
			label: t("utils.getTabsModeOne.options") ?? "Options",
		},
		"additional-data": {
			key: "additional-data",
			value: "additional-data",
			label:
				t("utils.getTabsModeOne.additionalData") ?? "Additional Data",
		},
		cars: {
			key: "cars",
			value: "cars",
			label: t("utils.getTabsModeOne.cars") ?? "Transport",
		},
		files: {
			key: "files",
			value: "files",
			label: t("utils.getTabsModeOne.files") ?? "Files",
		},
		comments: {
			key: "comments",
			value: "comments",
			label: t("utils.getTabsModeOne.comments") ?? "Comments",
		},
		orders: {
			key: "orders",
			value: "orders",
			label: t("utils.getTabsModeOne.orders") ?? "Orders",
		},
		transactions: {
			key: "transactions",
			value: "transactions",
			label: t("utils.getTabsModeOne.transactions") ?? "Transactions",
		},
		customers: {
			key: "customers",
			value: "customers",
			label: t("utils.getTabsModeOne.customers") ?? "Customers",
		},
		schedules: {
			key: "schedules",
			value: "schedules",
			label: t("utils.getTabsModeOne.schedules") ?? "Work schedules",
		},
		passport: {
			key: "passport",
			value: "passport",
			label: t("utils.getTabsModeOne.passport") ?? "Passport",
		},
		access: {
			key: "access",
			value: "access",
			label: t("utils.getTabsModeOne.access") ?? "Access",
		},
		averageSpeed: {
			key: "averageSpeed",
			value: "averageSpeed",
			label: t("utils.getTabsModeOne.averageSpeed") ?? "Average speed",
		},
		"permission-select": {
			key: "permission-select",
			value: "permission-select",
			label: t("utils.getTabsModeOne.permissionSelect") ?? "Rights",
		},
		automaticFines: {
			key: "automaticFines",
			value: "automaticFines",
			label:
				t("utils.getTabsModeOne.automaticFines") ?? "Automatic fines",
		},
		manualFines: {
			key: "manualFines",
			value: "manualFines",
			label: t("utils.getTabsModeOne.manualFines") ?? "Manual fines",
		},
	};

	// Filter and map the selected tabs to their corresponding options
	return selectedTabs
		.filter((tabKey) => tabKey in allTabs) // Ensure only valid tab keys are included
		.map((tabKey) => allTabs[tabKey]); // Map tab keys to their options
};

export default getTabsModeOne;
