import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE } = BASE_WIDTH_COLUMNS;

const carClass = makeTable({
	allIds: [
		"active",
		"name",
		"company",
		"branch",
		"availableForOnlineOrdering",
	],
	i18tPath: (id) => `tableColumns.carClasses.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	defaultIds: [
		"active",
		"name",
		"company",
		"branch",
		"availableForOnlineOrdering",
	],
	initialWidths: {
		active: ACTIVE,
		name: 150,
		company: 400,
		branch: 400,
		availableForOnlineOrdering: 100,
	},
	initialResizable: {
		active: true,
		name: true,
		branch: true,
		company: true,
		availableForOnlineOrdering: true,
	},
	initialSortable: {
		active: true,
		name: true,
		branch: true,
		company: true,
		availableForOnlineOrdering: true,
	},
});

export default carClass;
