/* eslint-disable no-param-reassign */

import { createSlice } from "@reduxjs/toolkit";

import { OrderType } from "../../../../constants/OrdersPage";
import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	ordersType: "live", // * tab in head row from order page
};

const setOrdersType: ReduxReducer<State, OrderType> = (state, { payload }) => {
	state.ordersType = payload;
};

const orderTabsFilters = createSlice({
	name: Orders.ConstantOrdersSlice.TABS_FILTERS,
	initialState,
	reducers: {
		setOrdersType,
	},
});

export const orderTabsFiltersAction = orderTabsFilters.actions;
export const orderTabsFiltersReducer = orderTabsFilters.reducer;
export type orderTabsFiltersState = State;

export default orderTabsFilters;
