import { useMemo } from "react";
import { isUndefined, isNaN } from "lodash";

import Executor from "../../../services/Executor";
import { Orders } from "../../../redux/reducers/Orders";

const useFilteredExecutors = (
	executors: Executor.Model[],
	filters: any /* Orders.Chat.ModalFilter */,
	activeFilters: Partial<Record<keyof Orders.Chat.ModalFilter, boolean>> = {},
): Executor.Model[] =>
	useMemo(() => {
		const canNotShow = true;
		const canShowAndOnNextFilter = false;

		type typeIdOrNot = number | undefined;
		// type typeId = number | string;
		// type typeArrId = (number | string)[];
		type typeId = number | "all";
		type typeArrId = (number | "all")[];

		const isNotArr = (arr?: typeArrId) => !Array.isArray(arr);

		const isAll = (arr?: typeArrId) =>
			Array.isArray(arr) && arr.length === 1 && arr[0] === "all";

		const isEmptyArr = (arr?: typeArrId) =>
			Array.isArray(arr) && arr.length === 0;

		const isNumber = (id: typeIdOrNot) =>
			typeof id === "number" && !isNaN(id);

		const isCanSkipFilter = (arr?: typeArrId) =>
			isNotArr(arr) || isAll(arr) || isEmptyArr(arr);

		const toStr = (id: typeId) => id.toString();

		const filterByIds = (id: typeIdOrNot, filterIds: typeArrId) => {
			if (typeof id !== "number" || isNaN(id)) return canNotShow;
			return filterIds.some((filterId) => toStr(filterId) === toStr(id));
		};

		const checkCompanyId = (
			// Company
			onFilter: boolean,
			companyId: typeIdOrNot,
			companyIds: typeArrId,
		): boolean => {
			if (!onFilter || isCanSkipFilter(companyIds))
				return canShowAndOnNextFilter;

			const resultThisFilter = !filterByIds(companyId, companyIds || []);

			return resultThisFilter;
		};

		const checkTaxiServiceId = (
			// Fils
			onFilter: boolean,
			taxiServiceId: typeIdOrNot,
			taxiServiceIds: typeArrId,
		): boolean => {
			if (!onFilter || isCanSkipFilter(taxiServiceIds))
				return canShowAndOnNextFilter;

			const resultThisFilter = !filterByIds(
				taxiServiceId,
				taxiServiceIds || [],
			);

			return resultThisFilter;
		};

		const checkExecutorId = (
			// Executors
			onFilter: boolean,
			executorId: typeId,
			executorIds: typeArrId,
		): boolean => {
			if (!onFilter || isCanSkipFilter(executorIds))
				return canShowAndOnNextFilter;

			const resultThisFilter = !executorIds.includes(executorId);

			return resultThisFilter;
		};

		const checkExecutorGroupId = (
			// Vehicle fleets
			onFilter: boolean,
			groupId: typeIdOrNot,
			executorGroupIds: typeArrId,
		): boolean => {
			if (!onFilter || isCanSkipFilter(executorGroupIds))
				return canShowAndOnNextFilter;

			const resultThisFilter = !filterByIds(
				groupId,
				executorGroupIds || [],
			);

			return resultThisFilter;
		};

		const checkFleetId = (
			// Brigade
			onFilter: boolean,
			fleetId: typeIdOrNot,
			fleetIds: typeArrId,
		): boolean => {
			if (!onFilter || isCanSkipFilter(fleetIds))
				return canShowAndOnNextFilter;

			const resultThisFilter = !filterByIds(fleetId, fleetIds || []);

			return resultThisFilter;
		};

		const defaultActiveFilters = {
			companyIds: false,
			taxiServiceIds: false,
			executorIds: false,
			executorGroupIds: false,
			fleetIds: false,
		};

		const mergedActiveFilters = {
			...defaultActiveFilters,
			...activeFilters,
		};

		const {
			executorIds,
			taxiServiceIds,
			companyIds,
			executorGroupIds,
			fleetIds,
		} = filters;

		const retval = executors.filter((executor) => {
			const { id, taxiService, group, currentParking } = executor;
			const company = taxiService?.company;
			const parking = currentParking?.parking;

			if (
				checkCompanyId(
					mergedActiveFilters.companyIds,
					company?.id,
					companyIds,
				) ||
				checkTaxiServiceId(
					mergedActiveFilters.taxiServiceIds,
					taxiService?.id,
					taxiServiceIds,
				) ||
				checkExecutorId(
					mergedActiveFilters.executorIds,
					id,
					executorIds,
				) ||
				checkExecutorGroupId(
					mergedActiveFilters.executorGroupIds,
					group?.id,
					executorGroupIds,
				) ||
				checkFleetId(
					mergedActiveFilters.fleetIds,
					currentParking?.id,
					fleetIds,
				)
			) {
				return false;
			}

			// if (
			// 	(mergedActiveFilters.companyIds &&
			// 		checkCompanyId(company?.id, companyIds)) ||
			// 	(mergedActiveFilters.taxiServiceIds &&
			// 		checkTaxiServiceId(taxiService?.id, taxiServiceIds)) ||
			// 	(mergedActiveFilters.executorIds &&
			// 		checkExecutorId(id, executorIds)) ||
			// 	(mergedActiveFilters.executorGroupIds &&
			// 		checkExecutorGroupId(group?.id, executorGroupIds)) ||
			// 	(mergedActiveFilters.fleetIds &&
			// 		checkFleetId(parking?.id, fleetIds))
			// ) {
			// 	return false;
			// }

			return true;
		});

		// console.group("Console_Group_DP");
		// console.log(`executors`, executors);
		// console.log(`retval`, retval);

		// //  console.log(`activeKey`, activeTab);
		// console.table(mergedActiveFilters);
		// console.groupEnd();

		return retval;
	}, [activeFilters, executors, filters]);

export default useFilteredExecutors;
