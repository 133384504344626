import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Column, Row, theme } from "uikit";
import Button from "./components/Button";

const CallerStyled = styled(Row)`
	padding: 8px;

	display: flex;
	align-items: center;

	width: 290px;
	height: 48px;
	border-radius: 10px;
	background-color: ${theme.colors.white};
	box-shadow: 0px 4px 14px 0px #0000000d;
`;

const Avatar = styled("img")`
	width: 32px;
	height: 32px;
	border-radius: 50%;
`;

const MainText = styled("span")`
	color: ${theme.colors.primary};
	font-family: Lato;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: left;
`;
const SecondaryText = styled("span")`
	color: ${theme.colors.text_placeholder};
	font-family: Lato;
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0px;
	text-align: left;
`;

const Caller = () => {
	const { t } = useTranslation();
	return (
		<CallerStyled justify="space-around">
			<Row gaps="8px">
				<Avatar src="https://icdn.lenta.ru/images/2022/07/14/13/20220714133344014/square_320_2144f43b9da1fd38c196a1dfc0feffe5.jpg" />
				<Column>
					<MainText>
						{t("softphoneWidget.callers.caller.str100") ?? ""}
					</MainText>
					<SecondaryText>+3804545677867</SecondaryText>
				</Column>
			</Row>
			<Row gaps="6px">
				<Button iconName="call-start" color="#4CAF50" />
				<Button iconName="call-end" color="red" />
			</Row>
		</CallerStyled>
	);
};

export default Caller;
