/* eslint-disable no-param-reassign */

import * as ModelEvent from "@node-elion/syncron";
import { LatLngLiteral } from "leaflet";
import { cloneDeep, isBoolean } from "lodash";

import Map from "../../redux/services/Map";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import Subscription from "../../types/Subscription";
import { Company, Language, Base, CarClass } from "..";

const logger = createLogger({ name: "TaxiService2" });

class TaxiService extends Base {
	static fromResponse(data: any): TaxiService.Model {
		return {
			id: data.id,

			company: data.company
				? Company.fromResponse(data.company)
				: undefined,
			settlement: createObjectLanguageNamesFromObject(data.settlement),

			coordinates: data.settlementPoint,
			settings: cloneDeep(data.settings),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: TaxiService.Model.Modified): any {
		const payload: TaxiService.Model.Modified = {
			settings: model.settings,
		};

		if (
			isBoolean(model.settings?.sectors?.isBasicAverageSpeedEnabled) &&
			payload.settings
		) {
			payload.settings.sectors.additionalFields.isBasicAverageSpeedEnabled =
				model?.settings?.sectors?.additionalFields
					?.isBasicAverageSpeedEnabled || false;
		}

		return payload;
	}

	static async update(object: TaxiService.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.taxiService.update(
				object.id,
				TaxiService.toRequest(object),
			),
		);
	}

	public static async update2(
		object: TaxiService.Model.Modified,
	): Promise<boolean> {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.taxiService.update(
						object.id,
						TaxiService.toRequest(object),
					),
				{ silent: false, error: true },
			);
			logger.info("[TaxiService] update2", { object, res });
			if (res.error) throw res;
			return res;
		} catch (err: any) {
			logger.error("[TaxiService] Error update2:", err);
			return false;
		}
	}

	public static async subscribe(
		options: TaxiService.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<TaxiService.Model>,
	): Promise<Subscription<TaxiService.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[TaxiService] subscribe", { state, options });
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.taxiService.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error("[TaxiService] subscribe error", options);
						logger.error(error);
					},
				}),
			{ name: "TaxiService.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: TaxiService.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<TaxiService.SubscribeOptions>;
	}

	public static async getById(id: number): Promise<TaxiService.Model | null> {
		const titleError = "[TaxiService] Error getById:";
		try {
			const result = await this.request(
				(prpc) => prpc.theirsModel.taxiService.getById(id),
				{ silent: false, error: true },
			);

			if (result && result.error) {
				logger.error(titleError, result.data);
				return null;
			}

			return result;
		} catch (err: any) {
			logger.error(titleError, err);
			return null;
		}
	}

	public static async getAllTransfer(
		params: {
			order?: Record<string, "ASC" | "DESC">;
			query?: {
				taxiServiceId?: number;
			};
			limit?: number;
			offset?: number;
		} = {},
	): Promise<TaxiService.TransferModel[] | null> {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.order.orderTransfer.getAll(
						params,
					),
				{ silent: false, error: true },
			);

			logger.info("[TaxiService] getAllTransfer", {
				params,
				res,
			});

			return res?.items || [];
		} catch (err: any) {
			logger.error("[TaxiService] Error getAllTransfer:", err);
			return null;
		}
	}

	public static async patchTransfer(
		transferId: string,
		object: TaxiService.TransferUpdate,
	) {
		try {
			logger.info("[TaxiService] patchTransfer: object", object);

			const response = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.order.orderTransfer.update(
						transferId,
						object,
					),
				{ silent: false, error: true },
			);
			logger.info("[TaxiService] patchTransfer: response", response);
			return response;
		} catch (err: any) {
			logger.error("[TaxiService] Error patchTransfer:", err);
			return null;
		}
	}

	private static optionsToRequest(options: TaxiService.SubscribeOptions) {
		return {
			ids: options.ids,
			companyIds: options.companyIds,

			query: options.query,
			limit: options.limit,
			offset: options.offset,
			lang: options.language,
			terse: true,
			near: options.near,
		};
	}
}

declare namespace TaxiService {
	interface Model {
		id: number;
		additionalFields?: Record<string, any> & {
			isBasicAverageSpeedEnabled?: boolean;
		};
		company?: Company.Model;

		settlement: Record<Map.Language, string>;
		coordinates: LatLngLiteral;
		settings: Record<string, any>;

		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface SubscribeOptions {
		ids?: number[];
		companyIds?: number[];

		query?: string;
		limit?: number;
		offset?: number;
		language?: Language;
		terse?: boolean;
		near?: LatLngLiteral;
	}

	interface ObjectLiteral {
		[key: string]: any;
	}

	enum TransferType {
		SEND = "send",
		RECEIVE = "receive",
	}

	interface ExternalCarClass {
		id: number;
		name: ObjectLiteral;
	}

	interface ExternalService {
		id: number;
		name: ObjectLiteral;
	}

	interface ExternalTaxiServiceName {
		company: ObjectLiteral;
		settlement: ObjectLiteral;
		isEnabled: boolean;
	}

	interface ExternalTaxiService {
		pointer: string;
		canonicalName: ExternalTaxiServiceName;
		/**
		 * @description Additional fields for transfer (receive)
		 * */
		availableCarClasses?: ExternalCarClass[];
		/**
		 * @description Additional fields for transfer (receive)
		 * */
		availableServices?: ExternalService[];
		isEnabled: any;
	}

	interface TransferModel {
		id: string;
		type: TransferType;
		taxiService: Model & {
			globalPointer: string;
			taxiServiceToCarClasses?: TaxiServiceToCarClasses[];
		};
		externalTaxiService: ExternalTaxiService;
		enabled: boolean;
		/**
		 * @description Additional fields for transfer (receive)
		 */
		transferSettings?: OrderTransfer.AdditionalFields;
	}

	interface TaxiServiceToCarClasses {
		carClass: CarClass.Model;
		deletedAt: string | null;
		id: number;
	}

	interface TransferModelMerge extends TransferModel {
		merge: {
			send: TransferModel;
			receive: TransferModel;
		};
	}

	interface TransferModelMutate extends Omit<TransferModelMerge, "id"> {
		id: number;
		influenceId: string;
	}

	namespace OrderTransfer {
		export namespace AdditionalFields {
			export interface CarClassSync {
				originId: number | null; //* Our
				internalId: number | null; //* Compatible with
				indexMap?: number; //* only for transmutation update
			}

			export interface ServiceSync {
				originId: number | null; //* Our
				internalId: number | null; //* Compatible with
				indexMap?: number; //* only for transmutation update
			}
		}
		export interface AdditionalFields {
			carClasses: AdditionalFields.CarClassSync[];
			services: AdditionalFields.ServiceSync[];
		}
	}

	interface TransferUpdate {
		enabled: boolean;
		transferSettings: OrderTransfer.AdditionalFields;
	}

	namespace Model {
		type Modified = Partial<
			Omit<Model, "createdAt" | "updatedAt" | "deletedAt">
		>;
	}
}

export default TaxiService;
