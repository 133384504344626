import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import softphoneWidget, {
	TransferTabType,
} from "../../../../../../redux/reducers/softphoneWidget";

import Root from "./components/Root";
import NavButton from "./components/NavButton";

interface Props {
	navigation: TransferTabType;
	transferTab: TransferTabType;
}

const NavBar: React.FC<Props> = ({ navigation, transferTab }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const onSelectNavigation = useCallback(
		(type: TransferTabType) => {
			dispatch(softphoneWidget.actions.setTransferTab(type));
		},
		[dispatch],
	);

	useEffect(() => {
		const navBar = document.querySelector('[itemId="transferNav"]');

		const horizontalScroll = (event) => {
			if (event.deltaY !== 0) {
				event.preventDefault();
				if (navBar) {
					navBar.scrollLeft += event.deltaY / 5;
				}
			}
		};

		if (navBar) {
			navBar.addEventListener("wheel", horizontalScroll);
		}

		return () => {
			navBar?.removeEventListener("wheel", horizontalScroll);
		};
	}, []);

	useEffect(() => {
		const navBar = document.querySelector('[itemId="transferNav"]');

		if (navBar) {
			switch (transferTab) {
				case "dispatchers": {
					navBar.scrollLeft = -100;
					break;
				}
				case "executors": {
					navBar.scrollLeft = 0;
					break;
				}
				case "anyNumber": {
					navBar.scrollLeft = 100;
					break;
				}
				default: {
					break;
				}
			}
		}
	}, [transferTab]);

	return (
		<Root gaps="24px*" itemID="transferNav">
			<NavButton
				active={navigation === "dispatchers"}
				text={t("softphoneWidget.transfer.navBar.str0") ?? ""}
				onChangeNavigation={onSelectNavigation}
				type="dispatchers"
			/>
			<NavButton
				active={navigation === "executors"}
				text={t("softphoneWidget.transfer.navBar.str1") ?? ""}
				onChangeNavigation={onSelectNavigation}
				type="executors"
			/>
			<NavButton
				active={navigation === "anyNumber"}
				text={t("softphoneWidget.transfer.navBar.str2") ?? ""}
				onChangeNavigation={onSelectNavigation}
				type="anyNumber"
			/>
		</Root>
	);
};

export default NavBar;
