import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const accountsReport = makeTable({
	allIds: [
		"date",
		"operationType",
		"source",
		"order",
		"operationDescription",
		"user",
		"amountUah",
		"balanceUah",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.accountsReport.columns.${id}`,
	defaultIds: [
		"date",
		"operationType",
		"source",
		"order",
		"operationDescription",
		"user",
		"amountUah",
		"balanceUah",
	],
	initialSortMethod: {
		column: "order",
		type: "asc",
	},
	initialWidths: {
		date: DATE,
		operationType: 300,
		source: 300,
		order: 150,
		operationDescription: 300,
		user: 150,
		amountUah: 150,
		balanceUah: 150,
	},
	initialResizable: {
		date: true,
		operationType: true,
		source: true,
		order: true,
		operationDescription: true,
		user: true,
		amountUah: true,
		balanceUah: true,
	},
	initialSortable: {
		date: true,
		operationType: true,
		source: true,
		order: true,
		operationDescription: true,
		user: true,
		amountUah: true,
		balanceUah: true,
	},
});

export default accountsReport;
