import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE, ACTIVE } = BASE_WIDTH_COLUMNS;

const accountsWorkShifts = makeTable({
	allIds: ["active", "name", "openAndCloseType", "taxiServices", "createdAt"],
	i18tPath: (id) => `mainPage.accounts.schedules.columns.${id}`,
	defaultIds: [
		"active",
		"name",
		"openAndCloseType",
		"taxiServices",
		"createdAt",
	],
	initialSortMethod: {
		column: "createdAt",
		type: "asc",
	},
	initialWidths: {
		active: ACTIVE,
		name: 624,
		openAndCloseType: 270,
		taxiServices: 347,
		createdAt: DATE,
	},
	initialResizable: {
		active: true,
		name: true,
		openAndCloseType: true,
		taxiServices: true,
		createdAt: true,
	},
	initialSortable: {
		active: true,
		name: true,
		openAndCloseType: true,
		taxiServices: true,
		createdAt: true,
	},
});

export default accountsWorkShifts;
