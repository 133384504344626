import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE, COMPANIES } = BASE_WIDTH_COLUMNS;

const settingsMessageProvider = makeTable({
	allIds: [
		"active",
		"name",
		"alphaName",
		"status",
		"companies",
		"taxiServices",
	],
	defaultIds: [
		"active",
		"name",
		"alphaName",
		"status",
		"companies",
		"taxiServices",
	],
	i18tPath: (id) => `settings.messages.providerSms.columns.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	initialWidths: {
		active: ACTIVE,
		name: 200,
		alphaName: 160,
		status: 140,
		companies: COMPANIES,
		taxiServices: 630,
	},
	initialResizable: {
		active: true,
		name: true,
		alphaName: true,
		status: true,
		companies: true,
		taxiServices: true,
	},
	initialSortable: {
		active: true,
		name: true,
		alphaName: true,
		status: true,
		companies: true,
		taxiServices: true,
	},
});

export default settingsMessageProvider;
