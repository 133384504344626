import React, { MouseEvent, ReactNode, memo, useCallback } from "react";
import { Icon, theme } from "uikit";

import Root from "./components/Root";
import Title from "./components/Title";

const HeaderDraggable = memo<HeaderDraggable.Props>(
	({ draggable, title, onClose }) => {
		const rootOnMouseDown = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				event.preventDefault();
			},
			[],
		);

		return (
			<Root
				draggable={draggable}
				className="draggable-area"
				sizes="1fr auto!"
				maxedWidth
				onMouseDown={rootOnMouseDown}
			>
				<Title>{title}</Title>
				<div style={{ cursor: "pointer" }} onClick={onClose}>
					<Icon
						id="close"
						size={11}
						colors={[theme.colors.secondary]}
					/>
				</div>
			</Root>
		);
	},
);

declare namespace HeaderDraggable {
	interface Props {
		draggable: boolean;
		title?: ReactNode;
		action?: ReactNode;

		onClose: () => void;
	}
}

export default HeaderDraggable;
