import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../redux/store";
import useKeyBind from "../../../../../hooks/useKeyBind";
import { KEYBOARD_CODES } from "../../../../../constants/business";
import { useChatActions } from "../../hooks";

import { Root, Header, MessageType } from "./components";

const MessageTypeModal: React.FC<MessageTypeModal.Props> = ({ onClose }) => {
	const { t } = useTranslation();
	const {
		selectDefaultMessage,
		selectAnnouncementMessage,
		selectWarningMessage,
		selectSosMessage,
	} = useChatActions(onClose);

	const { booferSelectedMessageType } = useTypedSelector(
		(state) => state.orders.chats.chat,
	);
	const [tabIndex, setTabIndex] = useState<number>(-1);

	useKeyBind([KEYBOARD_CODES.TAB], () => {
		setTabIndex((prev) => (prev === 3 ? 0 : prev + 1));
	});

	useKeyBind([KEYBOARD_CODES.SHIFT, KEYBOARD_CODES.TAB], () => {
		setTabIndex((prev) => (prev === 0 ? 3 : prev - 1));
	});

	useKeyBind([KEYBOARD_CODES.ESCAPE], onClose);

	useKeyBind([KEYBOARD_CODES.ENTER], () => {
		(
			({
				0: selectDefaultMessage,
				1: selectAnnouncementMessage,
				2: selectWarningMessage,
				3: selectSosMessage,
			})[tabIndex] || onClose
		)();
	});

	useKeyBind([KEYBOARD_CODES.ARROW_LEFT], () => {});
	useKeyBind([KEYBOARD_CODES.ARROW_RIGHT], () => {});

	return (
		<Root>
			<Header onClick={onClose} />
			<MessageType
				text={t("orderPageWidgets.chat.messageTypeModal.str0") ?? ""}
				type="default"
				selected={booferSelectedMessageType === "default"}
				onSelect={selectDefaultMessage}
				tabSelected={tabIndex === 0}
			/>
			<MessageType
				text={t("orderPageWidgets.chat.messageTypeModal.str1") ?? ""}
				type="announcement"
				selected={booferSelectedMessageType === "announcement"}
				onSelect={selectAnnouncementMessage}
				tabSelected={tabIndex === 1}
			/>
			<MessageType
				text={t("orderPageWidgets.chat.messageTypeModal.str2") ?? ""}
				type="warning"
				selected={booferSelectedMessageType === "warning"}
				onSelect={selectWarningMessage}
				tabSelected={tabIndex === 2}
			/>
			<MessageType
				text="SOS"
				type="sos"
				selected={booferSelectedMessageType === "sos"}
				onSelect={selectSosMessage}
				tabSelected={tabIndex === 3}
			/>
		</Root>
	);
};

declare namespace MessageTypeModal {
	interface Props {
		onClose: () => void;
	}
}

export default MessageTypeModal;
