import { Action, combineReducers } from "@reduxjs/toolkit";

import {
	executors,
	accountsExecutorsAction,
	StateExecutors,
} from "./Executors";
import { Accounts } from "./interface";

export { Accounts };

export type State = {
	executors: StateExecutors;
};

export type Actions = {
	executors: typeof accountsExecutorsAction;
};

export const accountsReducer = combineReducers<State, Action<Actions>>({
	executors,
});

export const accountsAction: Actions = {
	executors: accountsExecutorsAction,
};

export default accountsReducer;
