import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { ACTIVE, DATE } = BASE_WIDTH_COLUMNS;

const preferencesObjectFirstTable = makeTable({
	allIds: [
		"active",
		"name",
		"city",
		"street",
		"house",
		"coordinates",
		"company",
		"branch",
		"createdAt",
		"properties",
	],
	i18tPath: (id) => `mainPage.preferences.objects.columns.${id}`,
	initialSortMethod: {
		column: "active",
		type: "asc",
	},
	defaultIds: [
		"active",
		"name",
		"city",
		"street",
		"house",
		"coordinates",
		"company",
		"branch",
		"createdAt",
		"properties",
	],
	initialWidths: {
		active: ACTIVE,
		name: 225,
		city: 155,
		street: 170,
		house: 130,
		coordinates: 150,
		company: 150,
		branch: 150,
		createdAt: DATE,
		properties: 115,
	},
	initialResizable: {
		active: true,
		name: true,
		city: true,
		street: true,
		house: true,
		coordinates: true,
		company: true,
		branch: true,
		createdAt: true,
		properties: true,
	},
	initialSortable: {
		active: true,
		name: true,
		city: true,
		street: true,
		house: true,
		coordinates: true,
		company: true,
		branch: true,
		createdAt: true,
		properties: true,
	},
});

export default preferencesObjectFirstTable;
