import { TFunction } from "i18next";

const getTransactionDescription = (t: TFunction, _text?: string): string => {
	if (typeof _text !== "string") return "";

	const descriptions: Record<string, string> = {
		discount_executor_compensation: t(
			"transactionDescription.discountExecutorCompensation",
		),
		"Refund customer bonuses back": t(
			"transactionDescription.refundCustomerBonuses",
		),
		"Hold customer bonuses": t(
			"transactionDescription.holdCustomerBonuses",
		),
		"Counterparty payment": t("transactionDescription.counterpartyPayment"),
		"Refund counterparty payment": t(
			"transactionDescription.refundCounterpartyPayment",
		),
		"Compensation to the agent for order": t(
			"transactionDescription.agentOrderCompensation",
		),
		"Refund agent payment": t("transactionDescription.refundAgentPayment"),
	};

	return descriptions[_text] || _text || "";
};

export default getTransactionDescription;
