import makeTable from "./makeTable";
import BASE_WIDTH_COLUMNS from "./constants/baseWidthColumns";

const { DATE } = BASE_WIDTH_COLUMNS;

const cashlessOrdersGeneral = makeTable({
	allIds: [
		"name",
		"card",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"licensePlate",
		"model",
		"color",
		"distanceKm",
		"executionTimeMin",
		"idleTimeMin",
		"amountUah",
		"status",
	],
	i18tPath: (id) =>
		`mainPage.reports.accounting.cashlessOrders.columns.${id}`,
	defaultIds: [
		"name",
		"card",
		"passenger",
		"from",
		"to",
		"note",
		"orderNote",
		"created",
		"when",
		"completed",
		"callSign",
		"licensePlate",
		"model",
		"color",
		"distanceKm",
		"executionTimeMin",
		"idleTimeMin",
		"amountUah",
		"status",
	],
	initialSortMethod: {
		column: "created",
		type: "asc",
	},
	initialWidths: {
		name: 120,
		card: 80,
		passenger: 90,
		from: 220,
		to: 220,
		note: 130,
		orderNote: 150,
		created: DATE,
		when: DATE,
		completed: DATE,
		callSign: 80,
		licensePlate: 90,
		model: 110,
		color: 110,
		distanceKm: 130,
		executionTimeMin: 180,
		idleTimeMin: 100,
		amountUah: 80,
		status: 80,
	},
	initialResizable: {
		name: true,
		card: true,
		passenger: true,
		from: true,
		to: true,
		note: true,
		orderNote: true,
		created: true,
		when: true,
		completed: true,
		callSign: true,
		licensePlate: true,
		model: true,
		color: true,
		distanceKm: true,
		executionTimeMin: true,
		idleTimeMin: true,
		amountUah: true,
		status: true,
	},
	initialSortable: {
		name: true,
		card: false,
		passenger: false,
		from: false,
		to: false,
		note: false,
		orderNote: false,
		created: false,
		when: false,
		completed: false,
		callSign: false,
		licensePlate: false,
		model: false,
		color: false,
		distanceKm: false,
		executionTimeMin: false,
		idleTimeMin: false,
		amountUah: false,
		status: false,
	},
});

export default cashlessOrdersGeneral;
