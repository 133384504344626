/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import {
	KeybindEntry,
	DataKind,
	EntryList,
	EntryGroup,
} from "../../../types/settingEntries";

export interface BaseKeybindEntries<Data extends DataKind> {
	// Order state filters
	liveTab: KeybindEntry<Data>;
	preliminaryTab: KeybindEntry<Data>;
	executingTab: KeybindEntry<Data>;
	allTab: KeybindEntry<Data>;
	closedTab: KeybindEntry<Data>;

	searchCar: KeybindEntry<Data>;
	searchExecutor: KeybindEntry<Data>;
	searchOrder: KeybindEntry<Data>;

	// Order main page
	newOrder: KeybindEntry<Data>;
	editOrder: KeybindEntry<Data>;
	closeOrder: KeybindEntry<Data>;
	copyOrder: KeybindEntry<Data>;
	setOrderExecutor: KeybindEntry<Data>;
	sendOrderAgain: KeybindEntry<Data>;
	recalculateOrder: KeybindEntry<Data>;
	recalculateOrderWithActualRates: KeybindEntry<Data>;
	returnOrderOnAirOrRemoveExecutor: KeybindEntry<Data>;
	toggleOrderAir: KeybindEntry<Data>;
	setOrderCallToClientBeingRequired: KeybindEntry<Data>;
	setOrderCallToClientSuccess: KeybindEntry<Data>;
	setOrderCallToExecutorBeingRequired: KeybindEntry<Data>;
	setOrderCallToExecutorSuccess: KeybindEntry<Data>;
	callOrderClient: KeybindEntry<Data>;
	callOrderExecutor: KeybindEntry<Data>;

	// Order modal
	orderModalNewOrder: KeybindEntry<Data>;
	orderModalRecalculateOrder: KeybindEntry<Data>;

	// Order modal -> inside point
	orderModalAddPoint: KeybindEntry<Data>;
	orderModalInsertPoint: KeybindEntry<Data>;
	orderModalEditPoint: KeybindEntry<Data>;
	orderModalDeletePoint: KeybindEntry<Data>;

	orderModalAddPointRow: KeybindEntry<Data>;

	orderModalMovePointUp: KeybindEntry<Data>;
	orderModalMovePointDown: KeybindEntry<Data>;
	orderModalFocusPointUp: KeybindEntry<Data>;
	orderModalFocusPointDown: KeybindEntry<Data>;
	orderModalSwapFirstPointForLastPoint: KeybindEntry<Data>;

	// Order modal -> inside inputs
	orderModalFocusLoyaltyCardInput: KeybindEntry<Data>;
	orderModalFocusCardInput: KeybindEntry<Data>;
	orderModalFocusDriverNodeInput: KeybindEntry<Data>;
	orderModalFocusNodeInput: KeybindEntry<Data>;
	orderModalSwapPhones: KeybindEntry<Data>;

	// Order modal -> inside modals
	orderModalScheduledModal: KeybindEntry<Data>;
	orderModalServicesModal: KeybindEntry<Data>;
	orderModalTaxiClassModal: KeybindEntry<Data>;

	// Order modal -> inside selects
	orderModalSelectPayment: KeybindEntry<Data>;

	// Order modal -> inside buttons to footer
	orderModalCloseOrder: KeybindEntry<Data>;
	orderModalCopyOrder: KeybindEntry<Data>;

	orderModalCallOrderClient: KeybindEntry<Data>;
	orderModalCallOrderExecutor: KeybindEntry<Data>;
	orderModalShowPhoneToExecutor: KeybindEntry<Data>;

	orderModalSearchFilterStreet: KeybindEntry<Data>;
	orderModalSearchFilterObjects: KeybindEntry<Data>;
	orderModalSearchFilterLocalObjects: KeybindEntry<Data>;

	orderModalCancelOrder: KeybindEntry<Data>;
	orderModalEditOrder: KeybindEntry<Data>;
	// Order colors
	color1: KeybindEntry<Data>;
	color2: KeybindEntry<Data>;
	color3: KeybindEntry<Data>;
	color4: KeybindEntry<Data>;
	color5: KeybindEntry<Data>;
	color6: KeybindEntry<Data>;
	color7: KeybindEntry<Data>;
	color8: KeybindEntry<Data>;

	// Points
	addPoint: KeybindEntry<Data>;
	insertPoint: KeybindEntry<Data>;
	editPoint: KeybindEntry<Data>;
	deletePoint: KeybindEntry<Data>;
	movePointUp: KeybindEntry<Data>;
	movePointDown: KeybindEntry<Data>;

	// Search filter
	searchFilterStreet: KeybindEntry<Data>;
	searchFilterObjects: KeybindEntry<Data>;
	searchFilterLocalObjects: KeybindEntry<Data>;

	// Softphone
	openSoftphone: KeybindEntry<Data>;
	callUpSoftphone: KeybindEntry<Data>;
	callDownSoftphone: KeybindEntry<Data>;

	// Chat Widgets
	sendMessageInChatWidget: KeybindEntry<Data>;
}

export type KeybindEntries<Data extends DataKind> = BaseKeybindEntries<Data> &
	EntryList<Data, never, never>;
export type KeybindMeta = KeybindEntries<"meta">;
export type KeybindState = KeybindEntries<"value">;
export type KeybindValue = KeybindEntries<any>[keyof KeybindEntries<any>];

export const keybindMeta: KeybindMeta = {
	// Order state filters
	liveTab: { type: "keybind" },
	preliminaryTab: { type: "keybind" },
	executingTab: { type: "keybind" },
	allTab: { type: "keybind" },
	closedTab: { type: "keybind" },

	searchCar: { type: "keybind" },
	searchExecutor: { type: "keybind" },
	searchOrder: { type: "keybind" },

	// Order main page
	newOrder: { type: "keybind" },
	editOrder: { type: "keybind" },
	closeOrder: { type: "keybind" },
	copyOrder: { type: "keybind" },
	setOrderExecutor: { type: "keybind" },
	sendOrderAgain: { type: "keybind" },
	recalculateOrder: { type: "keybind" },
	recalculateOrderWithActualRates: { type: "keybind" },
	returnOrderOnAirOrRemoveExecutor: { type: "keybind" },
	toggleOrderAir: { type: "keybind" },
	setOrderCallToClientBeingRequired: { type: "keybind" },
	setOrderCallToClientSuccess: { type: "keybind" },
	setOrderCallToExecutorBeingRequired: { type: "keybind" },
	setOrderCallToExecutorSuccess: { type: "keybind" },
	callOrderClient: { type: "keybind" },
	callOrderExecutor: { type: "keybind" },

	// Order modal
	orderModalNewOrder: { type: "keybind" },
	orderModalRecalculateOrder: { type: "keybind" },

	// Order modal -> inside point
	orderModalAddPoint: { type: "keybind" },
	orderModalInsertPoint: { type: "keybind" },
	orderModalEditPoint: { type: "keybind" },
	orderModalDeletePoint: { type: "keybind" },
	orderModalAddPointRow: { type: "keybind" },

	orderModalMovePointUp: { type: "keybind" },
	orderModalMovePointDown: { type: "keybind" },
	orderModalFocusPointUp: { type: "keybind" },
	orderModalFocusPointDown: { type: "keybind" },
	orderModalSwapFirstPointForLastPoint: { type: "keybind" },

	// Order modal -> inside inputs
	orderModalFocusLoyaltyCardInput: { type: "keybind" },
	orderModalFocusCardInput: { type: "keybind" },
	orderModalFocusDriverNodeInput: { type: "keybind" },
	orderModalFocusNodeInput: { type: "keybind" },
	orderModalSwapPhones: { type: "keybind" },

	// Order modal -> inside modals
	orderModalScheduledModal: { type: "keybind" },
	orderModalServicesModal: { type: "keybind" },
	orderModalTaxiClassModal: { type: "keybind" },

	// Order modal -> inside selects
	orderModalSelectPayment: { type: "keybind" },

	// Order modal -> inside buttons to footer
	orderModalCloseOrder: { type: "keybind" },
	orderModalCopyOrder: { type: "keybind" },

	orderModalCallOrderClient: { type: "keybind" },
	orderModalCallOrderExecutor: { type: "keybind" },
	orderModalShowPhoneToExecutor: { type: "keybind" },

	orderModalSearchFilterStreet: { type: "keybind" },
	orderModalSearchFilterObjects: { type: "keybind" },
	orderModalSearchFilterLocalObjects: { type: "keybind" },

	orderModalCancelOrder: { type: "keybind" },
	orderModalEditOrder: { type: "keybind" },

	// Order colors
	color1: { type: "keybind" },
	color2: { type: "keybind" },
	color3: { type: "keybind" },
	color4: { type: "keybind" },
	color5: { type: "keybind" },
	color6: { type: "keybind" },
	color7: { type: "keybind" },
	color8: { type: "keybind" },
	color9: { type: "keybind" },
	color10: { type: "keybind" },

	// Points
	addPoint: { type: "keybind" },
	insertPoint: { type: "keybind" },
	editPoint: { type: "keybind" },
	deletePoint: { type: "keybind" },
	movePointUp: { type: "keybind" },
	movePointDown: { type: "keybind" },

	// Search filter
	searchFilterStreet: { type: "keybind" },
	searchFilterObjects: { type: "keybind" },
	searchFilterLocalObjects: { type: "keybind" },

	// Softphone
	openSoftphone: { type: "keybind" },
	callUpSoftphone: { type: "keybind" },
	callDownSoftphone: { type: "keybind" },

	// Chat Widgets
	sendMessageInChatWidget: { type: "keybind" },
};

export const keybindGroups: EntryGroup<
	BaseKeybindEntries<any>,
	"settings.interface.keybinds.groups."
>[] = [
	{
		title: [
			"settings.interface.keybinds.groups.orderPage",
			"settings.interface.keybinds.groups.orderStateFilter",
		],
		entryKeys: [
			"liveTab",
			"preliminaryTab",
			"executingTab",
			"allTab",
			"closedTab",

			"searchCar",
			"searchExecutor",
			"searchOrder",
		],
	},
	{
		title: [
			"settings.interface.keybinds.groups.orderPage",
			"settings.interface.keybinds.groups.orderDetails",
		],
		entryKeys: [
			"addPoint",
			"insertPoint",
			"editPoint",
			"deletePoint",
			"movePointUp",
			"movePointDown",
		],
	},
	{
		title: ["settings.interface.keybinds.groups.orderPage"],
		entryKeys: [
			"newOrder",
			"editOrder",
			"closeOrder",
			"copyOrder",
			"setOrderExecutor",
			"sendOrderAgain",
			"recalculateOrder",
			"recalculateOrderWithActualRates",
			"returnOrderOnAirOrRemoveExecutor",
			"toggleOrderAir",
			"setOrderCallToClientBeingRequired",
			"setOrderCallToClientSuccess",
			"setOrderCallToExecutorBeingRequired",
			"setOrderCallToExecutorSuccess",
			"callOrderClient",
			"callOrderExecutor",
		],
	},
	{
		title: [
			"settings.interface.keybinds.groups.orderPage",
			"settings.interface.keybinds.groups.orderColors",
		],
		entryKeys: [
			"color1",
			"color2",
			"color3",
			"color4",
			"color5",
			"color6",
			"color7",
			"color8",
		],
	},
	{
		title: [
			"settings.interface.keybinds.groups.orderPage",
			"settings.interface.keybinds.groups.orderModal",
		],
		entryKeys: [
			"orderModalNewOrder",
			"orderModalRecalculateOrder",
			"orderModalSwapPhones",
			"orderModalFocusLoyaltyCardInput",
			"orderModalFocusCardInput",
			"orderModalFocusNodeInput",
			"orderModalFocusDriverNodeInput",
			"orderModalAddPoint",
			"orderModalInsertPoint",
			"orderModalEditPoint",
			"orderModalDeletePoint",
			"orderModalAddPointRow",
			"orderModalMovePointUp",
			"orderModalMovePointDown",
			"orderModalFocusPointUp",
			"orderModalFocusPointDown",
			"orderModalSwapFirstPointForLastPoint",
			"orderModalScheduledModal",
			"orderModalSelectPayment",
			"orderModalTaxiClassModal",
			"orderModalServicesModal",
			"orderModalCloseOrder",
			"orderModalCopyOrder",
			"orderModalCallOrderClient",
			"orderModalCallOrderExecutor",
			"orderModalShowPhoneToExecutor",
			"orderModalSearchFilterStreet",
			"orderModalSearchFilterLocalObjects",
			"orderModalSearchFilterObjects",
			"orderModalCancelOrder",
			"orderModalEditOrder",
		],
	},
	{
		title: [
			"settings.interface.keybinds.groups.orderPage",
			"settings.interface.keybinds.groups.orderModal",
			"settings.interface.keybinds.groups.pointModal",
		],
		entryKeys: [
			"searchFilterStreet",
			"searchFilterObjects",
			"searchFilterLocalObjects",
		],
	},
	{
		title: ["settings.interface.keybinds.groups.softphone"],
		entryKeys: ["openSoftphone", "callUpSoftphone", "callDownSoftphone"],
	},
	{
		title: ["settings.interface.keybinds.groups.chatWidgets"],
		entryKeys: ["sendMessageInChatWidget"],
	},
];

const initialState: KeybindState = {
	// Order state filters
	liveTab: ["F1"],
	preliminaryTab: ["F2"],
	executingTab: ["F3"],
	allTab: ["F4"],
	closedTab: ["Shift", "F4"],

	searchCar: ["Control", "F10"],
	searchExecutor: ["F10"],
	searchOrder: ["Shift", "F10"],

	// Order main page
	newOrder: ["F5"],
	editOrder: ["F9"],
	closeOrder: ["Delete"],
	copyOrder: ["F6"],
	setOrderExecutor: ["F11"],
	sendOrderAgain: ["F8"],
	recalculateOrder: ["KeyR"],
	recalculateOrderWithActualRates: ["Control", "KeyR"],
	returnOrderOnAirOrRemoveExecutor: ["KeyO"],
	toggleOrderAir: ["Space"],
	setOrderCallToClientBeingRequired: ["Shift", "End"],
	setOrderCallToClientSuccess: ["Shift", "Home"],
	setOrderCallToExecutorBeingRequired: ["Control", "End"],
	setOrderCallToExecutorSuccess: ["Control", "End"],
	callOrderClient: ["Home"],
	callOrderExecutor: ["End"],

	// Order modal
	orderModalNewOrder: ["Control", "Shift", "Tab"],
	orderModalRecalculateOrder: ["Control", "KeyR"],

	// Order modal -> inside point
	orderModalAddPoint: ["Control", "Insert"],
	orderModalInsertPoint: ["Shift", "Insert"],
	orderModalEditPoint: ["Shift", "F9"],
	orderModalDeletePoint: ["Shift", "Delete"],
	orderModalAddPointRow: ["Insert"],
	orderModalMovePointUp: ["Control", "ArrowUp"],
	orderModalMovePointDown: ["Control", "ArrowDown"],
	orderModalFocusPointUp: ["Shift", "ArrowUp"],
	orderModalFocusPointDown: ["Shift", "ArrowDown"],
	orderModalSwapFirstPointForLastPoint: ["Control", "KeyA"],

	// Order modal -> inside inputs
	orderModalFocusLoyaltyCardInput: ["F2"],
	orderModalFocusCardInput: ["F3"],
	orderModalFocusDriverNodeInput: ["Shift", "F5"],
	orderModalFocusNodeInput: ["Control", "F5"],
	orderModalSwapPhones: ["Control", "KeyQ"],

	// Order modal -> inside modals
	orderModalScheduledModal: ["F4"],
	orderModalServicesModal: ["Control", "F6"],
	orderModalTaxiClassModal: ["F7"],

	// Order modal -> inside selects
	orderModalSelectPayment: ["F5"],

	// Order modal -> inside buttons to footer
	orderModalCloseOrder: ["Delete"],
	orderModalCopyOrder: ["F6"],
	orderModalCallOrderClient: ["Home"],
	orderModalCallOrderExecutor: ["End"],
	orderModalShowPhoneToExecutor: ["Control", "KeyL"],
	orderModalSearchFilterStreet: ["Control", "F1"],
	orderModalSearchFilterLocalObjects: ["Control", "F2"],
	orderModalSearchFilterObjects: ["Control", "F3"],
	orderModalCancelOrder: ["Escape"],
	orderModalEditOrder: ["F9"],

	// Order colors
	color1: ["Control", "Digit1"],
	color2: ["Control", "Digit2"],
	color3: ["Control", "Digit3"],
	color4: ["Control", "Digit4"],
	color5: ["Control", "Digit5"],
	color6: ["Control", "Digit6"],
	color7: ["Control", "Digit7"],
	color8: ["Control", "Digit8"],

	// Points
	addPoint: ["Insert"],
	insertPoint: ["Shift", "Insert"],
	editPoint: ["Shift", "F9"],
	deletePoint: ["Shift", "Delete"],
	movePointUp: ["Control", "ArrowUp"],
	movePointDown: ["Control", "ArrowDown"],
	// Search filter
	searchFilterStreet: ["F1"],
	searchFilterObjects: ["F2"],
	searchFilterLocalObjects: ["F3"],

	// Softphone
	openSoftphone: ["Control", "KeyS"],
	callUpSoftphone: ["PageUp"],
	callDownSoftphone: ["PageDown"],

	// Chat Widgets
	sendMessageInChatWidget: ["Enter"],
};

type Reducer<P> = CaseReducer<KeybindState, PayloadAction<P>>;

const setAll: Reducer<KeybindState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const keybinds = createSlice({
	name: "keybinds",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default keybinds;
