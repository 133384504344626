import React, { Dispatch } from "react";

import Header from "./components/Header";
import Root from "./components/Root";
import Footer from "./components/Footer";
import Form from "./components/Form";

const Content: React.FC<Content.Props> = ({
	value,
	onChange,
	onReset,
	onClose,
	onApply,
	setShowModalPropsForExecutorGroup,
	setShowModalPropsForCarPark,
}) => (
	<Root align="stretch" gaps="16px 20px">
		<Header onClose={onClose} />
		<Form
			value={value}
			onChange={onChange}
			setShowModalPropsForExecutorGroup={
				setShowModalPropsForExecutorGroup
			}
			setShowModalPropsForCarPark={setShowModalPropsForCarPark}
		/>
		<Footer onReset={onReset} onCancel={onClose} onApply={onApply} />
	</Root>
);

declare namespace Content {
	type Value = Form.Value;

	interface Props {
		value: Value;

		onReset: () => void;
		onClose: () => void;
		onApply: () => void;

		onChange: Dispatch<Value>;
		setShowModalPropsForExecutorGroup: (value: boolean) => void;
		setShowModalPropsForCarPark: (value: boolean) => void;
	}
}

export default Content;
