import { combineReducers } from "@reduxjs/toolkit";

import {
	orderSoftPhoneCallsState,
	orderSoftPhoneCallsReducer,
	orderSoftPhoneCallsAction,
} from "./calls";

export type StateSoftPhone = {
	calls: orderSoftPhoneCallsState;
};

export const softPhone = combineReducers<StateSoftPhone>({
	calls: orderSoftPhoneCallsReducer,
});

export const orderSoftPhoneAction = {
	calls: orderSoftPhoneCallsAction,
};

export default softPhone;
