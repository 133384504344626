/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { ExecutorRate as ExecutorRateService } from "../../../../../services";
import { Accounts } from "../../interface";

import { State } from "./interface";

const initialState: State = {
	executorRate: [],
};

const setExecutorRateData: ReduxReducer<State, ExecutorRateService.Model[]> = (
	state,
	action,
) => {
	state.executorRate = action.payload;
};

const accountsExecutorsData = createSlice({
	name: Accounts.ConstantOrdersSlice.EXECUTORS_DATA,
	initialState,
	reducers: {
		setExecutorRateData,
	},
});

export const accountsExecutorsDataAction = accountsExecutorsData.actions;
export const accountsExecutorsDataReducer = accountsExecutorsData.reducer;
export type accountsExecutorsDataState = State;

export default accountsExecutorsData;
