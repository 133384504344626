import makeTable from "./makeTable";

const settingsFinancesExecutorPenaltiesModalManualFines = makeTable({
	allIds: ["name", "balance", "bonus"],
	i18tPath: (id) => `settings.finances.executorPenalties.columns.${id}`,
	defaultIds: ["name", "balance", "bonus"],
	initialSortMethod: {
		column: "name",
		type: "asc",
	},
	initialWidths: {
		name: 300,
		balance: 300,
		bonus: 400,
	},
	initialResizable: {
		name: true,
		balance: true,
		bonus: true,
	},
	initialSortable: {
		name: true,
		balance: true,
		bonus: true,
	},
});

export default settingsFinancesExecutorPenaltiesModalManualFines;
