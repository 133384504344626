/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
import React, {
	useState,
	useMemo,
	useEffect,
	ReactNode,
	useCallback,
	useLayoutEffect,
} from "react";
import { react } from "uikit";
import {
	Polygon,
	Tooltip,
	Polyline,
	useMapEvent,
	useMapEvents,
	useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import MarkerBase from "react-leaflet-enhanced-marker";
import { useDebouncedCallback } from "use-debounce/lib";

import getReveal from "../../redux/services/Map/getReveal";
import { IOrderPoint } from "../../redux/constants/OrdersPage/order";
import { useTypedSelector } from "../../redux/store";
import { SVGgeneral } from "../../utils/generalSprite";
import NamedMarker from "../NamedMarker";
import Marker from "../Marker";
import { useTaxiServiceMapName } from "../../hooks";

import PolylineDecorator from "./components/PolylineDecorator";
import { MapBase, CarMarkerIconPoint, CarMarkerPoint } from "./styled";

const CarMarker = (props) => (
	<CarMarkerPoint id="car-marker-icon-id">
		<CarMarkerIconPoint>
			<SVGgeneral id="parking" />
		</CarMarkerIconPoint>
		{props.index}
	</CarMarkerPoint>
);

const polygonOptions = {
	color: "#7eaff4",
};

const styledPolygonObject = {
	fillColor: "red",
	fillOpacity: 0.5,
	weight: 0,
	color: "transparent",
	opacity: 0,
};

const styledPolygonRoad = {
	color: "red",
	fillOpacity: 0,
};

function getColor(index, length = 9) {
	return [
		"#e83a53",
		"#54ad4a",
		"#397eb0",
		"#974ba4",
		"#f49e3b",
		"#fcfb2e",
		"#ad4f20",
		"#f680bd",
		"#aca7a3",
	][index % length];
}

const isLatLngPoint = (value) => {
	if (!value) return false;
	return Number.isFinite(+value.lng) && Number.isFinite(+value.lat);
};

function CentroidPolygons({
	setZoom,
	setCenter,
	geometry,
	searchType,
	acceptBoundPolygon,
	zoom,
	setAcceptBoundPolygon,
}) {
	const map = useMap();

	useMapEvent("zoom", (event) => {
		setZoom(event.target._zoom);
	});
	useMapEvents({
		dragend: (e) => {
			setCenter(e.target.getCenter());
		},
		moveend: (e) => {
			setCenter(e.target.getCenter());
		},
	});
	useEffect(() => {
		if (!map) return;
		if (!geometry) return;
		if (searchType === "Point" && acceptBoundPolygon) {
			map.setView(geometry, zoom, {
				animate: true,
			});
		}

		if (geometry.length >= 2 && acceptBoundPolygon) {
			map.fitBounds(geometry, {
				padding: [40, 40],
				maxZoom: 17,
			});
		}

		if (acceptBoundPolygon) setAcceptBoundPolygon(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geometry]);
	return <></>;
}

function Centroid({
	// mapIsReady,
	setZoom,
	setCenter,
	// isSetBounding,
	points,
	// allowControlledBounding,
	// setAllowBounding,
	acceptBounding,
	setAcceptBounding,
	activePoint,
	allowMapCentralization,
	setMapCenter,
	centroid,
	setAllowMapCentralization,
	zoom,
	allowBound,
}) {
	const map = useMap();

	useMapEvent("zoom", (event) => {
		setZoom(event.target._zoom);
	});

	useMapEvents({
		dragend: (e) => {
			setCenter(e.target.getCenter());
		},
		moveend: (e) => {
			setCenter(e.target.getCenter());
		},
	});

	// useEffect(() => {
	// 	if (
	// 		Boolean(map) &&
	// 		isSetBounding &&
	// 		allowControlledBounding &&
	// 		mapIsReady
	// 	) {
	// 		if (points.length >= 2) {
	// 			const track = points.map((item) => item.coordinates);
	// 			map.fitBounds(track, {
	// 				padding: [40, 40],
	// 				maxZoom: 19,
	// 			});
	// 		} else if (points.length > 0) {
	// 			map.setView(points[0].coordinates, zoom, {
	// 				animate: true,
	// 			});
	// 		}
	// 		setAllowBounding();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isSetBounding, points.length >= 2, mapIsReady]);

	// useEffect(() => {
	// 	if (
	// 		Boolean(map) &&
	// 		acceptBounding &&
	// 		points.length >= 2 &&
	// 		!allowControlledBounding
	// 	) {
	// 		if (points.length >= 2) {
	// 			const track = points.map((item) => item.coordinates);
	// 			map.fitBounds(track, {
	// 				padding: [40, 40],
	// 				maxZoom: 19,
	// 			});
	// 		} else if (points.length > 0) {
	// 			map.setView(points[0].coordinates, zoom, {
	// 				animate: true,
	// 			});
	// 		}
	// 		setAcceptBounding(false);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [points, acceptBounding, allowControlledBounding]);
	//

	useEffect(() => {
		if (
			Boolean(map) &&
			activePoint !== -1 &&
			points[activePoint] &&
			acceptBounding
		) {
			map.setView(points[activePoint].coordinates, zoom, {
				animate: true,
			});
			setAcceptBounding(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePoint, acceptBounding]);

	useEffect(() => {
		if (allowBound) {
			const route = points.map((point) => point.coordinates);
			if (!map || route.length <= 1) return;

			const [firstPos, ...rest] = route.flat(1);
			if (!firstPos) return;

			let min = firstPos;
			let max = firstPos;

			rest.forEach((pos) => {
				min = {
					lat: Math.min(min.lat, pos.lat),
					lng: Math.min(min.lng, pos.lng),
				};

				max = {
					lat: Math.max(max.lat, pos.lat),
					lng: Math.max(max.lng, pos.lng),
				};
			});

			if (min.lat === max.lat) {
				min.lat -= 0.01;
				max.lat += 0.01;
			}

			if (min.lng === max.lng) {
				min.lng -= 0.01;
				max.lng += 0.01;
			}

			map.fitBounds(
				[
					[min.lat, min.lng],
					[max.lat, max.lng],
				],
				{ animate: true, padding: [35, 35], maxZoom: 19 },
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowBound, map, points]);

	useEffect(() => {
		if (allowMapCentralization) {
			setMapCenter(centroid);
			map.setView(centroid, zoom);
			setAllowMapCentralization(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowMapCentralization]);
	return <></>;
}

export function TrackPolyline({ route, distance, zoom, isTaximeter = false }) {
	const { Symbol } = L as any;

	return route?.map((item, index) => (
		<PolylineDecorator
			key={`index${index}`}
			positions={item}
			aria-hidden="true"
			pathOptions={{
				color: isTaximeter ? "#8e00ff" : getColor(index),
			}}
			patterns={[
				{
					offset: 25,
					repeat: 90,
					symbol: Symbol.arrowHead({
						pixelSize:
							distance > 70000
								? zoom / 1.1
								: zoom < 5
								? 0
								: zoom / 1.5,
						pathOptions: {
							color: isTaximeter ? "#8e00ff" : getColor(index),
							fillOpacity: 1,
							weight: 0,
						},
					}),
				},
			]}
		/>
	));
}

interface IProps {
	geometry?: L.LatLngExpression[] | L.LatLngExpression[][];
	searchType?: string;
	allowControlledBounding?: boolean;
	allowBound?: boolean;
	setAllowBounding?: () => void;
	isSetBounding?: boolean;
	polygons?: L.LatLngExpression[] | L.LatLngExpression[][];
	polygonsVisible?: boolean;
	points?: IOrderPoint[];
	distance?: number;
	route?: L.LatLngExpression[] | L.LatLngExpression[][];
	routeTaximeter?: L.LatLngExpression[] | L.LatLngExpression[][];
	isTaximeter?: boolean;
	activePoint?: number;
	allowMapCentralization?: boolean;
	setAllowMapCentralization?: (props) => void;
	centroid?: L.LatLngExpression | number[];
	setCenter?: (props) => void;
	setPoint?: (index, newCoordinates, result) => void;
	readOnly?: boolean;
	cars?: { [key: string]: any }[];
	children?: ReactNode;
}

export default function Map({
	geometry,
	searchType,
	// ----OUTSIDE CONTROL BOUNDING-----
	allowControlledBounding,
	setAllowBounding,
	isSetBounding,
	allowBound = true,
	//-------
	// ----POLYGONS----
	polygons: propsPolygons,
	polygonsVisible,
	//-----
	points,
	distance = 0,
	route,
	routeTaximeter,
	isTaximeter = false,
	activePoint = -1,
	// ----MAP CENTER----
	allowMapCentralization = false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setAllowMapCentralization = (value) => {},
	centroid,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setCenter = (newCenter) => {},
	//-----
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setPoint = (index, newCoordinates, result) => {},
	readOnly = false,

	// ----- CARS ------
	cars,
	children,
}: IProps) {
	const { mapLanguage } = useTypedSelector((state) => state.settings.map);

	const [center, setMapCenter] = useState(
		(centroid || [50.455002, 30.511284]) as L.LatLngExpression,
	);
	const [zoom, setZoom] = useState(11);

	const [mapIsReady, setMapStatus] = useState(false);
	const [acceptBounding, setAcceptBounding] = useState(false);
	const [acceptBoundPolygon, setAcceptBoundPolygon] = useState(false);
	const [polygons, setPolygons] = useState(propsPolygons);
	const taxiServiceName = useTaxiServiceMapName();

	const findInterestsByPoint = (
		point = {},
		cb: (props: unknown) => unknown = () => {},
	) => {
		getReveal(point, {}).then((payload) => cb(payload));
	};

	const debouncedFindInterestsByPointRequest = useDebouncedCallback(
		findInterestsByPoint,
		900,
	);

	useLayoutEffect(() => {
		if (mapIsReady && setAllowBounding) setAllowBounding();
	}, [mapIsReady, setAllowBounding]);

	const zoomControlZoomIn = document.getElementsByClassName(
		"leaflet-control-zoom-in",
	);
	const zoomControlZoomOut = document.getElementsByClassName(
		"leaflet-control-zoom-out",
	);

	useEffect(() => {
		if (zoomControlZoomIn) {
			const elem = zoomControlZoomIn.item(0);
			if (elem) {
				elem.setAttribute("id", "map-zoom-in-control");
			}
		}
		if (zoomControlZoomOut) {
			const elem = zoomControlZoomOut.item(0);
			if (elem) {
				elem.setAttribute("id", "map-zoom-out-control");
			}
		}
	}, [zoomControlZoomIn, zoomControlZoomOut]);

	const eventHandlers = useMemo(
		() => ({
			dragend(event) {
				const index =
					+event.target.dragging._draggable._element.innerText;
				const newCoordinates = event.target._latlng;

				debouncedFindInterestsByPointRequest(
					newCoordinates,
					(payload) => {
						setPoint(index - 1, newCoordinates, payload);
					},
				);
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[points],
	);

	const pointsOrder = useMemo(() => points || [], [points]);
	useMemo(() => {
		setPolygons(propsPolygons);
	}, [propsPolygons]);

	useEffect(() => {
		setAcceptBounding(true);
	}, [isSetBounding, activePoint]);

	useEffect(() => {
		setAcceptBoundPolygon(true);
	}, [geometry]);

	const mapOnReady = useCallback(() => {
		react.delay(setAcceptBounding)(true);
		react.delay(setMapStatus)(true);
	}, []);

	/* points order functional */
	const pointsOrderElement = useMemo(() => {
		if (!pointsOrder.length) return <></>;

		return pointsOrder.map((item, index) =>
			!readOnly ? (
				<MarkerBase
					draggable
					autoPan
					tabIndex="-1"
					key={`marker#${index}`}
					icon={
						<NamedMarker
							key={`markerIcon#${index}`}
							name={`${index + 1}`.toString()}
						/>
					}
					eventHandlers={eventHandlers}
					position={item?.coordinates}
				/>
			) : (
				<MarkerBase
					tabIndex="-1"
					key={`marker#${index}`}
					icon={
						<NamedMarker
							key={`markerIcon#${index}`}
							name={`${index + 1}`.toString()}
						/>
					}
					position={item?.coordinates}
				/>
			),
		);
	}, [eventHandlers, pointsOrder, readOnly]);

	/* polygons functional */
	const polygonsElement = useMemo(() => {
		if (!polygonsVisible && polygons) {
			return polygons.map((polygon, index) => (
				<Polygon
					aria-hidden="true"
					key={`polygon#${index + 1}`}
					eventHandlers={{}}
					pathOptions={polygonOptions}
					positions={polygon?.vertices}
				>
					{polygon?.name && <Tooltip sticky>{polygon.name}</Tooltip>}
				</Polygon>
			));
		}
		return <></>;
	}, [polygons, polygonsVisible]);

	return (
		<MapBase
			center={center}
			zoom={zoom}
			maxZoom={19}
			language={mapLanguage}
			aria-hidden="true"
			onReady={mapOnReady}
			nameMap={taxiServiceName}
			hasRasterization={taxiServiceName === "visicom"}
			minZoom={4}
		>
			{/* base map functional */}

			<TrackPolyline
				route={route}
				distance={distance}
				zoom={zoom}
				isTaximeter={isTaximeter}
			/>
			{routeTaximeter && (
				<TrackPolyline
					route={routeTaximeter}
					distance={distance}
					zoom={zoom}
				/>
			)}
			<Centroid
				allowBound={allowBound}
				// mapIsReady={mapIsReady}
				// isSetBounding={isSetBounding}
				// allowControlledBounding={allowControlledBounding}
				// setAllowBounding={setAllowBounding}
				setZoom={setZoom}
				setCenter={setCenter}
				points={points}
				acceptBounding={acceptBounding}
				setAcceptBounding={setAcceptBounding}
				activePoint={activePoint}
				allowMapCentralization={allowMapCentralization}
				setMapCenter={setMapCenter}
				centroid={centroid}
				zoom={zoom}
				setAllowMapCentralization={setAllowMapCentralization}
			/>

			{pointsOrderElement}
			{polygonsElement}
			{/* map search functional */}
			{geometry && (
				<CentroidPolygons
					setZoom={setZoom}
					setCenter={setCenter}
					geometry={geometry}
					searchType={searchType}
					acceptBoundPolygon={acceptBoundPolygon}
					zoom={zoom}
					setAcceptBoundPolygon={setAcceptBoundPolygon}
				/>
			)}
			{geometry && isLatLngPoint(geometry) && (
				<MarkerBase
					tabIndex="-1"
					icon={<Marker />}
					position={geometry}
				/>
			)}
			{(searchType === "Point" || searchType === "Polygon") &&
				geometry && (
					<Polygon
						aria-hidden="true"
						pathOptions={styledPolygonObject}
						positions={geometry}
					>
						{/* <Tooltip sticky></Tooltip> */}
					</Polygon>
				)}
			{searchType === "LineString" && geometry && (
				<Polyline
					aria-hidden="true"
					pathOptions={styledPolygonRoad}
					positions={geometry}
				/>
			)}
			{searchType === "MultiLineString" &&
				geometry &&
				geometry.map((lineString, index) => (
					<div key={`${index + 1}`}>
						<Polyline
							aria-hidden="true"
							pathOptions={styledPolygonRoad}
							positions={lineString}
						/>
					</div>
				))}

			{children}
		</MapBase>
	);
}
