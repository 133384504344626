import { useMemo } from "react";

import useCurrentTaxiServices from "./useCurrentTaxiService";

/**
 *
 * @returns {string} - Returns true if the taxi service name.
 */
const useTaxiServiceMapName = (): string => {
	// Access taxi services from the state
	const settings = useCurrentTaxiServices()?.settings;
	const taxiServiceName = useMemo(
		() => settings?.map?.general?.mapServices?.search,
		[settings?.map?.general?.mapServices?.search],
	);
	return taxiServiceName;
};

export default useTaxiServiceMapName;
