/* eslint-disable no-param-reassign */
import { LatLngLiteral } from "leaflet";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isArray, uniqueId } from "lodash";

import Executor from "../../../services/Executor";
import SearchType from "../../../types/SearchType";
import RequiredKeys from "../../../types/RequiredKeys";
import { ColumnId } from "../../../components/OrderPageWidgets/OrderTable/components/ModelTable/columns";
import {
	Accessor,
	OrderTableColumn,
	defaultColumns,
} from "../../../components/OrderTable/columns";
import { Tab } from "../../../pages/MainPage/pages/Orders/components/OrderModal/types/Tab";
import { OrderType } from "../../constants/OrdersPage";

import {
	OrderStatusContainer,
	OrderStatusContainers,
	Companies,
	Modal,
	ModalState,
	ColumnModal,
	TableOrderBy,
	FocusableOrder,
	Filter,
	OrderForm,
	OrderPageState,
} from "./interface";

export type { OrderStatusContainer, OrderStatusContainers, Companies };

const DEFAULT_PAGINATION = {
	limit: undefined,
	count: 0,
	offset: 0,
};

const ORDERS_TEMPLATE: OrderStatusContainers = {
	live: {
		items: [],
		count: 0,
		loaded: false,
		pagination: DEFAULT_PAGINATION,
	},
	closed: {
		items: [],
		count: 0,
		loaded: false,
		pagination: DEFAULT_PAGINATION,
	},
	preliminary: {
		items: [],
		count: 0,
		loaded: false,
		pagination: DEFAULT_PAGINATION,
	},
	all: { items: [], count: 0, loaded: false, pagination: DEFAULT_PAGINATION },
	executable: {
		items: [],
		count: 0,
		loaded: false,
		pagination: DEFAULT_PAGINATION,
	},
};

const defaultOrder = {
	points: [],
	distance: 0,
	route: [],
	hash: uniqueId("order"),
};

const pathTranslationsMain = "redux.reducers.ordersPage.modals.";

const initialState: OrderPageState = {
	modal: {
		open: false,
		data: {
			cards: [],
			activeCardId: null,
			dispatcherId: null,
		},
	},
	columnModal: {
		open: false,
		data: {},
	},
	visibleTableColumnAccessors: defaultColumns
		.filter((column) => column.hidden !== true)
		.map((column) => column.accessor),
	companies: { items: [], pagination: {} },
	ordersFilterIds: [],
	orders: ORDERS_TEMPLATE,
	tabs: [], // * data for tabs in modal edit/add order
	bufferOpenNewTab: false, // * data for tabs in modal edit/add order
	bufferFirstOpenThenCallUp: false, // * data for first cal open  -  modal edit/add order
	bufferOpenBaseThenCallContinue: false, // * open button or key then continues call - modal edit/add order
	activePoint: -1,
	carsPosition: [],
	focusableOrder: null, // focus on order after create
	activeOrder: {
		points: [],
		distance: 0,
		route: [],
	},
	tableOrderBy: {
		column: ColumnId.CreateOrderDate,
		type: "desc",
	},
	searchTypes: ["street"],
	route: [],
	ordersType: "live",
	mapCenter: { lat: 50.4501, lng: 30.5234 },
	modals: [
		{
			label: `${pathTranslationsMain}chat`,
			type: "chat",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}table`,
			type: "table",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}map`,
			type: "map",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}parking`,
			type: "parking",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}executors`,
			type: "executors",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}softphone`,
			type: "softphone",
			position: "",
			groupId: "",
			visible: false,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
		{
			label: `${pathTranslationsMain}previewer`,
			type: "previewer",
			position: "",
			groupId: "",
			visible: true,
			columnStart: 0,
			rowStart: 0,
			columnEnd: 0,
			rowEnd: 0,
		},
	],
	columns: defaultColumns,
	executors: [],
	executorsByCarCallsign: [],
	smsTemplates: [],
	filters: {
		live: {
			showOnlyMyOrders: false,
			showOrdersWithoutExecutors: false,
			limit: 10,
		},
		closed: {
			showOnlyMyOrders: false,
			showOrdersWithoutExecutors: false,
			limit: 10,
			dateRange: {
				from: new Date(Date.now() - 24 * 60 * 60 * 1000),
				to: new Date(Date.now() - 24 * 60 * 60 * 1000),
			},
		},
		preliminary: {
			showOnlyMyOrders: false,
			showOrdersWithoutExecutors: false,
			limit: 10,
		},
		all: {
			showOnlyMyOrders: false,
			showOrdersWithoutExecutors: false,
			limit: 10,
		},
		executable: {
			showOnlyMyOrders: false,
			showOrdersWithoutExecutors: false,
			limit: 10,
		},
	},
	forms: [],
};

type Reducer<P> = CaseReducer<OrderPageState, PayloadAction<P>>;

const setColumnModalOpen: Reducer<boolean> = (state, { payload }) => {
	state.columnModal.open = payload;
};

const setColumnModalData: Reducer<ColumnModal["data"]> = (
	state,
	{ payload },
) => {
	state.columnModal.data = payload;
};

const setVisibleColumns: Reducer<Accessor[]> = (state, { payload }) => {
	state.visibleTableColumnAccessors = payload;
};

const setModalOpen: Reducer<boolean> = (state, { payload }) => {
	state.modal.open = payload;

	if (payload === false) {
		state.tabs = initialState.tabs;
		// state.bufferOpenNewTab = initialState.bufferOpenNewTab;
		// state.bufferFirstOpenThenCallUp =
		// 	initialState.bufferFirstOpenThenCallUp;
		// state.bufferOpenBaseThenCallContinue =
		// 	initialState.bufferOpenBaseThenCallContinue;
	} //* Clearing the data cache in the edit modal order
};

const setBufferOpenNewTab: Reducer<boolean> = (state, { payload }) => {
	state.bufferOpenNewTab = payload;
};

const setBufferFirstOpenThenCallUp: Reducer<boolean> = (state, { payload }) => {
	state.bufferFirstOpenThenCallUp = payload;
};

const setBufferOpenBaseThenCallContinue: Reducer<boolean> = (
	state,
	{ payload },
) => {
	state.bufferOpenBaseThenCallContinue = payload;
};

const setModalData: Reducer<ModalState["data"]> = (state, { payload }) => {
	state.modal.data = payload;
};

const updatedModalData: Reducer<any> = (state, { payload }) => {
	if (!payload) return;
	try {
		const { cards } = state.modal.data;
		const newCards = cards?.map((card) => {
			if (card.id === payload?.id) {
				return { ...card, order: { ...card.order, ...payload } };
			}
			return card;
		});
		state.modal.data.cards = newCards;
	} catch (error) {
		console.error(error);
	}
};

const updatedOrderForm: Reducer<OrderForm> = (state, { payload }) => {
	if (!payload) return;
	try {
		const { forms } = state;

		if (!forms.length) {
			state.forms = [payload];
			return;
		}

		const newForms = [...forms].map((form) => {
			if (payload.orderId === form.orderId) {
				return payload;
			}
			return form;
		});

		state.forms = newForms;
	} catch (error) {
		console.error(error);
	}
};

const removeOrderForm: Reducer<{ orderId: number }> = (state, { payload }) => {
	if (!payload) return;
	try {
		const { forms } = state;
		const newForms =
			[...forms].filter((form) => payload.orderId !== form.orderId) || [];

		state.forms = newForms;
	} catch (error) {
		console.error(error);
	}
};

const setSearchTypes: Reducer<SearchType | SearchType[]> = (
	state,
	{ payload },
) => {
	state.searchTypes = payload;
};

const setColumns: Reducer<OrderTableColumn[]> = (state, { payload }) => {
	state.columns = payload;
};

const modifyColumn: Reducer<{
	accessor: Accessor;
	data: Partial<OrderTableColumn>;
}> = (state, { payload }) => {
	const index = state.columns.findIndex(
		(c) => c.accessor === payload.accessor,
	);

	const data = payload.data as RequiredKeys<OrderTableColumn>;

	if (index !== -1)
		state.columns[index] = {
			...state.columns[index],
			...data,
			accessor: payload.accessor,
		};
};

const modals: Reducer<Modal[]> = (state, { payload }) => {
	state.modals = payload;
};

const setOrdersType: Reducer<OrderType> = (state, { payload }) => {
	state.ordersType = payload;
	state.activeOrder = state.orders[payload]?.items?.at(0) ?? defaultOrder;
	state.activePoint = 0;
};

const setOrders: Reducer<OrderStatusContainers> = (state, { payload }) => {
	state.orders = payload;
};

const setTabs: Reducer<Tab[]> = (state, { payload }) => {
	state.tabs = payload;
};

const setFocusableOrder: Reducer<FocusableOrder | null> = (
	state,
	{ payload },
) => {
	state.focusableOrder = payload;
};

const setOrdersByTab: Reducer<{
	tab: keyof OrderStatusContainers;
	data: OrderStatusContainer;
}> = (state, { payload }) => {
	state.orders[payload.tab] = payload.data;

	if (payload.tab === state.ordersType) {
		const activeOrder =
			state.orders[payload.tab].items.find(
				(item) => item.id === state.activeOrder?.id,
			) ??
			state.orders[payload.tab]?.items?.at(0) ??
			defaultOrder;

		if ("id" in activeOrder && activeOrder.id === state.activeOrder.id) {
			state.activePoint = Math.min(
				activeOrder.points.length - 1,
				state.activePoint,
			);
		} else {
			state.activePoint = 0;
		}

		state.activeOrder = activeOrder;
	}
};

const setTableOrderBy: Reducer<TableOrderBy> = (state, { payload }) => {
	state.tableOrderBy = payload;
};

const setActivePoint: Reducer<number> = (state, { payload }) => {
	state.activePoint = payload;
};

const setMapCenter: Reducer<LatLngLiteral> = (state, { payload }) => {
	state.mapCenter = payload;
};

const setRoute: Reducer<any> = (state, { payload }) => {
	state.route = payload;
};

const setActiveOrder: Reducer<any> = (state, { payload }) => {
	state.activeOrder = payload;
};

const setModalVisible: Reducer<{
	type: any;
	visible: any;
}> = (state, { payload }) => {
	const newArray = state.modals.map((item) =>
		item.type === payload.type
			? { ...item, visible: payload.visible }
			: item,
	);

	state.modals = newArray;
};

const setFilteredOrdersID: Reducer<{ ids: number[] }> = (
	state,
	{ payload },
) => {
	state.ordersFilterIds = payload.ids;
};

const setCompanies: Reducer<Companies> = (state, { payload }) => {
	state.companies = payload;
};

const setCarsPosition: Reducer<any[]> = (state, { payload }) => {
	state.carsPosition = payload;
};

const setExecutors: Reducer<Executor.Model[]> = (state, { payload }) => {
	state.executors = payload;
};

const setExecutorsByCarCallsign: Reducer<Executor.Model[]> = (
	state,
	{ payload },
) => {
	state.executorsByCarCallsign = payload;
};

const setSMSTemplates: Reducer<any[]> = (state, { payload }) => {
	state.smsTemplates = payload;
};

const setFilters: Reducer<{ type: OrderType | OrderType[]; data: Filter }> = (
	state,
	{ payload },
) => {
	if (isArray(payload.type)) {
		payload.type.forEach((type) => {
			state.filters[type] = payload.data;
		});
	} else {
		state.filters[payload.type] = payload.data;
	}
};

const orderPage = createSlice({
	name: "orderPageReducer",
	initialState,
	reducers: {
		removeOrderForm,
		updatedOrderForm,
		setColumnModalOpen,
		setColumnModalData,
		setVisibleColumns,
		setModalOpen,
		setModalData,
		setSearchTypes,
		setColumns,
		modifyColumn,
		modals,
		setOrdersType,
		setOrders,
		setTabs,
		setBufferOpenNewTab,
		setBufferFirstOpenThenCallUp,
		setBufferOpenBaseThenCallContinue,
		setFocusableOrder,
		setOrdersByTab,
		setTableOrderBy,
		setActivePoint,
		setMapCenter,
		setRoute,
		setActiveOrder,
		setModalVisible,
		setFilteredOrdersID,
		setCompanies,
		setCarsPosition,
		setExecutors,
		setExecutorsByCarCallsign,
		setSMSTemplates,
		setFilters,
		updatedModalData,
	},
});

export default orderPage;
