import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Select } from "uikit";
import { uniq } from "lodash";

import LabeledField from "../../../../../../LabeledField";
import { findValueByLanguageKey } from "../../../../../../../assets/languages/langs";

const CompanyAndTaxiService = ({
	filter,
	language,
	possibleCompanies,
	possibleTaxiServices,
	taxiServiceIds,
	parkingData,
	searchFieldData,
	defaultCity,
	taxiServiceData,
	companyId,
	setCompanyId,
	taxiServiceId,
	setTaxiServiceId,
	onChangeTabIndex,
}) => {
	const { t } = useTranslation();
	const possibleCompanyOptions = useMemo(() => {
		const filtredCompaniesIds = uniq(
			taxiServiceData?.cache
				.filter((taxiService) =>
					filter.taxiServiceIds.includes(taxiService.id),
				)
				.map((taxiService) => taxiService.company.id),
		);

		const filtredCompanies = possibleCompanies.filter((company) =>
			filtredCompaniesIds.includes(company.id),
		);
		return filtredCompanies.map((company) => ({
			key: company.id,
			label: company.name?.[language] ?? "",
			value: company.id,
		}));
	}, [
		filter.taxiServiceIds,
		language,
		possibleCompanies,
		taxiServiceData?.cache,
	]);

	const companyValueByDefaultCity = useMemo(
		() =>
			taxiServiceData?.cache
				.filter((taxiService) =>
					filter.taxiServiceIds.includes(taxiService.id),
				)
				.find(
					(taxiService) =>
						findValueByLanguageKey(taxiService?.settlement) ===
						defaultCity.value,
					// taxiService?.settlement?.en === defaultCity.value ||
					// taxiService?.settlement?.ru === defaultCity.value ||
					// taxiService?.settlement?.uk === defaultCity.value,
				),
		[defaultCity.value, filter.taxiServiceIds, taxiServiceData?.cache],
	);

	const getTaxiServicesByCompanyId = useCallback(
		(selectedCompanyId) => {
			try {
				const filtredTaxiServiceId = possibleTaxiServices
					.filter(
						(taxiService) =>
							taxiService.company.id === selectedCompanyId,
					)
					.filter((taxiService) =>
						taxiServiceIds.includes(taxiService.id),
					)
					.filter((taxiService) =>
						filter.taxiServiceIds.includes(taxiService.id),
					)[0];
				setTaxiServiceId(filtredTaxiServiceId?.id);
			} catch (error) {
				console.log(error);
			}
		},
		[
			filter.taxiServiceIds,
			possibleTaxiServices,
			setTaxiServiceId,
			taxiServiceIds,
		],
	);

	useMemo(() => {
		const defaultCityCompanyId = companyValueByDefaultCity?.company?.id;
		const defaultCityTaxiServiceId = companyValueByDefaultCity?.id;
		if (parkingData) return;
		if (defaultCityCompanyId) {
			setCompanyId(defaultCityCompanyId);
		} else if (possibleCompanyOptions[0]?.value) {
			setCompanyId(possibleCompanyOptions[0]?.value || 0);
			getTaxiServicesByCompanyId(possibleCompanyOptions[0]?.value || 0);
		}
		if (defaultCityTaxiServiceId) {
			setTaxiServiceId(defaultCityTaxiServiceId);
		}
	}, [
		companyValueByDefaultCity?.company?.id,
		companyValueByDefaultCity?.id,
		getTaxiServicesByCompanyId,
		parkingData,
		possibleCompanyOptions,
		setCompanyId,
		setTaxiServiceId,
	]);

	const possibleTaxiServiceOptions = useMemo(() => {
		const filtredTaxiService = possibleTaxiServices
			.filter((taxiService) => taxiService.company.id === companyId)
			.filter((taxiService) => taxiServiceIds.includes(taxiService.id))
			.filter((taxiService) =>
				filter.taxiServiceIds.includes(taxiService.id),
			);
		return filtredTaxiService.map((taxiService) => ({
			key: taxiService.id,
			label: `${taxiService?.settlement[language]} (${taxiService.company?.name[language]}) `,
			value: taxiService.id,
		}));
	}, [
		possibleTaxiServices,
		companyId,
		taxiServiceIds,
		filter.taxiServiceIds,
		language,
	]);

	const onSelectCompany = useCallback(
		(selectedCompany) => {
			setCompanyId(selectedCompany.value);
			getTaxiServicesByCompanyId(selectedCompany.value);
			onChangeTabIndex(-1);
		},
		[getTaxiServicesByCompanyId, onChangeTabIndex, setCompanyId],
	);

	const onSelectTaxiService = useCallback(
		(selectedTaxiService) => {
			setTaxiServiceId(selectedTaxiService.value);
			onChangeTabIndex(0);
		},
		[onChangeTabIndex, setTaxiServiceId],
	);

	return (
		<Row gaps="10px">
			<LabeledField
				label={
					t(
						"orderPageWidgets.parking.parkingsModal.companyAndTaxiService.str0",
					) ?? ""
				}
			>
				<Select
					value={companyId}
					options={possibleCompanyOptions}
					onSelect={onSelectCompany}
					placeholder={
						t(
							"orderPageWidgets.parking.parkingsModal.companyAndTaxiService.str1",
						) ?? ""
					}
					disabled={parkingData || searchFieldData}
				/>
			</LabeledField>
			<LabeledField
				label={
					t(
						"orderPageWidgets.parking.parkingsModal.companyAndTaxiService.str2",
					) ?? ""
				}
			>
				<Select
					value={taxiServiceId}
					options={possibleTaxiServiceOptions}
					onSelect={onSelectTaxiService}
					placeholder={
						t(
							"orderPageWidgets.parking.parkingsModal.companyAndTaxiService.str3",
						) ?? ""
					}
					disabled={parkingData || searchFieldData}
				/>
			</LabeledField>
		</Row>
	);
};

export default CompanyAndTaxiService;
