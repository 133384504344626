import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { useTypedSelector } from "../../redux/store";
import { NavigationLayout } from "../../components/common";
import { ROUTES } from "../../constants/routes";
import { hasAccess, AccessKey } from "../../access";

const Navigation: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const links: NavigationLayout.ILink[] = useMemo(
		() =>
			[
				{
					text: t(`mainPage.orders.title`),
					Image: () => <Icon id="orders" size={90} />,
					link: ROUTES.ORDERS,
					accessName: AccessKey.ORDERS,
				},
				{
					text: t(`mainPage.customers.title`),
					Image: () => <Icon id="customers" size={90} />,
					link: ROUTES.CUSTOMERS,
					accessName: AccessKey.CUSTOMERS,
				},
				{
					text: t(`mainPage.blacklist.title`),
					Image: () => <Icon id="blacklist" size={90} />,
					link: ROUTES.BLACKLIST,
					accessName: AccessKey.BLACKLIST,
				},
				{
					text: t(`mainPage.preferences.title`),
					Image: () => <Icon id="reference-book" size={90} />,
					link: ROUTES.PREFERENCES,
					accessName: AccessKey.PREFERENCES_PAGE_NAME,
				},
				{
					text: t(`mainPage.accounts.title`),
					Image: () => <Icon id="accounts" size={90} />,
					colorizeStroke: true,
					link: ROUTES.ACCOUNTS,
					accessName: AccessKey.ACCOUNTS,
				},
				{
					text: t(`mainPage.reports.title`),
					Image: () => <Icon id="reports" size={90} />,
					link: ROUTES.REPORTS,
					accessName: AccessKey.REPORTS,
				},
				{
					text: t(`mainPage.archives.title`),
					Image: () => <Icon id="archives" size={90} />,
					link: ROUTES.ARCHIVES,
					accessName: AccessKey.ARCHIVES,
				},
				{
					// disabled: true,
					text: t(`mainPage.accounting.title`),
					Image: () => <Icon id="accounting2" size={90} />,
					link: ROUTES.ACCOUNTING,
					accessName: AccessKey.ACCOUNTING,
				},
				{
					text: t(`settings.title`),
					Image: () => <Icon id="settings" size={90} />,
					link: ROUTES.SETTINGS,
					accessName: AccessKey.SETTINGS,
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	return <NavigationLayout links={links} />;
};

export default Navigation;
