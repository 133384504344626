import React, { useCallback, MouseEvent, memo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ordersAction as orders } from "../../../../../../../redux/reducers/Orders";
import { ChatMessage as ChatMessageServices } from "../../../../../../../services";
import { StyledRow } from "../../../../../../common";

import { Avatar, Messages } from "./components";

const Root = styled(StyledRow)`
	margin: 0px 12px;
	max-width: 80%;
`;

const MessageBlock: React.FC<MessageBlock.Props> = ({
	data,
	onClickFindParent,
}) => {
	const dispatch = useDispatch();

	const { id, isCurrentUser, type, title, content, createdAt, parent } = data;

	const setContextMenuCoordinate = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			dispatch(
				orders.chats.chat.setContextMenuCoordinate({
					x: e.clientX,
					y: e.clientY,
					isCurrentUser,
				}),
			);

			dispatch(orders.chats.chat.setBooferAnswerParent(id));
			dispatch(orders.chats.chat.setIsShowContextMenu(true));
		},
		[dispatch, id, isCurrentUser],
	);

	return (
		<Root gap="10px" onContextMenu={setContextMenuCoordinate}>
			{isCurrentUser || <Avatar text={data?.avatar} />}

			<Messages
				data={{
					isCurrentUser,
					type,
					title,
					content,
					createdAt,
					parent,
				}}
				onClickFindParent={onClickFindParent}
			/>
		</Root>
	);
};

declare namespace MessageBlock {
	interface Props {
		data: {
			id: number;
			isCurrentUser: boolean;
			createdAt: string;
			avatar?: string;
			type?: string;
			title?: string;
			content?: string;
			chatMessageToAllowedDispatchers?: any[];
			chatMessageToAllowedExecutors?: any[];
			expirationDate?: string;
			parent?: ChatMessageServices.OutputGrouped.ChatMessage;
		};
		onClickFindParent?: (parentId: number) => void;
	}
}

export default memo(MessageBlock);
