import React from "react";
import styled from "styled-components";
import { Icon, theme } from "uikit";
import { RegisteredIcon } from "../../../../../../../../assets/icons";

const ButtonStyled = styled("div")<{ color?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;

	background-color: ${({ color }) => color || theme.colors.button_secondary};
	border-radius: 50%;
	cursor: pointer;
`;

interface Props {
	iconName: RegisteredIcon;
	color?: string;
}

const Button: React.FC<Props> = ({ iconName, color }) => (
	<ButtonStyled color={color}>
		<Icon id={iconName || "call"} size={16} />
	</ButtonStyled>
);

export default Button;
