import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { StyledP, StyledRow } from "..";

import getTransactionDescription from "./getTransactionDescription";

const TransactionCommentary: TransactionCommentary.EnhancedComponent = memo(
	({ text }) => {
		const { t } = useTranslation();

		const contentDescription = getTransactionDescription(t, text);

		return (
			<StyledRow alignItems="center" gap="3px" w="100%">
				<StyledP>{contentDescription}</StyledP>
			</StyledRow>
		);
	},
) as TransactionCommentary.EnhancedComponent;

TransactionCommentary.getTransactionDescription = getTransactionDescription;

declare namespace TransactionCommentary {
	interface EnhancedComponent
		extends React.MemoExoticComponent<React.FC<Props>> {
		getTransactionDescription: typeof getTransactionDescription;
	}

	interface Props {
		text?: string;
	}
}

export default TransactionCommentary;
