import React, { useMemo, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";

import useActiveTab from "../../../../hooks/useActiveTab";
import { useTypedSelector } from "../../../../redux/store";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	SideTab,
	StyledRow,
	SuspenseLoader,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyExecutorTariffPlans2 = lazy<React.FC<FinancesPage.Props>>(
	async () => {
		const elem = await import(
			"./tabs/ExecutorTariffPlans2" /* webpackChunkName: "settings-finances-page-executor-tariff-plans-2" */
		);
		return { default: elem.default };
	},
);

const LazyCustomerTariffPlans = lazy<React.FC<FinancesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/CustomerTariffPlans" /* webpackChunkName: "settings-finances-page-customer-tariff-plans" */
	);
	return { default: elem.default };
});

const LazyUserTariffPlans = lazy<React.FC<FinancesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/UserTariffPlans" /* webpackChunkName: "settings-finances-page-user-tariff-plans" */
	);
	return { default: elem.default };
});

const LazyExecutorPenalties = lazy<React.FC<FinancesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/ExecutorPenalties" /* webpackChunkName: "settings-finances-page-executor-penalties" */
	);
	return { default: elem.default };
});

const LazyCustomerPenalties = lazy<React.FC<FinancesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/CustomerPenalties" /* webpackChunkName: "settings-finances-page-customer-penalties" */
	);
	return { default: elem.default };
});

const LazyUserPenalties = lazy<React.FC<FinancesPage.Props>>(async () => {
	const elem = await import(
		"./tabs/UserPenalties" /* webpackChunkName: "settings-finances-page-user-penalties" */
	);
	return { default: elem.default };
});

const FinancesPage: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTOR_TARIFF_PLANS,
					label: t("settings.finances.executorTariffPlans.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutorTariffPlans2 />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_TARIFF_PLANS],
				},
				{
					key: TabKeys.CUSTOMER_TARIFF_PLANS,
					label: t("settings.finances.customerTariffPlans.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCustomerTariffPlans />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER_TARIFF_PLANS],
				},
				{
					key: TabKeys.USER_TARIFF_PLANS,
					label: t("settings.finances.userTariffPlans.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyUserTariffPlans />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.USER_TARIFF_PLANS],
				},
				{
					key: TabKeys.EXECUTOR_PENALTIES,
					label: t("settings.finances.executorPenalties.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutorPenalties />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_PENALTIES],
				},
				{
					key: TabKeys.CUSTOMER_PENALTIES,
					label: t("settings.finances.customerPenalties.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCustomerPenalties />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CUSTOMER_PENALTIES],
				},
				{
					key: TabKeys.USER_PENALTIES,
					label: t("settings.finances.userPenalties.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyUserPenalties />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.USER_PENALTIES],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace FinancesPage {
	interface Props {}
}

export default FinancesPage;
