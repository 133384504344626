import { combineReducers } from "@reduxjs/toolkit";

import {
	accountsExecutorsDataState,
	accountsExecutorsDataReducer,
	accountsExecutorsDataAction,
} from "./data";

export type StateExecutors = {
	data: accountsExecutorsDataState;
};

export const executors = combineReducers<StateExecutors>({
	data: accountsExecutorsDataReducer,
});

export const accountsExecutorsAction = {
	data: accountsExecutorsDataAction,
};

export default executors;
